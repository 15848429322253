import { useEffect } from 'react'

import Spinner from '../../components/Spinner'
import ContainedPage from '../../components/layout/ContainedPage'
import { useLocation, useNavigate, usePathGenerator } from '../../modules/navigation'
import Route from '../../app/Route.enum'
import { useSavedMoveId } from '../../modules/moves'

const HomePage: React.FC = () => {
  const { navigate } = useNavigate()
  const generatePath = usePathGenerator()
  const moveId = useSavedMoveId()
  const { search } = useLocation()

  /**
   * the homepage of the app either redirects to the quote request page or to the active move
   */
  useEffect(() => {
    if (moveId) {
      navigate(`${generatePath(Route.Move, { moveId })}${search}`)
      return
    }
    navigate(`${Route.QuotesRequest}${search}`)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [moveId])

  return (
    <ContainedPage><Spinner floating /></ContainedPage>
  )
}

export default HomePage
