import { useTranslation } from 'react-i18next'
import MarkerIcon from '@mui/icons-material/LocationOnOutlined'
import MapIcon from '@mui/icons-material/MapOutlined'
import CalendarIcon from '@mui/icons-material/CalendarMonth'
import ManIcon from '@mui/icons-material/PersonOutline'
import TruckIcon from '@mui/icons-material/LocalShippingOutlined'

import {
  type Quote,
  calculateNumberOfTrucks,
} from '../../../../modules/quotes'
import { useTranslatedValue } from '../../../../common/translation'
import { mergeClassName } from '../../../../utils/mergeClassName'
import { capitalizeFirstLetter } from '../../../../utils/string'
import {
  useDateFormatter,
  ignoreTimezone,
} from '../../../../utils/date'

type QuoteIdentificationProps = {
  quote: Quote
  small?: boolean
  showDate?: boolean
  showDistance?: boolean
  showFullTrucksDetails?: boolean
  className?: string
  companyNameClassName?: string
}

const QuoteIdentification: React.FC<QuoteIdentificationProps> = ({
  quote,
  small = false,
  showDate = true,
  showDistance = true,
  showFullTrucksDetails = false,
  className,
  companyNameClassName,
}) => {
  const { t } = useTranslation()
  const formatDate = useDateFormatter()
  const getTranslation = useTranslatedValue()

  return (
    <div className={mergeClassName(
      'lg:py-6 pl-4 lg:pl-8 md:pl-8 flex items-center transition-all',
      small && 'pb-6 lg:pb-0',
      className,
    )}
    >
      <div className="flex w-full flex-col items-center gap-6 sm:flex-row md:my-6 lg:my-0">

        { quote.companyBranch.company?.logo?.en && (
          <div className={mergeClassName(
            'w-[130px] flex sm:w-[100px] shrink-0 items-center justify-center transition-all',
            small && 'lg:w-[60px]',
          )}
          >
            <img
              src={quote.companyBranch.company?.logo?.en}
              alt=""
              className={mergeClassName(
                'max-h-[75px] transition-all',
                small && 'sm:max-h-[50px]',
              )}
            />
          </div>
        ) }

        <div>
          <div className={mergeClassName(
            'hidden text-center font-body2 text-xl font-bold uppercase leading-5 sm:block sm:text-left sm:text-2xl sm:font-normal',
            small && 'block',
            companyNameClassName,
          )}
          >
            { capitalizeFirstLetter(
              getTranslation(quote.companyBranch.company?.identification.name),
            ) }
          </div>

          <div className="mt-2 flex flex-wrap justify-center gap-x-4 gap-y-1 sm:justify-start">
            <div className="flex items-center gap-1 text-sm text-neutral-400">
              <MarkerIcon className="!text-[18px]" />
              { capitalizeFirstLetter(quote.companyBranch.tripAndTravel?.trucksOriginAddress.city) },
              { ' ' }
              { quote.companyBranch.tripAndTravel?.trucksOriginAddress.region }
            </div>

            { showDistance && (
              <div className="hidden items-center gap-1 text-sm text-neutral-400 sm:flex">
                <MapIcon className="!text-[18px]" />
                { (quote.straightLineDistance / 1000).toFixed(1) }
                { ' ' }
                { t('units.km') }
              </div>
            ) }

            <div className="flex items-center gap-1 text-sm text-neutral-400">
              { showFullTrucksDetails
                ? (
                  <div>
                    { quote.resolvedNbTrucks.map(({ key, value }) => (
                      <div key={key}>
                        <TruckIcon className="mr-2 !text-[18px]" />
                        <div className="mr-1 inline-block font-bold text-neutral-600">
                          { value } x
                        </div>
                        { t(`trucks.${key}.title`, { count: value }) }
                      </div>
                    )) }
                  </div>
                  )
                : (
                  <>
                    <TruckIcon className="!text-[18px]" />
                    { ' ' }
                    { calculateNumberOfTrucks(quote.resolvedNbTrucks) }
                  </>
                  ) }
            </div>

            <div className="flex items-center gap-1 text-sm text-neutral-400">
              <ManIcon className="!text-[18px]" />
              { quote.resolvedNbMen }
            </div>

            { (quote.forAlternativeDate && showDate) && (
              <div className="flex items-center gap-1 text-sm text-yellow-700">
                <CalendarIcon className="!text-[20px]" />
                { formatDate(ignoreTimezone(quote.date), { showYear: false, shortMonth: true, shortDay: true }) }
              </div>
            ) }
          </div>
        </div>
      </div>
    </div>
  )
}

export default QuoteIdentification
