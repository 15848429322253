import { useTranslation } from 'react-i18next'

import { mergeClassName } from '../../../../../utils/mergeClassName'
import { useDateFormatter } from '../../../../../utils/date'

type DateBoxProps = {
  date: Date
  price: number
  floatingNode?: React.ReactNode
  current?: boolean
  className?: string
}

const DateBox: React.FC<DateBoxProps> = ({
  date,
  price,
  floatingNode,
  current,
  className,
}) => {
  const { t } = useTranslation()
  const formatDate = useDateFormatter()

  const getWeekDay = () => {
    const weekDays = Object.values(t('date.weekDays', { returnObjects: true }))
    const isoWeekDay = date.getDay() === 0 ? 7 : date.getDay()
    return String(weekDays[isoWeekDay - 1]).toLocaleUpperCase().substring(0, 3)
  }

  return (
    <div
      className={mergeClassName(
        'relative overflow-hidden border w-[65px] h-[65px] text-center',
        current && 'bg-slate-500 border border-slate-800 shadow',
        className,
      )}
    >

      { floatingNode }

      <div className={mergeClassName(
        'flex flex-col h-full',
        current && 'text-bold',
      )}
      >
        <div className={mergeClassName(
          'pt-px text-[10px] text-gray-400',
          current && 'text-gray-200',
        )}
        >
          { getWeekDay() }
        </div>
        <div className={mergeClassName(
          'text-xs text-gray-400',
          current && 'text-gray-200',
        )}
        >
          { formatDate(date, { showYear: false, shortMonth: true, shortDay: true }) }
        </div>
        <div className={mergeClassName(
          'pt-1 pb-2 text-gray-500',
          current && 'text-white',
        )}
        >
          { Math.ceil(price) }
        </div>
      </div>
    </div>
  )
}

export default DateBox
