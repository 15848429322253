import { useTranslation } from 'react-i18next'

import { useLanguage } from '../../modules/i18n'
import EnglishContent from './PrivacyPolicy.en'
import FrenchContent from './PrivacyPolicy.fr'
import PageTitle from '../../components/layout/PageTitle/PageTitle'
import FullWidthPage from '../../components/layout/FullWidthPage'
import Container from '../../components/Container/Container'

const PrivacyPolicyPage: React.FC = () => {
  const { t } = useTranslation()
  const language = useLanguage()

  return (
    <FullWidthPage>
      <PageTitle>{ t('pages.privacyPolicy.title') }</PageTitle>

      <Container>
        <div className="prose mb-8 max-w-none font-body prose-headings:font-body2">
          { language === 'fr' ? <FrenchContent /> : <EnglishContent /> }
        </div>
      </Container>
    </FullWidthPage>
  )
}

export default PrivacyPolicyPage
