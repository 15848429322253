import { cva, type VariantProps } from 'class-variance-authority'

export enum HeadingVariant {
  H1 = 'h1',
  H2 = 'h2',
  H3 = 'h3',
  H4 = 'h4',
  H5 = 'h5',
  H6 = 'h6',
}

export const headingVariants = cva(
  'font-body2',
  {
    variants: {
      variant: {
        [HeadingVariant.H1 as string]: 'text-[1.85rem] font-bold leading-[1.9rem] lg:text-4xl lg:leading-10',
        [HeadingVariant.H2 as string]: 'mt-3 text-[1.6rem] leading-8 lg:text-[2rem]',
        [HeadingVariant.H3 as string]: 'text-2xl font-bold text-gray-500',
        [HeadingVariant.H4 as string]: '',
        [HeadingVariant.H5 as string]: '',
        [HeadingVariant.H6 as string]: '',
      },
    },
    defaultVariants: {
      variant: HeadingVariant.H1,
    },
  },
)

type ReactHeading = React.DetailedHTMLProps<React.HTMLAttributes<HTMLHeadingElement>, HTMLHeadingElement>

type HeadingProps = ReactHeading & {
  as?: HeadingVariant | string
} & VariantProps<typeof headingVariants>

const Heading: React.FC<HeadingProps> = ({
  variant = HeadingVariant.H1,
  as,
  className,
  children,
  ...headingProps
}) => {
  const getHeadingComponent = (variant: HeadingVariant | string) => {
    switch (variant as HeadingVariant) {
      case HeadingVariant.H1:
        return 'h1'
      case HeadingVariant.H2:
        return 'h2'
      case HeadingVariant.H3:
        return 'h3'
      case HeadingVariant.H4:
        return 'h4'
      case HeadingVariant.H5:
        return 'h5'
      case HeadingVariant.H6:
        return 'h6'
      default:
        return 'h1'
    }
  }

  const HeadingComponent = getHeadingComponent(as ?? variant as string)

  return (
    <HeadingComponent
      {...headingProps}
      className={headingVariants({ variant, className })}
    >
      { children }
    </HeadingComponent>
  )
}

export default Heading
