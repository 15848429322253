import {
  type Quote,
  useQuotesView,
  useSortedQuote,
} from '../../../modules/quotes'
import QuoteComponent from './Quote'
import QuotesViewsDropdown from './QuotesViewsDropdown'

type QuotesProps = {
  quotes: Quote[]
}

const Quotes: React.FC<QuotesProps> = ({
  quotes,
}) => {
  const filteredQuotes = useSortedQuote(quotes)
  const quotesView = useQuotesView()

  return (
    <>
      <div className="mb-4 flex justify-end">
        <QuotesViewsDropdown />
      </div>

      <div
        key={quotesView}
        className="flex flex-col gap-8"
      >
        { filteredQuotes.map((quote, index) => (
          <QuoteComponent
            key={quote.id}
            quote={quote}
            best={index === 0}
          />
        )) }
      </div>
    </>
  )
}

export default Quotes
