import { type Move, type UpdateMovePayload } from '../../../../modules/moves'
import { residenceHasRooms } from '../../../../modules/quotes'
import { keyValueMapToRecord, recordToKeyValueMap } from '../../../../common/keyValueMap'
import { type MoveContentFormValues } from './MoveContentForm.type'

const removeZeroValues = <T extends Record<string, number>>(values: T): T => {
  return Object.fromEntries(
    Object.entries(values)
      .map(([key, value]) => ([key, parseInt(String(value))]))
      .filter(([, value]) => {
        return !!value
      }),
  ) as T
}

export const moveToFormData = (move: Move): MoveContentFormValues => {
  const residenceRooms = move.residenceRooms ? keyValueMapToRecord(move.residenceRooms) : []
  const specialItems = move.specialItems ? keyValueMapToRecord(move.specialItems) : []

  return {
    residenceType: move.residenceType,
    residenceRooms,
    residenceRoomsTotal: residenceRooms.length,
    nbBoxes: move.nbBoxes ?? 0,
    furnitureRatio: move.furnitureRatio ?? 0,
    specialItems,
    specialItemsTotal: specialItems.length,
  }
}

export const formatFormOutput = (values: MoveContentFormValues): Partial<UpdateMovePayload> => {
  const hasFurniture = (values.furnitureRatio ?? 0) > 0
  const hasRooms = residenceHasRooms(values.residenceType)
  const requiresRooms = hasRooms && hasFurniture

  return {
    residenceType: values.residenceType,
    nbBoxes: values.nbBoxes === 0 ? null : values.nbBoxes,
    furnitureRatio: values.furnitureRatio === 0 ? null : values.furnitureRatio,
    residenceRooms: values.residenceRooms && requiresRooms ? recordToKeyValueMap(removeZeroValues(values.residenceRooms)) : null,
    specialItems: values.specialItems ? recordToKeyValueMap(removeZeroValues(values.specialItems)) : null,
  }
}
