import { useTranslation } from 'react-i18next'
import CheckIcon from '@mui/icons-material/Check'

import Button, { ButtonColor, ButtonVariant } from '../../../Button'
import {
  Modal,
  ModalDescription,
  ModalHeader,
  ModalTitle,
  ModalFooter,
} from '../../../Modal'
import { useLanguage } from '../../../../modules/i18n'
import FrenchContent from './BookingTerms.fr'
import EnglishContent from './BookingTerms.en'

type BookingTermsModalProps = {
  open: boolean
  onClose: () => void
  onAccept: () => void
}

const BookingTermsModal: React.FC<BookingTermsModalProps> = ({
  open,
  onClose,
  onAccept,
}) => {
  const { t } = useTranslation()
  const language = useLanguage()

  return (
    <Modal
      open={open}
      onClose={() => { onClose() }}
      style={{ maxWidth: 800 }}
    >
      <ModalHeader>
        <ModalTitle>
          { t('pages.checkout.tacModal.title') }
        </ModalTitle>
      </ModalHeader>
      <ModalDescription className="max-h-[calc(100vh-250px)]">
        <div className="prose mb-8 max-w-none font-body prose-headings:font-body2">
          { language === 'fr' ? <FrenchContent /> : <EnglishContent /> }
        </div>
      </ModalDescription>
      <ModalFooter>
        <Button
          variant={ButtonVariant.Outlined}
          color={ButtonColor.Primary}
          onClick={onAccept}
          startIcon={<CheckIcon className="-mr-1" />}
        >
          { t('actions.accept') }
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default BookingTermsModal
