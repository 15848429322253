import { useCallback, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'

import { useLanguage } from '../../i18n'
import { useConfig } from '../../config'
import { ConsentCategory } from '../core'
import { useUpdateConsentAction } from '../core/consent.hooks'
import './cookieyes.css'

const FIFTY_MS = 50
const ONE_SECOND_MS = 1000
const FIVE_SECONDS_MS = 5 * ONE_SECOND_MS

const frequency = FIFTY_MS
const timeout = FIVE_SECONDS_MS

const Cookieyes: React.FC = () => {
  const { t } = useTranslation()
  const currentLanguage = useLanguage()
  const enabled = useConfig('cookieyes.enabled')
  const nbTries = useRef(0)
  const updateConsent = useUpdateConsentAction()

  /**
   * update the custom reject button when language is updated
   */
  const onScriptLoad = useCallback(() => {
    const categories: Record<ConsentCategory, boolean> = (window as any)?.getCkyConsent()?.categories ?? {}

    const accepted: ConsentCategory[] = Object
      .entries(categories)
      .filter(([, consent]) => !!consent)
      .map(([category]) => category as ConsentCategory)

    if (accepted.length) {
      updateConsent(accepted)
    }

    const closeIcon = document.querySelectorAll('.cky-consent-container .cky-banner-btn-close')[0]
    if (!closeIcon) {
      return
    }
    closeIcon.innerHTML = `<span class="custom-cookiesyes-close-button">${t('cookieConsentBar.rejectAll')}</span>`
  }, [t, updateConsent])

  /**
   * when consent is updated, update it in the store
   */
  useEffect(() => {
    const handleUpdate = (eventData: any) => {
      const accepted: ConsentCategory[] = eventData?.detail?.accepted ?? [ConsentCategory.Necessary]
      updateConsent(accepted)
    }

    document.addEventListener('cookieyes_consent_update', handleUpdate)

    return () => { document.removeEventListener('cookieyes_consent_update', handleUpdate) }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  /**
   * inject a "Reject all" span into the close icon since we hide the button on the banner
   */
  useEffect(() => {
    if (!enabled) {
      return
    }

    const checkIfLoaded = () => {
      const loaded = document.querySelectorAll('.cky-consent-bar').length > 0
      const timedOut = (nbTries.current * frequency) >= timeout
      nbTries.current++

      if (loaded) {
        onScriptLoad()
      }
      if (loaded || timedOut) {
        clearInterval(interval)
      }
    }

    const interval = setInterval(() => {
      checkIfLoaded()
    }, frequency)
    return () => { clearInterval(interval) }
  }, [enabled, onScriptLoad])

  /**
   * detect whenever we change language and there is a missmatch between
   * cookieyes language and app language
   * if so, reload their banner
   */
  useEffect(() => {
    if (!enabled) {
      return
    }

    const ckySettings = (window as any).ckySettings
    if (!ckySettings || currentLanguage === ckySettings.documentLang) {
      return
    }

    /* update cookieyes language */
    ckySettings.documentLang = currentLanguage

    /* remove their widgets from the DOM */
    const cookieYesElements = document.querySelectorAll('body>[class^=cky]')
    cookieYesElements.forEach(element => { element.remove() })

    /* reload their banner script */
    const mainScriptUrl = String((window as any).cookieyes.src)
    const bannerScriptUrl = mainScriptUrl.replace(/script\.js$/, 'banner.js')
    const script = document.createElement('script')
    script.setAttribute('src', `${bannerScriptUrl}?t=${Date.now()}`)
    script.setAttribute('async', 'true')
    script.onload = onScriptLoad
    document.body.appendChild(script)
  }, [currentLanguage, enabled, onScriptLoad])

  return null
}

export default Cookieyes
