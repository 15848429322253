import { useTranslation } from 'react-i18next'
import { PageContent } from '../../../components/Container'
import FullWidthPage from '../../../components/layout/FullWidthPage'
import { Heading } from '../../../components/typography'

const MoveLockedPage: React.FC = () => {
  const { t } = useTranslation()

  return (
    <FullWidthPage>
      <PageContent>

        <div className="pt-4 text-center">

          <Heading variant="h1">
            { t('pages.move.moveLocked.title') }
          </Heading>

          <div className="my-8 whitespace-pre-line font-sans text-lg">
            { t('pages.move.moveLocked.description') }
          </div>
        </div>

      </PageContent>
    </FullWidthPage>
  )
}

export default MoveLockedPage
