import AlertIcon from '@mui/icons-material/WarningAmber'

import { mergeClassName } from '../../utils/mergeClassName'

type ReactDiv = React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>

type AlertProps = {
  children?: React.ReactNode
  className?: string
} & ReactDiv

const Alert: React.FC<AlertProps> = ({
  children,
  className,
  ...props
}) => {
  if (!children) {
    return null
  }
  return (
    <div
      className={mergeClassName(
        'flex gap-2 items-center rounded-xl bg-red-50/50 text-red-800 font-sans py-2 px-3 text-sm',
        className,
      )}
      {...props}
    >
      <AlertIcon className="!text-[24px]" />
      { children }
    </div>
  )
}

export default Alert
