import * as Yup from 'yup'
import { useTranslation } from 'react-i18next'

import { type CheckoutFormValues } from './CheckoutForm.type'

type UseValidationSchemaOptions = {
  hasConstraintsWarnings?: boolean
}

const useValidationSchema = ({
  hasConstraintsWarnings = false,
}: UseValidationSchemaOptions = {}) => {
  const { t } = useTranslation()

  const conditionRequired = Yup.boolean().oneOf([true], t('formValidation.required')).required()

  const shape: Yup.ObjectShape = {
    estimateAcknowledged: conditionRequired,
    // termsAccepted: conditionRequired,
  }
  if (hasConstraintsWarnings) {
    shape.acceptConstraints = conditionRequired
  }

  return Yup.object<CheckoutFormValues>().shape(shape)
}

export default useValidationSchema
