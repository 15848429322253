import { useEffect, useState } from 'react'

import FullWidthPage from '../../../components/layout/FullWidthPage'
import DateTimeStep from './DateTimeStep'
import AddressesStep from './AddressesStep'
import MoveSizeStep from './MoveSizeStep'
import FormCarousel from '../../../components/FormCarousel'
import { pushCustomPageView } from '../../../modules/analytics'
import Route from '../../../app/Route.enum'
import { type QuotesRequestFormValues } from './QuotesRequestPage.type'
import QuotesPreviewPage from '../QuotesPreviewPage'
import { useSavedMoveId } from '../../../modules/moves'
import { useNavigate } from '../../../modules/navigation'

const QuotesRequestPage: React.FC = () => {
  const [quotesRequest, setQuotesRequest] = useState<QuotesRequestFormValues>()
  const { navigate } = useNavigate()
  const moveId = useSavedMoveId()
  const hasMove = !!moveId

  /**
   * redirects user to his move if any
   */
  useEffect(() => {
    if (hasMove) {
      navigate(Route.HomePage)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasMove])

  if (hasMove) {
    return null
  }

  /**
   * push tracking event
   */
  const onSlideChange = (step: string) => {
    pushCustomPageView(`${Route.QuotesRequest}/${step}`)
  }

  if (quotesRequest) {
    return (
      <QuotesPreviewPage quotesRequest={quotesRequest} />
    )
  }

  return (
    <FullWidthPage>
      <FormCarousel<QuotesRequestFormValues>
        onSubmit={setQuotesRequest}
        slides={{
          addresses: <AddressesStep />,
          dateTime: <DateTimeStep />,
          moveSize: <MoveSizeStep />,
        }}
        contentWidth={1300}
        wrapperClassName="max-h-[calc(100dvh-120px)] md:max-h-[calc(100dvh-220px)] xl:mb-0 xl:max-h-[calc(100dvh-356px)]"
        onSlideChange={onSlideChange}
      />

    </FullWidthPage>
  )
}

export default QuotesRequestPage
