import { useRef, useState, useEffect } from 'react'
import lottie, { type AnimationItem } from 'lottie-web'

type DivElement = React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>

type UseAnimatedSvgProps = {
  loop?: boolean
  autoplay?: boolean
} & DivElement

export const useAnimatedSvg = (animationData: object, {
  loop = true,
  autoplay = true,
  ...props
}: UseAnimatedSvgProps = {}) => {
  const elementRef = useRef<HTMLDivElement>(null)
  const [animation, setAnimation] = useState<AnimationItem>()

  useEffect(() => {
    if (!elementRef.current) {
      return
    }
    if ((elementRef.current.childNodes.length ?? 0) > 0) {
      return
    }

    setAnimation(lottie.loadAnimation({
      container: elementRef.current,
      renderer: 'svg',
      loop,
      autoplay,
      animationData,
    }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const element = <div ref={elementRef} {...props} />
  return [
    element,
    animation,
  ] as [JSX.Element, AnimationItem]
}
