import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useIntercom } from 'react-use-intercom'

import Route from '../../../../app/Route.enum'
import { useNavigate, useQueryString } from '../../../navigation'
import { useMove } from '../../../moves'
import ContainedPage from '../../../../components/layout/ContainedPage'
import Spinner from '../../../../components/Spinner'
import Alert from '../../../../components/Alert'
import Button, { ButtonVariant } from '../../../../components/Button'

const THREE_SECS_MS = 1000 * 2
const MAX_TRIES = 5

const StripeLandingPage: React.FC = () => {
  const { t } = useTranslation()
  const { navigate } = useNavigate()
  const { data: move, refetch } = useMove()
  const [timedout, setTimedout] = useState(false)
  const intercom = useIntercom()

  const stripeStatus = useQueryString('redirect_status')
  const paymentError = !([
    'processing',
    'succeeded',
  ].includes(stripeStatus))

  const openChat = () => {
    intercom.show()
  }

  /**
   * fetch move until we receive the deposit
   * this timeout after few tries
   */
  useEffect(() => {
    if (paymentError) {
      return
    }
    let i = 0
    const interval = setInterval(() => {
      i++
      if (i >= MAX_TRIES) {
        setTimedout(true)
        clearInterval(interval)
        return
      }
      refetch().catch(console.error)
    }, THREE_SECS_MS)

    return () => { clearInterval(interval) }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  /**
   * redirect to the confirmation page when we receive a deposit
   */
  useEffect(() => {
    if (move.deposit) {
      navigate(Route.Move)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [move])

  if (paymentError || timedout) {
    return (
      <ContainedPage>
        <div className="my-12 flex justify-center">
          <Alert>
            <div className="p-8">
              <div className="text-3xl font-bold">
                { t('pages.move.pendingDeposit.paymentInteralError.title') }
              </div>
              <div className="text-xl">
                { t('pages.move.pendingDeposit.paymentInteralError.subtitle') }
              </div>
            </div>
          </Alert>
        </div>

        <div className="my-4 text-center">
          <Button
            onClick={openChat}
            variant={ButtonVariant.Transparent}
            className="!text-xl !text-neutral-600 !underline"
          >
            { t('pages.move.pendingDeposit.paymentInteralError.contactUs') }
          </Button>
        </div>

      </ContainedPage>
    )
  }

  return (
    <ContainedPage>
      <Spinner floating />
    </ContainedPage>
  )
}

export default StripeLandingPage
