export enum ACTION_TYPES {
  SET_MOVE_ID = 'SET_MOVE_ID',
  SET_IS_LOGGING_OUT = 'SET_IS_LOGGING_OUT',
}

export const setMoveId = (moveId: string | undefined) => ({
  type: ACTION_TYPES.SET_MOVE_ID as const,
  moveId,
})

export const setIsLoggingOut = () => ({
  type: ACTION_TYPES.SET_IS_LOGGING_OUT as const,
})

export type Actions = ReturnType<typeof setMoveId> | ReturnType<typeof setIsLoggingOut>
