/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { set } from 'date-fns/set'
import { fromZonedTime, toZonedTime } from 'date-fns-tz'

import { type Move, type UpdateMovePayload } from '../../../../modules/moves'
import { type DateTimeFormValues } from './DateTimeForm.type'

export const moveToFormData = (move: Move): DateTimeFormValues => {
  return {
    movingDate: move.originTimezone
      ? toZonedTime(move.movingDate, move.originTimezone)
      : new Date(move.movingDate),
    preferredTime: move.preferredTime ?? [],
  }
}

export const formatFormOutput = (values: DateTimeFormValues): UpdateMovePayload => {
  /* moving date is expected to be midnight UTC */
  const movingDate = fromZonedTime(set(values.movingDate, {
    hours: 0,
    minutes: 0,
    seconds: 0,
    milliseconds: 0,
  }), 'UTC')

  return {
    ...values,
    movingDate,
  }
}
