import { type ConsentCategory } from './consent.types'

export enum ACTION_TYPES {
  SET_ACCEPTED = 'SET_ACCEPTED',
}

export const setConsentAccepted = (accepted: ConsentCategory[]) => ({
  type: ACTION_TYPES.SET_ACCEPTED as const,
  accepted,
})

export type Actions = ReturnType<typeof setConsentAccepted>
