import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { addDays } from 'date-fns/addDays'

import { ArrivalWindow } from '../../../../modules/quotes'
import { type UpdateMovePayload, useMove } from '../../../../modules/moves'
import Form, {
  CalendarInput,
  CardCheckboxGroup,
  CardLabel,
} from '../../../Form'
import SubmitBar from '../SubmitBar'
import { Label, Row, Cell, Field } from '../../../data'
import useValidationSchema from './validationSchema'
import { moveToFormData, formatFormOutput } from './formDataBuilder'
import { useConfig } from '../../../../modules/config'
import { type DateTimeFormValues } from './DateTimeForm.type'

type DateTimeFormProps = {
  onSubmit: (data: UpdateMovePayload) => Promise<void> | void
  onCancel?: () => void
}

const DateTimeForm: React.FC<DateTimeFormProps> = ({
  onCancel,
  onSubmit,
}) => {
  const { t } = useTranslation()
  const now = new Date()
  const { data: move } = useMove()

  const schema = useValidationSchema()
  const form = useForm({
    resolver: yupResolver(schema),
    defaultValues: moveToFormData(move),
  })

  const minDateDays = useConfig<number>('instantBooking.quote.minDateDays')
  const maxDateDays = useConfig<number>('instantBooking.quote.maxDateDays')

  const preferredTimeOptions = Object.fromEntries(Object.values(ArrivalWindow).map(arrivalWindow => ([
    arrivalWindow,
    <CardLabel
      key={arrivalWindow}
      title={t(`arrivalWindows.${arrivalWindow}.title`)}
    />,
  ])))

  const handleSubmit = async (values: DateTimeFormValues) => { await onSubmit(formatFormOutput(values)) }

  return (
    <Form
      form={form}
      onSubmit={handleSubmit}
    >
      <Row>
        <Cell>
          <Label>
            { t('components.move.dateTime.movingDate') }
          </Label>
          <Field>
            <CalendarInput
              name="movingDate"
              wrapperClassName="shadow ml-[-15px] sm:ml-0 lg:mr-4"
              minDate={addDays(now, minDateDays + 1)}
              maxDate={addDays(now, maxDateDays)}
            />
          </Field>
        </Cell>

        <Cell>
          <Label>
            { t('components.move.dateTime.preferredTime') }
          </Label>

          <Field>
            <CardCheckboxGroup
              name="preferredTime"
              options={preferredTimeOptions}
              small
            />
          </Field>

        </Cell>
      </Row>

      <SubmitBar onCancel={onCancel} />
    </Form>
  )
}

export default DateTimeForm
