import Link, { LinkVariant } from '../../components/Link'

const EnglishContent: React.FC = () => (
  <>
    <h2>Website Terms of Use</h2>

    <p>Version 1.0</p>

    <h2>Introduction</h2>

    <p>MovingWaldo Inc. ('we', 'us' or 'MovingWaldo') operates the web site www.movingwaldo.com (the 'Site') and multiple subdomains (including but not limited to app.movingwaldo.com, booking.movingwaldo.com, pro.movingwaldo.com, partners.movingwaldo.com, etc.) whereby we offer certain services, including, but not limited to, our Movers Booking services, our Moving Dashboard and our Change of Address services (our 'Services'). If you wish to use the Site as a visitor or sign up for a MovingWaldo user account (become a 'User'), regardless of whether you use any of our Services, please carefully read these terms of use (the 'Terms of Use' or this 'Agreement') and MovingWaldo's Privacy Policy (found at <Link variant={LinkVariant.Primary} target="_blank" to="https://movingwaldo.com/privacy-policy">https://movingwaldo.com/privacy-policy</Link>). This Agreement sets forth the terms and conditions that apply to your access and use of the Site (whether you are as a visitor or User) and the terms and conditions under which we provide our Services.
    </p>

    <h2>Website operation</h2>

    <p>The Website is operated by us in Canada and in the United States. We make no representation that parts of the Website are appropriate or may be used in places outside Canada and the United States. You are responsible to ensure that your access to this Website and Content available on or through it are legal in each jurisdiction in or through which you access or view the Website or such Content. People choosing to use the Website do so at their own risk and they are responsible for complying with all local laws.</p>

    <h2>Privacy and your personal information</h2>

    <p>For information about MovingWaldo's data protection and privacy practices, please read our Privacy Policy, which is hereby incorporated by reference into this Agreement. The Privacy Policy explains how we treat your personal information when you access the Site or use the Services. The Privacy Policy may be updated from time to time at MovingWaldo's discretion. Changes to the Privacy Policy will be effective upon posting to the Site.</p>

    <h2>Accepting the terms</h2>

    <p>By using the Site or ordering one of our Services, you agree to be bound by the terms and conditions of this Agreement and MovingWaldo's Privacy Policy, as they may be amended from time to time in the future. You may not use the Site or order Services if you're not of a legal age to form a binding contract with MovingWaldo. If you accept this Agreement, you represent that you have the capacity to be bound by it or, if you're acting on behalf of a person or entity, that you have the authority to bind such person or entity to it.</p>

    <h2>Changes to this agreement</h2>

    <p>MovingWaldo may amend this Agreement at any time, with or without notice to you, by posting amended Terms of Use on the Site. MovingWaldo will clearly indicate the date of the last amendment at the top of this page (https://movingwaldo.com/terms-of-use). The most current version of the Terms of Use will supersede all previous versions. It's your responsibility to check this Agreement periodically for changes. You hereby accept and agree to be bound by future changes unless and until you expressly inform MovingWaldo that you no longer agree to this Agreement. If you do so, your user account will be terminated and you'll no longer be able to use the Site or order Services.</p>

    <h2>User account information</h2>

    <p>By opening an account with MovingWaldo, you will choose a password and email address combination that will allow you to access the website as a user. If you were to share this information with third parties, MovingWaldo would not have any way to keep your information private from these third parties, hence, you agree that you are responsible for maintaining the confidentiality of this information. In any case, you are bound by the terms of use of MovingWaldo to keep the login information confidential and for your personal use only. Certain services may also be administered directly through your email account. In this case, you are responsible for maintaining the confidentiality of your email account. If you become aware of any unauthorized use of your MovingWaldo account information, you must notify us immediately at people@movingwaldo.com.</p>

    <h2>Your use of the site and services</h2>

    <p>You are only entitled to access the Site and use the Services for lawful purposes. You shall provide true, accurate, current and complete information, and you shall not misrepresent your information or use or input on the Site any other person's information without their express consent. Any fraudulent activity will be reported to federal authorities for prosecution. Your access to and use of the Site and Services may be interrupted from time to time for any reason, including, without limitation, the malfunction of equipment, periodic updating, maintenance or repair of the Site, or other actions that MovingWaldo, in its sole discretion, may elect to take.</p>

    <p>You agree that MovingWaldo may use your feedback, suggestions, or ideas in any way, including in future modifications of the Services, other products or services, advertising or marketing materials, whether such feedback or comments are emailed to MovingWaldo, entered on the Site, or entered on a third party website. You grant MovingWaldo a perpetual, worldwide, fully transferable, sub-licensable, irrevocable, fully paid-up, royalty-free licence to use the feedback and comments you provide to MovingWaldo in any way.</p>

    <p>You understand and agree that we may review and delete any content that you provide, including messages, identifiers, company names or profiles that in our sole judgment violates this Agreement, or that may be offensive, illegal, and inaccurate or that may violate the rights of others.</p>

    <h2>MovingWaldo inc.' services</h2>

    <p>MovingWaldo reserves the right at any time and from time to time to modify or discontinue, temporarily or permanently, the Services (or any part thereof) with or without notice. As set forth in detail below, you agree that MovingWaldo shall not be liable to you or to any third party for any modification, suspension, failure or discontinuance of the Services.</p>

    <p>MovingWaldo offers multiple services to help users with their moving needs. These services are rendered to users through different platforms and subdomains owned by MovingWaldo Inc, or through our customer service and booking agents. The current privacy policy applies to the services described below. For more information on other services, please refer to <Link variant={LinkVariant.Primary} target="_blank" to="https://www.movingwaldo.com/privacy-policy">https://www.movingwaldo.com/privacy-policy</Link>. </p>

    <h3>MovingWaldo's movers booking service</h3>

    <p>MovingWaldo is a website that facilitates the booking of third-party movers for users. We verify the quality of recommended movers, but they operate independently. MovingWaldo does not guarantee the services provided by third-party movers, and we assume no responsibility for their actions, conduct, or the quality of their services. </p>

    <p>As part of our service, MovingWaldo may provide an estimate of the cost of moving services. We may also coordinate the move between you and the moving company that you decide to hire for your move. We may also handle the financial transaction associated with the move, meaning that we will collect the funds from you, and debit the funds to the moving company once the services have been performed. </p>

    <p>MovingWaldo's services do not include the actual moving of your belongings. This portion will be the entire responsibility of the moving company that you, to your entire discretion, may decide to hire to complete the move. We cannot be held responsible for anything arising from the move itself. </p>

    <p>If you decide to hire a moving company through our platform or booking agents, a complete moving estimate and moving contract will be communicated to you and will contain the terms and conditions of the move.</p>

    <h2>Term</h2>

    <p>You may terminate this Agreement and your account with MovingWaldo at any time and for any reason by sending us an email at people@movingwaldo.com. We may terminate this Agreement with you, terminate your account, and elect not to implement our Services for you at any time and for any reason, effective upon sending notice to you at the email address you provided for your account.</p>

    <h2>MovingWaldo inc.'s intellectual property rights</h2>

    <p>The contents of the Site, including its look and feel (e.g. text, graphics, images, logos and button icons), photographs, content, notices, software, patented or patentable systems and methods, copyrighted material, trademarks, and other proprietary are protected under applicable copyright, trademark and other laws. The Proprietary Information belongs exclusively to MovingWaldo. You may not copy, modify, publish, transmit, distribute, perform, display, or sell any such Proprietary Information. MovingWaldo grants you the right to view and use the Site subject to these terms. Any distribution, reprint or electronic reproduction of any content from MovingWaldo in whole or in part for any other purpose is expressly prohibited without our prior written consent.</p>

    <h2>Disclaimers</h2>

    <p>MovingWaldo, and the person or company that referred you to MovingWaldo, make no representations, warranties or guarantees, express or implied, regarding the accuracy, reliability, safety or completeness of the content on the site or of the services. MovingWaldo assumes no responsibility for any error, omission, interruption, deletion, defect, communication failure, theft or destruction or unauthorized access to, or alteration and use of, User information. MovingWaldo is not responsible for any problems or technical malfunction of any telephone network or lines, computer systems, servers or providers, computer equipment, software, failure of email on account, or technical problems or traffic congestion on the Internet or any combination thereof, including injury or damage to Site visitors, Users or to any other person's computer related to or resulting from participating or downloading materials in connection with the web and/or in connection with the Services.</p>

    <p>Under no circumstances will MovingWaldo be responsible for any loss or damage, including personal injury or death, resulting from anyone's use of the Site or the Services. The Site and the Services are provided ‘AS-IS' and (to the extent legally permitted), MovingWaldo expressly disclaims all warranties or conditions of any kind, express or implied, including without limitation the implied warranties of title, non-infringement, merchantability and fitness for a particular purpose. MovingWaldo makes no warranty that the Site will be error-free and that access thereto will be continuous and uninterrupted. MovingWaldo can't guarantee and doesn't promise any specific results from use of the Site and/or the Services. If you decide to register as a User or to use the Services, you do so at your own discretion and risk. MovingWaldo is not responsible for the conduct, whether online or offline, of any User.</p>

    <p>You are responsible for making the decision regarding the choice of a third-party moving company. We are not responsible for services rendered by our network of vendors. Part of MovingWaldo's service is to recommend local or national vendors that we curated based on our primary verification process and/or our Waldo Verified process (found at <Link variant={LinkVariant.Primary} target="_blank" to="https://www.movingwaldo.com/community-resources/verification-process/">https://www.movingwaldo.com/community-resources/verification-process/</Link>). While we do our utmost in order to put you in touch with reliable and reputable companies, we can not guarantee prices and job quality. MovingWaldo is not liable for damages arising from a service agreement taken with a vendors listed in our network.
      MovingWaldo is not part of contractual agreements between the vendor and client. None of the clauses to come will link our service from any obligations in terms of the realization of the service rendered by a vendor listed www.movingwaldo.com.
    </p>

    <p>You agree to abide by the policies and procedures of the third-party moving company with whom you book through MovingWaldo. Any disputes or issues arising from your relationship with a third-party moving company must be resolved directly with the respective moving company.</p>

    <h2>General information</h2>

    <p>You agree that no joint venture, partnership, employment, or agency relationship exists between you and us as a result of these Terms of use or any use of the Website. You may not assign any right, interest, or benefit provided under these Terms of use or through the Website without our express prior written consent.</p>

    <h2>Limitation of liability</h2>

    <p>In no event is MovingWaldo, or the person or company that referred you to MovingWaldo, liable to any visitor to the Site, User or third party for any indirect, consequential, exemplary, incidental, special or punitive damages (however arising, including negligence), including lost profits arising out of, or relating to any use or misuse of the Site or Services or any part thereof. Our Website contains links to other websites which are owned and operated by third parties. These links will let you leave the Website. The linked sites are not under our control and we are not responsible for the contents of any linked site or any link contained in a linked site, or any changes or updates to such sites. We are not responsible for webcasting or any other form of transmission received from any linked site. We provide the links to you only as a convenience. We do not endorse the site or its use or contents.</p>

    <h2>Your indemnification of MovingWaldo inc.</h2>

    <p>You shall defend, indemnify and hold harmless MovingWaldo and its officers, directors, shareholders, and employees, from and against all claims and expenses, including but not limited to attorney's fees, in whole or in part, arising out of or attributable to any breach of this Agreement by you.</p>

    <h2>Claims or disputes</h2>

    <p>If there is any claim, controversy or dispute at law or equity about this Agreement, the Site and/or the Services, you agree that you and we'll resolve any claim in accordance with this paragraph. This Agreement will be governed in all respects by the laws of the Province of Quebec, without regard to its conflicts of law principles. With respect to any disputes or claims, you acknowledge and understand that exclusive jurisdiction over any cause of action arising out of this Agreement or the Services shall be in the province or federal courts located in or near the city of Montreal, Quebec. You agree to submit to the jurisdiction of such courts, and you hereby waive all defences of lack of personal jurisdiction and forum non-convenient with respect to venue and jurisdiction in the province and federal courts located in or near the city of Montreal, Quebec.</p>

    <h2>Miscellaneous</h2>

    <p>If any portion of this Agreement is deemed unlawful, void or unenforceable by any arbitrator or court of competent jurisdiction, this Agreement as a whole shall not be deemed unlawful, void or unenforceable, but only that portion of this Agreement that is deemed unlawful, void or unenforceable shall be stricken from this Agreement.</p>

  </>
)

export default EnglishContent
