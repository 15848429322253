import { mergeClassName } from '../../../utils/mergeClassName'

type ReactUl = React.DetailedHTMLProps<React.HTMLAttributes<HTMLUListElement>, HTMLUListElement>

const FaqList: React.FC<ReactUl> = ({
  className,
  children,
  ...props
}) => {
  return (
    <ul
      className={mergeClassName(
        'md:ml-4 flex flex-col gap-y-8',
        className,
      )}
      {...props}
    >
      { children }
    </ul>
  )
}

export default FaqList
