import * as Yup from 'yup'

import { type IdentificationFormValues } from './IdentificationForm.type'
import { usePhoneNumberValidator } from '../../../Form/fields/PhoneNumberField/validator'

const useValidationSchema = () => {
  const validatePhoneNumber = usePhoneNumberValidator()

  return Yup.object<IdentificationFormValues>().shape({
    firstName: Yup.string().required(),
    lastName: Yup.string().required(),
    email: Yup.string().email().required(),
    phoneNumber: validatePhoneNumber({ required: true }),
  })
}

export default useValidationSchema
