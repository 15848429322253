import movingwaldo from './movingwaldo.theme'
import blueDemo from './blueDemo.theme'
import pillartopost from './pillartopost.theme'
import maxwellrealty from './maxwellrealty.theme'

const themes = {
  movingwaldo,
  blueDemo,
  pillartopost,
  maxwellrealty,
}

export default themes
