import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

import Form, { PlaceField, SubmitButton } from '../../../Form'
import { ButtonSize } from '../../../Button'
import { Heading, Subtitle } from '../../../typography'
import useValidationSchema from './validationSchema'
import { type AddressesFormValues } from './AddressesForm.type'
import NbStairsControl from './NbStairsControl'
import { formatFormOutput } from './formDataBuilder'

type AddressesFormProps = {
  onSubmit: (data: AddressesFormValues) => Promise<void> | void
}

const AddressesForm: React.FC<AddressesFormProps> = ({
  onSubmit,
}) => {
  const { t } = useTranslation()
  const schema = useValidationSchema()
  const form = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      origin: { nbStairs: 0 },
      destination: { nbStairs: 0 },
    },
  })

  const handleSubmit = async (values: AddressesFormValues) => { await onSubmit(formatFormOutput(values)) }

  return (
    <Form form={form} onSubmit={handleSubmit}>
      <div className="flex flex-col gap-8 lg:mb-12 lg:mt-4 lg:flex-row xl:gap-8">

        <div className="flex-1 rounded-2xl border border-neutral-100 p-8 shadow">
          <Heading variant="h1" className="text-center lg:text-left lg:text-[1.5rem]">
            { t('forms.quotesRequest.addresses.origin.title') }
          </Heading>
          <Subtitle className="lg:text-lg">
            { t('forms.quotesRequest.addresses.origin.subtitle') }
          </Subtitle>
          <div className="mt-4 lg:my-8">
            <PlaceField name="origin.address" />
            <NbStairsControl name="origin.nbStairs" />
          </div>
        </div>

        <div className="flex-1 rounded-2xl border border-neutral-100 p-8 shadow">
          <Heading variant="h1" className="text-center lg:text-left lg:text-[1.5rem]">
            { t('forms.quotesRequest.addresses.destination.title') }
          </Heading>
          <Subtitle className="lg:text-lg">
            { t('forms.quotesRequest.addresses.destination.subtitle') }
          </Subtitle>
          <div className="my-4 lg:my-8">
            <PlaceField name="destination.address" />
            <NbStairsControl name="destination.nbStairs" />
          </div>
        </div>
      </div>

      <div className="mt-8">
        <SubmitButton
          className="w-full lg:w-auto"
          size={ButtonSize.Large}
        >
          { t('forms.quotesRequest.nextStepCta', {
            nextStep: t('forms.quotesRequest.steps.dateTime'),
          }) }
        </SubmitButton>
      </div>
    </Form>
  )
}

export default AddressesForm
