import { useFormContext, useWatch } from 'react-hook-form'
import Checkbox from '@mui/material/Checkbox'

import { mergeClassName } from '../../../../utils/mergeClassName'
import Alert from '../../../Alert'

export type CardCheckboxGroupProps = {
  name: string
  options: Record<string, React.ReactNode>
  className?: string
  itemClassName?: string
  small?: boolean
}

const CardCheckboxGroup: React.FC<CardCheckboxGroupProps> = ({
  name,
  options,
  className,
  itemClassName,
  small = false,
}) => {
  const { setValue, formState: { errors, submitCount, defaultValues } } = useFormContext()
  const value: string[] = useWatch({ name }) ?? []
  const formErrors = errors[name]
  const editing = !!defaultValues?.[name]
  const shouldValidate = submitCount > 0 || editing

  const onClick = (selectedValue: string) => {
    if (value.includes(selectedValue)) {
      setValue(name, [...value.filter(v => v !== selectedValue)], { shouldValidate })
    } else {
      setValue(name, [...value, selectedValue], { shouldValidate })
    }
  }

  return (
    <div>
      <div className={mergeClassName(
        'flex flex-col justify-between gap-2 lg:gap-4',
        small && 'gap-1 lg:gap-2',
        className,
      )}
      >
        { Object.entries(options).map(([name, label]) => {
          const checked = value.includes(name)
          return (
            <label
              key={name}
              className={mergeClassName(
                'flex items-center cursor-pointer rounded-xl bg-neutral-100 pl-3 pr-7 py-2 text-gray-600',
                itemClassName,
                small && 'text-sm py-0 bg-neutral-50',
                checked && 'bg-zinc-200/75 text-black child:text-black',
              )}
            >
              <Checkbox
                checked={checked}
                onClick={() => { onClick(name) }}
              />
              { label }
            </label>
          )
        }) }
      </div>

      { typeof formErrors?.message === 'string' && (
        <div className="mt-2 lg:mt-4">
          <Alert>
            { formErrors.message }
          </Alert>
        </div>
      ) }
    </div>
  )
}

export default CardCheckboxGroup
