import { type TextFieldProps as MuiTextFieldProps } from '@mui/material/TextField'
import InputAdornment from '@mui/material/InputAdornment'

import { type InputProps, InputSize } from './Input'
import { mergeClassName } from '../../../../utils/mergeClassName'

export const getMuiTextfieldProps = ({
  size = InputSize.Medium,
  width,
  label,
  placeholder,
  startNode,
  endNode,
  type,
  center = false,
  maxLength,
  className,
}: Omit<InputProps, 'name'>): MuiTextFieldProps => {
  className = mergeClassName(
    'child:bg-white',
    className,
  )

  const muiProps: Partial<MuiTextFieldProps> = {
    fullWidth: true,
    type,
    margin: 'none',
    placeholder,
    label,
    className,
  }

  const muiSize = {
    [InputSize.Small]: 'small',
    [InputSize.Medium]: 'medium',
  }[size]
  muiProps.size = muiSize as any

  if (width) {
    muiProps.style = { width }
  }
  muiProps.InputProps = {}

  if (startNode) {
    muiProps.InputProps.startAdornment = (
      <InputAdornment position="start">
        { startNode }
      </InputAdornment>
    )
  }

  if (endNode) {
    muiProps.InputProps.endAdornment = (
      <InputAdornment position="end">
        { endNode }
      </InputAdornment>
    )
  }

  muiProps.inputProps = {}

  if (center) {
    muiProps.inputProps.style = {
      ...muiProps.inputProps.style,
      textAlign: 'center',
    }
  }

  if (maxLength) {
    muiProps.inputProps.maxLength = maxLength
  }

  return muiProps
}
