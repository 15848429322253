import { type MoveDetails, type MoveSizeDetails, type MoveSizeEstimate } from '../quotes.types'
import { calculateNumberOfTrucks } from '../utils'
import { estimateMovingLabourTime } from './movingLabourEstimator'
import { estimateNbMen } from './nbMenEstimator'
import { estimateNbTrucks } from './nbTrucksEstimator'
import { estimatePreliminaryVolume, estimateVolume } from './volumeEstimator'
import { estimateWeight } from './weightEstimator'

const estimateFromVolume = (volumeCubicFeet: number) => {
  const weightPounds = estimateWeight(volumeCubicFeet)
  const nbTrucks = estimateNbTrucks(volumeCubicFeet)
  const nbMen = Math.max(estimateNbMen(volumeCubicFeet), calculateNumberOfTrucks(nbTrucks))
  const estimatedMovingLabourTime = estimateMovingLabourTime(volumeCubicFeet, nbMen)

  return {
    weightPounds,
    nbMen,
    estimatedMovingLabourTime,
    nbTrucks,
  }
}

export const estimatePreliminaryMoveSize = (moveDetails: MoveDetails): MoveSizeEstimate => {
  const volumeCubicFeet = estimatePreliminaryVolume(moveDetails)

  return {
    volumeCubicFeet,
    ...estimateFromVolume(volumeCubicFeet),
  }
}

export const estimateMoveSize = (move: MoveSizeDetails) => {
  const volumeCubicFeet = estimateVolume(move)

  return {
    volumeCubicFeet,
    ...estimateFromVolume(volumeCubicFeet),
  }
}
