import { useTranslation } from 'react-i18next'
import Container, { PageContent } from '../../../components/Container'
import FullWidthPage from '../../../components/layout/FullWidthPage'
import Spinner from '../../../components/Spinner'
import { useEffectOnce } from '../../../utils/useEffectOnce'
import { useRefreshQuotesAction } from '../../../modules/moves'
import { useErrorFormatter } from '../../../components/errors/useErrorFormatter'
import { errorNotification } from '../../../components/ToastNotifications'

const RefreshingQuotesPage: React.FC = () => {
  const { t } = useTranslation()
  const { refreshQuotes } = useRefreshQuotesAction()
  const formatError = useErrorFormatter()

  useEffectOnce(() => {
    try {
      refreshQuotes().catch((error: any) => { errorNotification(formatError(error)) })
    } catch (error) {
      errorNotification(formatError(error))
    }
  })

  return (
    <FullWidthPage>
      <Container>
        <PageContent>
          <div className="my-24 flex flex-col items-center justify-center gap-8">
            <Spinner />

            <div className="flex flex-col gap-4">
              <div className="text-center font-body2 text-3xl uppercase leading-tight text-slate-600">
                { t('pages.quotesRequest.updatingQuotes') }
              </div>

              <div className="text-center font-body2 text-2xl uppercase leading-tight text-slate-400">
                { t('pages.quotesRequest.fetchingQuotes') }
              </div>
            </div>
          </div>
        </PageContent>
      </Container>
    </FullWidthPage>
  )
}

export default RefreshingQuotesPage
