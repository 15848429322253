import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'

import { SpecialItem, useMoveSizeEstimate } from '../../../modules/quotes'
import { type Move, type UpdateMovePayload } from '../../../modules/moves'
import Form, { QuantityCollectionField, SubmitButton } from '../../Form'
import { Subtitle } from '../../typography'
import { formatFormOutput, moveToFormData } from './formDataBuilder'
import { type SpecialItemsFormValues } from './SpecialItemsForm.type'

type SpecialItemsFormProps = {
  move: Move
  onSubmit: (data: UpdateMovePayload) => Promise<void> | void
}

const SpecialItemsForm: React.FC<SpecialItemsFormProps> = ({
  move,
  onSubmit,
}) => {
  const { t } = useTranslation()
  const estimateMoveSize = useMoveSizeEstimate()

  const form = useForm({
    defaultValues: moveToFormData(move),
  })

  const handleSubmit = async (data: SpecialItemsFormValues) => {
    const outputData = formatFormOutput(data)
    const newVolume = estimateMoveSize({
      ...move,
      ...outputData,
    })

    await onSubmit({
      ...outputData,
      ...newVolume,
    })
  }

  const specialItemsOptions = Object.values(SpecialItem).map(specialItem => ({
    value: specialItem,
    label: t(`specialItems.${specialItem}.title`),
  }))

  return (
    <div className="text-center">
      <Form form={form} onSubmit={handleSubmit}>

        <Subtitle className="mb-12 mt-6 lg:text-center">
          { t('forms.specialItems.title') }
        </Subtitle>

        <QuantityCollectionField
          name="specialItems"
          options={specialItemsOptions}
        />

        <div className="mt-12">
          <SubmitButton>
            { t('actions.next') }
          </SubmitButton>
        </div>
      </Form>
    </div>
  )
}

export default SpecialItemsForm
