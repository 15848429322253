import { type Amount } from '../../../common/amount'
import { PromoCodeType } from './promoCodes.enums'
import { type PromoCode } from './promoCodes.models'

/**
 * apply promocode to a given amount
 */
export const applyPromoCode = (amount: Amount, promoCode: PromoCode): Amount => {
  switch (promoCode.type) {
    case PromoCodeType.Amount:
      return {
        ...amount,
        price: Math.max(amount.price - promoCode.value, 0),
      }
    case PromoCodeType.Percentage: {
      const rebate = Math.floor(((amount.price / 100) * promoCode.value) * 100) / 100
      return {
        ...amount,
        price: Math.max(amount.price - rebate, 0),
      }
    }
    default:
      return amount
  }
}
