import { Trans, useTranslation } from 'react-i18next'

import { useMove, useSelectedQuote } from '../../../modules/moves'
import { dateInTimezone, useDateFormatter } from '../../../utils/date'
import { QuoteConstraint } from '../../../modules/quotes'

export const useConstraintsWarnings = () => {
  const { t } = useTranslation()
  const formatDate = useDateFormatter()
  const selectedQuote = useSelectedQuote()
  const { data: move } = useMove()

  const warnings: Partial<Record<QuoteConstraint, React.ReactNode>> = {}

  selectedQuote?.constraints?.forEach(constraint => {
    switch (constraint) {
      case QuoteConstraint.unavailableDate: {
        const MovingDate = () => (
          <strong>
            { formatDate(
              dateInTimezone(move.movingDate, move.originTimezone)
              , { showYear: false, shortMonth: true }) }
          </strong>
        )
        const QuoteDate = () => (
          <strong>
            { formatDate(
              dateInTimezone(selectedQuote.date, move.originTimezone),
              { showYear: false, shortMonth: true }) }
          </strong>
        )

        warnings[constraint] = (
          <div>
            <Trans i18nKey={`quotes.constraints.${constraint}`}>
              <MovingDate />
              <QuoteDate />
            </Trans>
          </div>
        )

        break
      }
      case QuoteConstraint.notEnoughTrucks:
      case QuoteConstraint.additionalServicesNotOffered:
      case QuoteConstraint.specialItemsNotSupported:
        warnings[constraint] = t(`quotes.constraints.${constraint}`)
        break
    }
  })

  return warnings
}
