import { useEffect, useState } from 'react'
import { useTranslation, Trans } from 'react-i18next'
import { useForm, useFormState } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import CalendarIcon from '@mui/icons-material/CalendarMonth'
import MessageIcon from '@mui/icons-material/ChatOutlined'

import {
  type AcceptQuotePayload,
  type PaymentRequest,
  useMove,
  useSelectedQuote,
} from '../../../modules/moves'
import {
  usePromoCodeLabel,
  usePromoCodeRebate,
  useQuoteSubtotalWithPromoCode,
} from '../../../modules/promoCodes'
import { dateInTimezone, useDateFormatter } from '../../../utils/date'
import Form, {
  MoveDepositField,
  SingleCheckbox,
  SubmitButton,
  Textarea,
} from '../../../components/Form'
import { QuoteDetails } from '../../../components/quotes/Quotes/Quote'
import Link, { type LinkProps, LinkVariant } from '../../../components/Link'
import Price from '../../../components/Price'
import SelectedQuote from '../../../pages/move/CheckoutPage/SelectedQuote'
import { useConstraintsWarnings } from './useConstraintsWarnings'
import TimeControl from './TimeControl'
import { useDefaultTime } from './useDefaultTime'
import { formatFormOutput } from './formDataBuilder'
import { type CheckoutFormValues } from './CheckoutForm.type'
import useValidationSchema from './validationSchema'
import BookingTermsModal from './BookingTermsModal'
import PromoCodeForm from '../PromoCodeForm'

const TermsLink: React.FC<Partial<LinkProps>> = props => {
  const { t } = useTranslation()

  return (
    <Link
      variant={LinkVariant.Primary}
      {...props}
      to="#"
    >
      { t('forms.checkout.terms') }
    </Link>
  )
}

type CheckoutFormProps = {
  onSubmit: (values: AcceptQuotePayload) => Promise<void> | void
  onPromoCodeSubmit?: (promoCode: string | null) => Promise<void> | void
  paymentField?: React.ReactNode
  paymentRequest?: PaymentRequest
  hasValidPaymentField?: boolean
}

const CheckoutForm: React.FC<CheckoutFormProps> = ({
  onSubmit,
  onPromoCodeSubmit,
  paymentField,
  paymentRequest,
  hasValidPaymentField = true,
}) => {
  const { t } = useTranslation()
  const { data: move } = useMove()
  const selectedQuote = useSelectedQuote()
  const constraintsWarnings = useConstraintsWarnings()
  const formatDate = useDateFormatter()
  const defaultTime = useDefaultTime(
    selectedQuote?.companyBranch.services.arrivalWindows ?? [],
    move.preferredTime ?? [],
  )

  const promoCodeRebate = usePromoCodeRebate(selectedQuote, move.promoCode)
  const quoteSubtotalWithPromoCode = useQuoteSubtotalWithPromoCode(selectedQuote, move.promoCode)
  const promoCodeLabel = usePromoCodeLabel(move.promoCode)

  const hasConstraintsWarnings = Object.keys(constraintsWarnings).length > 0
  const hasCustomQuote = !!selectedQuote?.customized

  const schema = useValidationSchema({ hasConstraintsWarnings })
  const form = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      movingTime: defaultTime,
      origin: move.origin,
      destination: move.destination,
    },
  })
  const [tacOpen, setTacOpen] = useState(false)
  const paymentFieldName = '_payment'
  const { submitCount } = useFormState({ control: form.control })

  const handleSubmit = async (data: CheckoutFormValues) => { await onSubmit(formatFormOutput(data)) }

  const handleAcceptTac = () => {
    form.setValue('termsAccepted', true, { shouldValidate: true })
    setTacOpen(false)
  }

  /**
   * revalidate the form when constraints are updated
   */
  useEffect(() => {
    if (submitCount > 0) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      form.trigger()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasConstraintsWarnings])

  /**
   * set form error when there's a payment field is invalid
   */
  useEffect(() => {
    if (submitCount === 0) {
      return
    }
    if (hasValidPaymentField) {
      form.clearErrors(paymentFieldName)
    } else {
      form.setError(paymentFieldName, {})
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasValidPaymentField, submitCount])

  if (!selectedQuote) {
    return null
  }

  return (
    <>
      <BookingTermsModal
        open={tacOpen}
        onClose={() => { setTacOpen(false) }}
        onAccept={handleAcceptTac}
      />
      <div className="hidden xl:flex">
        <SelectedQuote />
      </div>

      <div className="p-4 pb-8 xl:py-8">
        <QuoteDetails
          quote={selectedQuote}
          open
          hideSubtotal
        />
        <div className="mt-8 flex flex-col items-end">

          { promoCodeRebate && quoteSubtotalWithPromoCode
            ? (
              <>
                <div className="flex w-full items-center justify-end font-sans text-base uppercase text-neutral-400">
                  <div className="line-through child:line-through">
                    <Price amount={selectedQuote.subtotal} />
                  </div>
                </div>
                <div className="mb-8 flex w-full max-w-[400px] items-center justify-between gap-8 font-sans text-base uppercase text-green-800">
                  { promoCodeLabel }
                  <div className="text-xl font-bold">
                    <Price amount={promoCodeRebate} />
                  </div>
                </div>

                <div className="flex w-full max-w-[400px] items-center justify-between gap-8 font-sans text-base uppercase text-neutral-600">
                  { t('pages.checkout.estimate') }:
                  <div className="text-xl font-bold">
                    <Price amount={quoteSubtotalWithPromoCode} />
                    <div className="text-right text-sm font-normal">
                      ({ t('quotes.quote.plusTaxes') })
                    </div>
                  </div>
                </div>
              </>
              )
            : (
              <div className="flex w-full max-w-[400px] items-center justify-between gap-8 font-sans text-base uppercase text-neutral-600">
                { t('pages.checkout.estimate') }:
                <div className="text-xl font-bold">
                  <Price amount={selectedQuote.subtotal} />
                  <div className="text-right text-sm font-normal">
                    ({ t('quotes.quote.plusTaxes') })
                  </div>
                </div>
              </div>
              ) }
        </div>
      </div>

      { onPromoCodeSubmit && (
        <PromoCodeForm onSubmit={onPromoCodeSubmit} />
      ) }

      <Form form={form} onSubmit={handleSubmit}>

        { paymentField && paymentRequest && (
          <MoveDepositField
            paymentRequest={paymentRequest}
          >
            { paymentField }
          </MoveDepositField>
        ) }

        <div className="my-4 rounded-md border bg-white p-4 lg:p-8">

          <div className="flex flex-col justify-between gap-y-2 sm:flex-row">
            <div className="flex items-center gap-3 font-sans text-lg">
              <CalendarIcon className="!text-[18px] text-neutral-500" />
              <span>
                { formatDate(dateInTimezone(selectedQuote.date, move.originTimezone)) }
              </span>
            </div>

            <div className="flex items-center gap-3 font-sans text-xl">
              <TimeControl name="movingTime" />
            </div>
          </div>

          <div className="mt-4 flex flex-col justify-between gap-2 text-neutral-500">
            <div className="flex items-center gap-3 font-sans">
              <MessageIcon className="!text-[18px]" />
              { t('forms.fields.moversMessage.label') }
            </div>

            <Textarea name="message" />
          </div>
        </div>

        { hasConstraintsWarnings && (
          <div className="my-2 rounded-md border border-orange-200 bg-orange-50 px-4 py-2">
            <SingleCheckbox
              name="acceptConstraints"
              label={(
                <div className="prose max-w-none pl-1 font-sans text-sm leading-5 text-neutral-700">
                  <span className="text-neutral-400">
                    { t('forms.checkout.IAcknowledgeTheFollowing') }:
                  </span>
                  <ul>
                    { Object.entries(constraintsWarnings).map(([constraint, warning]) => (
                      <li key={constraint} className="child:m-0">
                        { warning }
                      </li>
                    )) }
                  </ul>
                </div>
              )}
            />
          </div>
        ) }

        <div className="my-2 rounded-md border bg-neutral-100 px-4 py-2">
          <SingleCheckbox
            name="estimateAcknowledged"
            label={(
              <div className="py-2 pl-1 font-sans text-sm leading-5 text-neutral-800">
                { hasCustomQuote
                  ? t('forms.checkout.acknowledgeEstimateCustomQuote')
                  : t('forms.checkout.acknowledgeEstimate') }
              </div>
            )}
          />
        </div>

        { /* <div className="my-2 rounded-md border bg-neutral-100 px-4 py-2">
          <SingleCheckbox
            name="termsAccepted"
            label={(
              <div className="py-2 pl-1 font-sans text-sm leading-5 text-neutral-800">
                <Trans i18nKey="forms.checkout.acceptTerms">
                  <TermsLink onClick={() => setTacOpen(true)} />
                </Trans>
              </div>
            )}
          />
        </div> */ }

        <div className="mb-6 mt-8 flex w-full justify-end sm:w-auto xl:mb-0 xl:mt-6">
          <SubmitButton className="w-full sm:w-auto">
            { t('actions.bookMyMove') }
          </SubmitButton>
        </div>
      </Form>
    </>
  )
}

export default CheckoutForm
