import { useEffect } from 'react'
import { replaceColor } from 'lottie-colorify'

import furnitureAnimation from '../../../../assets/lotties/furniture.json'
import { usePrimaryColor } from '../../../../modules/theme'
import { useAnimatedSvg } from '../../../../utils/animatedSvg'
import { FormCarouselSlide, useFormCarouselContext } from '../../../../components/FormCarousel'
import MoveSizeForm from '../../../../components/forms/quotesRequest/MoveSizeForm'

const MoveSizeStep: React.FC = () => {
  const { handleSubmit, isCurrentSlide } = useFormCarouselContext()
  const primaryColor = usePrimaryColor()

  const [furniture, animation] = useAnimatedSvg(
    replaceColor([250, 55, 44], primaryColor.hex(), furnitureAnimation),
    {
      autoplay: false,
      loop: false,
      className: 'w-[120px] lg:w-[180px] xl:w-[220px]',
    },
  )

  /**
   * play the animation with a delay every time the step is active
   */
  useEffect(() => {
    if (!animation || !isCurrentSlide) {
      return
    }
    animation.setSpeed(1)
    animation.goToAndStop(0)

    const timeout = setTimeout(() => {
      animation.goToAndPlay(0)
    }, 350)

    return () => { clearInterval(timeout) }
  }, [animation, isCurrentSlide])

  return (
    <FormCarouselSlide>
      <div className="flex w-full flex-col-reverse items-center justify-around gap-12 lg:flex-row xl:gap-16">

        <div className="w-full">
          <MoveSizeForm
            onSubmit={handleSubmit}
          />
        </div>

        <div>
          { furniture }
        </div>
      </div>
    </FormCarouselSlide>
  )
}

export default MoveSizeStep
