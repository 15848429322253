import CfSelect, { type SelectProps as CFSelectProps } from '@concrete-form/mui/Select'

import { type InputProps } from '../Input'
import { getSelectProps } from './getSelectProps'

export type SelectProps = Pick<CFSelectProps, 'options' | 'allowEmpty' | 'disabled'> & InputProps

const Select: React.FC<SelectProps> = ({
  name,
  ...selectProps
}) => {
  return (
    <CfSelect
      name={name}
      variant="outlined"
      {...getSelectProps(selectProps)}
    />
  )
}

export default Select
