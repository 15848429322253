import { useFormContext, useWatch } from 'react-hook-form'
import { StaticDatePicker, type StaticDatePickerProps } from '@mui/x-date-pickers/StaticDatePicker'

import Alert from '../../../Alert'

type CalendarInputProps = {
  name: string
  wrapperClassName?: string
} & StaticDatePickerProps<Date>

const CalendarInput: React.FC<CalendarInputProps> = ({
  name,
  minDate,
  maxDate,
  wrapperClassName,
  ...calendarProps
}) => {
  const { setValue, formState: { errors } } = useFormContext()
  const formErrors = errors[name]

  const value = useWatch({ name })

  const now = new Date()
  const disableHighlightToday = !!(minDate && minDate > now) || !!(maxDate && maxDate < now)
  const onChange = (date: Date | null) => {
    setValue(name, date, { shouldValidate: true })
  }

  return (
    <div>
      <div className={wrapperClassName}>
        <StaticDatePicker
          minDate={minDate}
          maxDate={maxDate}
          disableHighlightToday={disableHighlightToday}
          slots={{
            actionBar: () => null,
            toolbar: () => null,
          }}
          onChange={onChange}
          value={value ?? null}
          {...calendarProps}
        />
      </div>

      { typeof formErrors?.message === 'string' && (
        <div className="mb-4 mt-2 lg:mb-0 lg:mt-4">
          <Alert>
            { formErrors.message }
          </Alert>
        </div>
      ) }
    </div>
  )
}

export default CalendarInput
