import { useConfig } from '../../config'
import { type Platform } from './whiteLabel.types'
import { getPlatformFromHostname } from './whiteLabel.utils'

/**
 * get a platform by name
 */
export const useGetPlatform = () => {
  const platforms = useConfig<Record<string, Platform>>('platforms')
  return (platform?: string) => {
    if (!platform || !platforms?.[platform]) {
      return
    }
    return {
      ...platforms[platform],
      name: platform,
    }
  }
}

/**
 * returns the current platform (whitelabel) based on hostname
 */
export const usePlatform = () => {
  const getPlatform = useGetPlatform()
  const hostname = window?.location?.hostname ?? ''
  return getPlatform(getPlatformFromHostname(hostname))
}
