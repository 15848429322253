import * as Yup from 'yup'
import { useTranslation } from 'react-i18next'

import { type DateTimeFormValues } from './DateTimeForm.type'

const useValidationSchema = () => {
  const { t } = useTranslation()
  return Yup.object<DateTimeFormValues>().shape({
    movingDate: Yup.date().required(t('formValidation.required')),
    flexibleDate: Yup.boolean(),
    preferredTime: Yup.array().min(1, t('formValidation.required')),
  })
}

export default useValidationSchema
