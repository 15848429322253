import { useMemo } from 'react'
import { frCA as dateFnsFr } from 'date-fns/locale/fr-CA'
import { enCA as dateFnsEn } from 'date-fns/locale/en-CA'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3'
import { enUS as muiEn, frFR as muiFr } from '@mui/x-date-pickers/locales'

import { useLanguage } from '../modules/i18n'
import Language from './Language.enum'

type MuiDatePickerProviderProps = {
  children?: React.ReactNode
}

const MuiDatePickerProvider: React.FC<MuiDatePickerProviderProps> = ({ children }) => {
  const language = useLanguage()
  const muiDatePickerLocale = useMemo(() => {
    switch (language) {
      case Language.Fr:
        return muiFr
      case Language.En:
      default:
        return muiEn
    }
  }, [language])

  const dateFnsLocale = useMemo(() => {
    switch (language) {
      case Language.Fr:
        return dateFnsFr
      case Language.En:
      default:
        return dateFnsEn
    }
  }, [language])

  return (
    <LocalizationProvider
      dateAdapter={AdapterDateFns}
      adapterLocale={dateFnsLocale}
      localeText={muiDatePickerLocale.components.MuiLocalizationProvider.defaultProps.localeText}
    >
      { children }
    </LocalizationProvider>
  )
}

export default MuiDatePickerProvider
