import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

import Form, { Input, PhoneNumberField, SubmitButton } from '../../Form'
import useValidationSchema from './validationSchema'
import { type RegisterFormValues } from './RegisterForm.type'

type RegisterFormProps = {
  onSubmit: (data: RegisterFormValues) => Promise<void> | void
  whiteQuotes?: boolean
}

const RegisterForm: React.FC<RegisterFormProps> = ({
  onSubmit,
  whiteQuotes = true,
}) => {
  const { t } = useTranslation()
  const schema = useValidationSchema()
  const form = useForm({
    resolver: yupResolver(schema),
  })

  return (
    <Form form={form} onSubmit={onSubmit}>
      <div className="my-6 flex flex-col gap-4">
        <div className="flex flex-col gap-4 md:flex-row">
          <Input
            name="firstName"
            placeholder={t('forms.fields.firstName.label')}
            center
          />
          <Input
            name="lastName"
            placeholder={t('forms.fields.lastName.label')}
            center
          />
        </div>
        <div className="flex flex-col gap-4 md:flex-row">
          <Input
            name="email"
            placeholder={t('forms.fields.email.label')}
            center
          />
          <PhoneNumberField
            name="phoneNumber"
            placeholder={t('forms.fields.phoneNumber.label')}
            center
            showExtension={false}
          />
        </div>
      </div>

      <div className="mt-6 flex justify-center">
        <SubmitButton>
          { whiteQuotes ? t('actions.seeAllQuotes') : t('actions.next') }
        </SubmitButton>
      </div>
    </Form>
  )
}

export default RegisterForm
