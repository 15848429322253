import { mergeClassName } from '../../utils/mergeClassName'

type ReactDiv = React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>

const FormCarouselSlide: React.FC<ReactDiv> = ({
  children,
  className,
  ...props
}) => {
  return (
    <div className="flex w-full items-center px-2 lg:px-8">
      <div
        className={mergeClassName(
          'flex w-full rounded-3xl bg-white px-4 pb-10 pt-4 md:px-8 lg:px-16 lg:py-12',
          className,
        )}
        {...props}
      >
        { children }
      </div>
    </div>
  )
}

export default FormCarouselSlide
