import { useTranslation } from 'react-i18next'

import Route from '../../../app/Route.enum'
import Container from '../../Container/Container'
import Link from '../../Link'

const Footer: React.FC = () => {
  const { t } = useTranslation()

  return (
    <div className="flex w-full items-center border-t bg-white py-2 text-neutral-400 lg:sticky lg:bottom-0">
      <Container>
        <div className="flex justify-between">
          <span>{ t('footer.copyright', {
            currentYear: new Date().getFullYear(),
          }) }
          </span>

          <div className="flex justify-center gap-4">
            <Link to={Route.TermsOfUse} variant="underlined">{ t('layout.termsOfUse') }</Link>
            <Link to={Route.PrivacyPolicy} variant="underlined">{ t('layout.privacyPolicy') }</Link>
          </div>
        </div>
      </Container>
    </div>
  )
}

export default Footer
