import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useFormContext, useWatch } from 'react-hook-form'
import usePlacesAutocomplete, { getDetails } from 'use-places-autocomplete'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import MarkerIcon from '@mui/icons-material/RoomOutlined'
import WarningIcon from '@mui/icons-material/WarningAmber'

import { useLanguage } from '../../../../modules/i18n'
import { useCountries } from '../../../../modules/localization'
import { getMuiTextfieldProps } from '../../controls/Input'
import PlaceLine from './PlaceLine'
import { getAddressDetails } from './util'
import { type Address } from '../../../../common/address'

const GOOGLE_MAP_API_DEBOUNCE = 300

type AddressAutoCompleteControlProps = {
  name: string
  onSelect?: (place: Partial<Address>) => void
}

const AddressAutoCompleteControl: React.FC<AddressAutoCompleteControlProps> = ({
  name,
  onSelect,
}) => {
  const { t } = useTranslation()
  const inputRef = useRef<HTMLDivElement>(null)
  const language = useLanguage() ?? 'en'
  const countries = Object.keys(useCountries())
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const form = useFormContext()
  const initialInputValue = useWatch({ name })

  const {
    suggestions: { data: places, status },
    setValue: setPlaceValue,
    value: placeValue,
  } = usePlacesAutocomplete({
    cacheKey: `language-${language}`,
    requestOptions: {
      language,
      componentRestrictions: {
        country: countries,
      },
    },
    debounce: GOOGLE_MAP_API_DEBOUNCE,
  })

  useEffect(() => {
    if (initialInputValue) {
      setPlaceValue(initialInputValue)
      // setOpen(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialInputValue])

  const noResults = status === 'ZERO_RESULTS'

  const onInputChange = (_: unknown, newValue: string, reason: string) => {
    if (reason !== 'input') {
      return
    }
    setPlaceValue(newValue)
    form.setValue(name, newValue, { shouldValidate: true })
  }

  const onPlaceSelect = (selectedPlace: any) => {
    setOpen(false)

    const placeId = selectedPlace?.place_id

    if (!placeId) {
      return
    }

    setLoading(true)
    getDetails({ placeId, language } as any) // missing optionnal "language" parameters in "usePlacesAutocomplete"
      .then(details => {
        const components = typeof details !== 'string' && details.address_components
        /* istanbul ignore if ; not testing invalid api response  */
        if (!components) {
          return
        }
        const address = getAddressDetails(components)
        /* istanbul ignore if ; not testing invalid api response  */
        if (!address.street) {
          return
        }

        setPlaceValue(address.street)
        form.setValue(name, address.street, { shouldValidate: true })

        onSelect?.(address)
      })
      .catch(console.error)
      .finally(() => {
        setLoading(false)
      })
  }

  return (
    <>
      <Autocomplete
        open={open}
        onOpen={() => { setOpen(true) }}
        onClose={() => { setOpen(false) }}
        freeSolo
        options={places}
        onInputChange={onInputChange}
        defaultValue={initialInputValue}
        value={placeValue ?? ''}
        disabled={loading}
        filterSelectedOptions
        getOptionLabel={(data: any) => data?.description ?? placeValue}
        renderInput={params => {
          const textFieldProps = getMuiTextfieldProps({
            placeholder: t('forms.fields.streetAddress.label'),
          })

          return (
            <TextField
              ref={inputRef}
              {...textFieldProps}
              {...params}
              InputProps={{
                ...params.InputProps,
                ...textFieldProps.InputProps,
              }}
              size="small"
            />
          )
        }}
        fullWidth
        clearOnBlur={false}
        renderOption={(props, data) => (
          <PlaceLine
            {...props}
            onClick={() => { onPlaceSelect(data) }}
          >
            <MarkerIcon color="primary" sx={{ mr: 1 }} /> { data?.description }
          </PlaceLine>
        )}
        filterOptions={() => places}
        noOptionsText={noResults
          ? (
            <div
              className="flex cursor-pointer items-center"
            >
              <WarningIcon color="warning" className="mr-1 !text-[18px]" />
              { t('components.formFields.PlaceField.addressNotFound') }
            </div>
            )
          : undefined}
        popupIcon={null}
      />
      { open && !noResults && (
        <style type="text/css">
          { `
          .MuiAutocomplete-noOptions{
            display: none;
          }
        ` }
        </style>
      ) }
    </>
  )
}

export default AddressAutoCompleteControl
