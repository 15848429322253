import { useTranslation } from 'react-i18next'

import { useLanguage } from '../../modules/i18n'
import EnglishContent from './TermsOfUse.en'
import FrenchContent from './TermsOfUse.fr'
import PageTitle from '../../components/layout/PageTitle/PageTitle'
import FullWidthPage from '../../components/layout/FullWidthPage'
import Container from '../../components/Container/Container'

const TermsOfUsePage: React.FC = () => {
  const { t } = useTranslation()
  const language = useLanguage()

  return (
    <FullWidthPage>
      <PageTitle>{ t('pages.termsOfUse.title') }</PageTitle>
      <Container>
        <div className="prose mb-8 max-w-none font-body prose-headings:font-body2">
          { language === 'fr' ? <FrenchContent /> : <EnglishContent /> }
        </div>
      </Container>
    </FullWidthPage>
  )
}

export default TermsOfUsePage
