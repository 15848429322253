import { mergeClassName } from '../../../utils/mergeClassName'
import Container from '../../Container/Container'

type PageTitleProps = {
  children?: React.ReactNode
  className?: string
}

const PageTitle: React.FC<PageTitleProps> = ({
  children,
  className,
}) => {
  return (
    <div className={mergeClassName(
      'border-b border-zinc-200 bg-stone-50 py-8 lg:py-12 w-full mb-8',
      className,
    )}
    >
      <Container>
        <h1 className="text-center font-body2 text-4xl lg:text-left">
          { children }
        </h1>
      </Container>
    </div>
  )
}

export default PageTitle
