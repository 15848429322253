import { gql } from '@apollo/client'

import { quoteFields } from '../../quotes/core/quotes.queries'
import { promoCodeFields } from '../../promoCodes/core/promoCodes.queries'

export const moveUserFields = `
firstName
lastName
email
phoneNumber {
  number
}`

export const placeFields = `
address {
  placeId
  placeTextLine1
  placeTextLine2
  country
  street
  apartment
  city
  region
  postalCode
}
nbStairs
elevatorReservationRequired
`

export const moveDetailsFields = `
id
locked
platform
quoteAccepted {
  date
}
deposit {
  id
  createdAt
  amount {
    price
    currency
  }
  isDeposit
  status
}
paymentProfile {
  depositRequest {
    gateway
    gatewayId
    createdAt
    clientSecret
    amount {
      price
      currency
    }
  }
}
origin {
  ${placeFields}
}
destination {
  ${placeFields}
}
movingDate
flexibleDate
preferredTime
residenceType
residenceRooms {
  key
  value
}
residenceRoomsPrefilled
specialItemsPrefilled
nbBoxes
furnitureRatio
volumeCubicFeet
weightPounds
estimatedMovingLabourTime
estimatedPackingLabourTime
nbMen
nbTrucks {
  key
  value
}
specialServices {
  key
  value
}
specialItems {
  key
  value
}
protectiveMaterial {
  key
  value
}
overnightFees
moveLeg {
  origin {
    type
    coordinates
  }
  destination {
    type
    coordinates
  }
  distanceMeters
  durationSeconds
  durationInTrafficSeconds
}
originTimezone
timeline {
  originArrivalTime
  finishPackingTime
  finishLoadingTruckTime
  originDepartureTime
  destinationArrivalTime
  finishUnloadingTruckTime
  destinationDepartureTime
}
moveDurationSeconds
requiredMovingService
calendlyScheduledEventUri
selectedBranch
promoCode {
  ${promoCodeFields}
}
`

export const moveFields = `
${moveDetailsFields}
user {
  ${moveUserFields}
}
quotes {
  ${quoteFields}
}
`

export const getMove = gql`
query GetMove($moveId: String!) {
  getMove(moveId: $moveId) {
    ${moveFields}
  }
}
`
