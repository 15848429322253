import { Trans, useTranslation } from 'react-i18next'
import CreditCardIcon from '@mui/icons-material/CreditCardOutlined'

import { type PaymentRequest } from '../../../../modules/moves'
import { type Amount } from '../../../../common/amount'
import Price from '../../../Price'
import InfoTooltip from '../../../InfoTooltip'

type RequestAmountProps = {
  amount: Amount
}

const RequestAmount: React.FC<RequestAmountProps> = ({ amount }) => {
  return (
    <strong>
      <Price
        amount={amount}
        showDecimals={false}
        compact
      />
    </strong>
  )
}

type MoveDepositFieldProps = {
  paymentRequest: PaymentRequest
  children: React.ReactNode
}

const MoveDepositField: React.FC<MoveDepositFieldProps> = ({
  paymentRequest,
  children,
}) => {
  const { t } = useTranslation()

  return (
    <div className="my-4 flex flex-col justify-between gap-2 rounded-md border bg-white p-4 text-neutral-500 lg:p-8">
      <div className="font-sans text-lg leading-tight text-neutral-700">
        <CreditCardIcon className="!text-[18px] text-neutral-500" />
        { ' ' }

        <Trans
          i18nKey="forms.moveDeposit.title"
        >
          <RequestAmount amount={paymentRequest.amount} />
        </Trans>
        { ' ' }
        <InfoTooltip
          tooltip={t('forms.moveDeposit.tooltip')}
        />
      </div>
      <div className="mb-4 ml-[27px] font-sans text-sm text-neutral-500 ">
        { t('forms.moveDeposit.subtitle') }
      </div>

      { children }
    </div>
  )
}

export default MoveDepositField
