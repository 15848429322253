import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

import { type UpdateMovePayload, useMove } from '../../../../modules/moves'
import Form, { Input, PhoneNumberField } from '../../../Form'
import SubmitBar from '../SubmitBar'
import { Label, Row, Cell, Field } from '../../../data'
import useValidationSchema from './validationSchema'
import { formatFormOutput, moveToFormData } from './formDataBuilder'
import { type IdentificationFormValues } from './IdentificationForm.type'

type IdentificationFormProps = {
  onSubmit: (data: UpdateMovePayload) => Promise<void> | void
  onCancel?: () => void
}

const IdentificationForm: React.FC<IdentificationFormProps> = ({
  onCancel,
  onSubmit,
}) => {
  const { t } = useTranslation()
  const { data: move } = useMove()
  const schema = useValidationSchema()
  const form = useForm({
    resolver: yupResolver(schema),
    defaultValues: moveToFormData(move),
  })

  const handleSubmit = async (data: IdentificationFormValues) => { await onSubmit(formatFormOutput(data)) }

  return (
    <Form
      form={form}
      onSubmit={handleSubmit}
    >
      <Row>
        <Cell>
          <Label>
            { t('components.move.identification.firstName') }
          </Label>
          <Field className="mt-1">
            <Input
              name="firstName"
              placeholder={t('forms.fields.firstName.label')}
            />
          </Field>
        </Cell>

        <Cell>
          <Label>
            { t('components.move.identification.lastName') }
          </Label>
          <Field className="mt-1">
            <Input
              name="lastName"
              placeholder={t('forms.fields.lastName.label')}
            />
          </Field>
        </Cell>
      </Row>

      <Row>
        <Cell>
          <Label>
            { t('components.move.identification.email') }
          </Label>
          <Field className="mt-1">
            <Input
              name="email"
              placeholder={t('forms.fields.email.label')}
            />
          </Field>
        </Cell>

        <Cell>
          <Label>
            { t('components.move.identification.phoneNumber') }
          </Label>
          <Field className="mt-1">
            <PhoneNumberField
              name="phoneNumber"
              placeholder={t('forms.fields.phoneNumber.label')}
              showExtension={false}
            />
          </Field>
        </Cell>
      </Row>

      <SubmitBar onCancel={onCancel} />
    </Form>
  )
}

export default IdentificationForm
