import { type PhoneNumber } from './phoneNumber.type'

/**
 * format phone number
 * +15141234567  =>  (514)123-4567
 */
export const formatPhoneNumber = (phoneNumber?: PhoneNumber) => {
  if (!phoneNumber?.number) {
    return
  }

  const number = phoneNumber.number.substring(2)

  const blocks = [
    number.substring(0, 3),
    number.substring(3, 6),
    number.substring(6, 10),
  ]

  const formattedNumber = `(${blocks[0]}) ${blocks[1]}-${blocks[2]}`
  return phoneNumber.extension
    ? `${formattedNumber} #${phoneNumber.extension}`
    : formattedNumber
}
