import { MoveContent, type MoveSizeEstimate } from '../../../../modules/quotes'
import { type MoveSizeFormOutput, type MoveSizeFormValues } from './MoveSizeForm.type'

export const formatFormOutput = (values: MoveSizeFormValues, moveSizeEstimate: MoveSizeEstimate): MoveSizeFormOutput => {
  const hasBoxes = values.moveContent.includes(MoveContent.Boxes)
  const hasFurniture = values.moveContent.includes(MoveContent.Furniture)
  const hasApplianceAndMore = values.moveContent.includes(MoveContent.ApplianceAndMore)

  return {
    ...values,
    nbBoxes: (hasBoxes && values.nbBoxes)
      ? parseInt(values.nbBoxes)
      : undefined,
    furnitureRatio: (hasFurniture && values.furnitureRatio)
      ? parseInt(values.furnitureRatio)
      : undefined,
    nbAppliancesAndMore: (hasApplianceAndMore && values.nbAppliancesAndMore)
      ? parseInt(values.nbAppliancesAndMore)
      : undefined,
    ...moveSizeEstimate,
  }
}
