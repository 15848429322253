import { mergeClassName } from '../../utils/mergeClassName'

export type ValueProps = React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>

const Value: React.FC<ValueProps> = ({
  className,
  children,
  ...props
}) => {
  return (
    <div
      className={mergeClassName(
        'font-sans text-neutral-500 text-lg',
        className,
      )}
      {...props}
    >
      { children ?? '—' }
    </div>
  )
}

export default Value
