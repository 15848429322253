import { type AddressesFormValues } from './AddressesForm.type'

const removeEmptyStrings = <T,>(object: Record<string, any>): T => {
  return Object.fromEntries(Object.entries(object).filter(([key, value]) => {
    return value !== ''
  })) as T
}

export const formatFormOutput = (values: AddressesFormValues): AddressesFormValues => {
  return {
    ...values,
    origin: {
      ...values.origin,
      address: removeEmptyStrings(values.origin.address),
    },
    destination: {
      ...values.destination,
      address: removeEmptyStrings(values.destination.address),
    },
  }
}
