import { useEffect } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import MinusIcon from '@mui/icons-material/RemoveCircleOutline'
import PlusIcon from '@mui/icons-material/AddCircleOutline'

import Input, { InputSize } from '../Input'

type IntegerInputProps = {
  name: string
  min?: number
  max?: number
}

const IntegerInput: React.FC<IntegerInputProps> = ({
  name,
  min = 0,
  max = Infinity,
}) => {
  const { setValue } = useFormContext()
  const value = useWatch({ name }) ?? 0

  useEffect(() => {
    if (!value) {
      setValue(name, 0, { shouldValidate: true })
    }
    const rangeValue = Math.min(Math.max(parseInt(value), min), max)

    if (value !== rangeValue) {
      setValue(name, rangeValue, { shouldValidate: true })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])

  const handleRemove = () => {
    const newValue = Math.max(parseInt(value) - 1, min)
    setValue(name, newValue, { shouldValidate: true })
  }

  const handleAdd = () => {
    const newValue = Math.min(parseInt(value) + 1, max)
    setValue(name, newValue, { shouldValidate: true })
  }

  return (
    <div className="flex items-center gap-2">
      <Input
        type="number"
        name={name}
        width={110}
        size={InputSize.Small}
        className="-mx-2 [&input]:!text-center"
        startNode={(
          <button type="button" onClick={handleRemove}>
            <MinusIcon className="!text-[18px] text-secondary" />
          </button>
        )}
        endNode={(
          <button type="button" onClick={handleAdd}>
            <PlusIcon className="!text-[18px] text-secondary" />
          </button>
        )}
      />
    </div>
  )
}

export default IntegerInput
