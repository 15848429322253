import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import EditIcon from '@mui/icons-material/Edit'

import { type EditableSectionProps } from './editableSections.type'
import { mergeClassName } from '../../utils/mergeClassName'

type Section = {
  key: string
  title: React.ReactNode
  icon?: React.FC<{ className?: string }>
  component: React.FC<EditableSectionProps>
  disabled?: boolean
}

type EditableSectionsProps = {
  sections?: Section[]
  wide?: boolean
}

const EditableSections: React.FC<EditableSectionsProps> = ({
  sections,
  wide = false,
}) => {
  const { t } = useTranslation()
  const [editingSection, setEditingSection] = useState<string>()

  if (!sections?.length) {
    return null
  }

  return (
    <div className="flex flex-col gap-6">

      { sections.map(({
        key,
        title,
        component: Component,
        icon: Icon,
        disabled = false,
      }) => (
        <div
          key={key}
          className={mergeClassName(
            'px-2 lg:px-0',
            editingSection && editingSection !== key && 'blur-[2px] cursor-default opacity-70 pointer-events-none',
          )}

        >
          <div className="flex flex-col-reverse gap-x-3 gap-y-1 sm:flex-row sm:items-center lg:gap-6">
            <div className="mb-2 flex items-center gap-2 font-body2 text-xl text-neutral-700 sm:mb-0">
              { Icon && <Icon className="text-secondary" /> }
              { title }
            </div>

            { !editingSection && !disabled && (
              <div
                className="mr-2 cursor-pointer whitespace-nowrap font-sans text-sky-700 underline"
                onClick={() => { setEditingSection(key) }}
              >
                <EditIcon className="mr-1 !text-[15px]" />
                { t('actions.edit') }
              </div>
            ) }

          </div>
          <div
            className={mergeClassName(
              'mb-4 mt-2 rounded-md border bg-white px-6 py-3 shadow-lg shadow-neutral-200/30 lg:px-8',
              editingSection && editingSection === key && 'shadow-md shadow-neutral-300',
              !wide && 'lg:px-6',
            )}
          >
            <Component
              isEditing={editingSection === key}
              onClose={() => { setEditingSection(undefined) }}
              wide={wide}
            />
          </div>
        </div>
      )) }

    </div>
  )
}

export default EditableSections
