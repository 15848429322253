import { useState, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import BackIcon from '@mui/icons-material/ChevronLeft'

import Carousel, { type CarouselProps } from '../Carousel'
import ProgressBar from '../ProgressBar'
import Container from '../Container'
import { FormCarouselContextProvider } from './formCarousel.context'

type FormCarouselProps<T=any> = {
  slides: Record<string, React.ReactNode>
  onSubmit: (data: T) => Promise<void> | void
  onSlideChange?: (slide: string) => void
} & Omit<CarouselProps, 'children' | 'currentSlide'>

const FormCarousel = <T=any>({
  slides,
  onSubmit,
  onSlideChange,
  ...carouselProps
}: FormCarouselProps<T>) => {
  const { t } = useTranslation()
  const [currentSlide, setCurrentSlide] = useState(0)
  const [data, setData] = useState<any>()
  const nbSlides = Object.keys(slides).length
  const lastSlideKeyPushed = useRef<string>('')

  let progress = (currentSlide / (nbSlides - 1)) * 100
  progress = Math.max(progress - 10, 5)

  const onSlideSubmit = (slide: string) => async (slideData: any) => {
    const updatedData = {
      ...data,
      [slide]: slideData,
    }
    setData(updatedData)
    if (currentSlide === nbSlides - 1) {
      await onSubmit(updatedData)
    } else {
      next()
    }
  }

  const next = () => {
    if (currentSlide >= (nbSlides - 1)) {
      return
    }
    setCurrentSlide(currentSlide + 1)
  }

  const back = () => {
    if (currentSlide === 0) {
      return
    }
    setCurrentSlide(currentSlide - 1)
  }

  useEffect(() => {
    const slideKey = Object.keys(slides)[currentSlide]
    if (!slideKey || lastSlideKeyPushed.current === slideKey) {
      return
    }
    lastSlideKeyPushed.current = slideKey
    onSlideChange?.(slideKey)
  }, [slides, currentSlide, onSlideChange])

  return (
    <>
      <Container className="my-2 flex justify-center md:my-7 xl:my-24">
        <div className="relative w-full max-w-[1200px] px-1 md:px-10 xl:px-0">
          { currentSlide > 0 && (
            <div className="absolute left-[-5px] top-[45px] flex cursor-pointer items-center font-sans text-sm font-bold text-neutral-600 md:left-[-15px] md:top-[27px] lg:top-[-30px]" onClick={back}>
              <BackIcon />
              { t('actions.back') }
            </div>
          ) }
          <ProgressBar
            nbDots={nbSlides}
            progress={progress}
          />
        </div>
      </Container>

      <Carousel
        currentSlide={currentSlide}
        {...carouselProps}
      >
        { Object.entries(slides).map(([key, slide], index) => (
          <FormCarouselContextProvider
            key={key}
            isCurrentSlide={currentSlide === index}
            handleSubmit={onSlideSubmit(key)}
          >
            { slide }
          </FormCarouselContextProvider>
        )) }
      </Carousel>
    </>
  )
}

export default FormCarousel
