import { mergeClassName } from '../../utils/mergeClassName'

type ValueProps = {
  label?: React.ReactNode
  Icon?: React.FC<{ className: string }>
  className?: string
  inverted?: boolean
  children?: React.ReactNode
}

const Value: React.FC<ValueProps> = ({
  label,
  Icon,
  className,
  inverted,
  children,
}) => {
  return (
    <div className={mergeClassName(
      'sm:basis-auto flex rounded-md border border-zinc-300 bg-neutral-50 sm:shadow-inner px-3 py-2 text-sm text-neutral-900',
      inverted && 'border-none bg-secondary text-white',
      className,
    )}
    >
      <div className="flex items-center gap-1">
        { Icon && (
          <Icon className={mergeClassName(
            '!text-[20px] text-secondary',
            inverted && 'text-neutral-300',
          )}
          />
        ) }
        <div className="flex  flex-col text-left">
          { label && (
            <div className={mergeClassName(
              'font-body2 text-xs leading-3 text-neutral-400',
              inverted && 'text-gray-300',
            )}
            >
              { label }
            </div>
          ) }
          <div className="flex flex-col child:border-none child:p-0 child:!shadow-none sm:flex-row sm:items-center">
            { children }
          </div>
        </div>
      </div>
    </div>
  )
}

export default Value
