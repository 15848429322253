import { removeTypeNameFromObject } from '../../../utils/graphql'
import { type Quote, type QuoteAlternativeDate } from '../../quotes'
import { type Move } from './moves.models'

/**
 * build alternative date for quote entity
 */
const buildQuoteAlternativesDate = (data?: any): QuoteAlternativeDate => ({
  ...data,
  date: new Date(data.date),
})

/**
 * build quote entity
 */
const buildQuote = (data?: any): Quote => ({
  ...data,
  date: new Date(data.date),
  alternativeDates: data.alternativeDates
    ? data.alternativeDates.map(buildQuoteAlternativesDate)
    : undefined,
})

/**
 * build move entity
 */
export const buildMove = (incomingData?: any): Move | undefined => {
  if (!incomingData) {
    return
  }
  const data = removeTypeNameFromObject<Move>(incomingData)

  return {
    ...data,
    movingDate: new Date(data.movingDate),
    quotes: data.quotes ? data.quotes.map(buildQuote) : undefined,
  }
}
