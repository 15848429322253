import { useTranslation } from 'react-i18next'

import { type PreliminaryQuote } from '../../../modules/quotes'
import Price from '../../../components/Price'

type PreliminaryQuotePreviewProps = {
  preliminaryQuote: PreliminaryQuote
}

const PreliminaryQuotePreview: React.FC<PreliminaryQuotePreviewProps> = ({
  preliminaryQuote,
}) => {
  const { t } = useTranslation()

  return (
    <div className="inline-flex items-center rounded-[2rem] bg-stone-100 p-2 shadow-lg">
      <div className="flex size-[100px] items-center justify-center rounded-3xl bg-white p-2">
        { preliminaryQuote.companyLogo
          ? (
            <img src={preliminaryQuote.companyLogo} alt={preliminaryQuote.companyLetter} className="max-h-[100px]" />
            )
          : (
            <span className="text-[64px] text-secondary/80">
              { preliminaryQuote.companyLetter }
            </span>
            ) }
      </div>
      <div className="px-4 text-center">
        <div className="font-body2 text-sm text-neutral-600 md:min-w-[180px]">
          { t('nbMen', { count: preliminaryQuote.nbMen }) }
          { ' + ' }
          { t('nbTrucks', { count: preliminaryQuote.nbTrucks }) }
        </div>
        <div className="font-sans text-4xl font-thin text-neutral-600">
          <Price amount={preliminaryQuote.price} showDecimals={false} />
        </div>
      </div>
    </div>
  )
}

export default PreliminaryQuotePreview
