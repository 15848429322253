import { Heading } from '../typography'

type ModalTitleProps = React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLHeadingElement>

const ModalTitle: React.FC<ModalTitleProps> = ({
  className,
  children,
  ...props
}) => {
  return (
    <Heading
      variant="h2"
      className={className}
      {...props}
    >
      { children }
    </Heading>
  )
}

export default ModalTitle
