import MarkerIcon from '@mui/icons-material/RoomOutlined'

import { formatPlace, type Place as PlaceModel } from '../../common/place'
import { mergeClassName } from '../../utils/mergeClassName'

type ReactDiv = React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>

type PlaceProps = {
  place?: PlaceModel
} & Omit<ReactDiv, 'children'>

const Place: React.FC<PlaceProps> = ({
  place,
  className,
  ...props
}) => {
  const [line1, line2] = formatPlace(place)
  if (!line1) {
    return null
  }

  return (
    <div
      className={mergeClassName(
        'inline-flex items-center gap-4',
        className,
      )}
      {...props}
    >
      <MarkerIcon color="primary" />
      <div className="inline-flex flex-col gap-2 lg:gap-0">
        <span className="font-sans text-xl leading-[15px] text-neutral-700 md:text-2xl md:leading-tight">
          { line1 }
        </span>
        { line2 && (
          <span className="font-sans text-base text-neutral-400">
            { line2 }
          </span>
        ) }
      </div>
    </div>
  )
}

export default Place
