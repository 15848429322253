import { mergeClassName } from '../../../utils/mergeClassName'

type ReactDiv = React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>

const Subtitle: React.FC<ReactDiv> = ({
  children,
  className,
  ...props
}) => {
  return (
    <div
      className={mergeClassName(
        'mt-1 text-center font-sans text-zinc-500 lg:text-left lg:text-xl',
        className,
      )}
      {...props}
    >
      { children }
    </div>
  )
}

export default Subtitle
