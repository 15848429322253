import * as Yup from 'yup'
import { useTranslation } from 'react-i18next'

import { residenceHasRooms } from '../../../../modules/quotes'
import { type MoveContentFormValues } from './MoveContentForm.type'

const useValidationSchema = () => {
  const { t } = useTranslation()

  const validateRooms = (schema: Yup.AnySchema) => {
    return schema.test((value, { createError, parent }) => {
      const hasRoom = residenceHasRooms(parent.residenceType)
      const hasFurniture = (parent.furnitureRatio ?? 0) > 0

      if (hasRoom && hasFurniture) {
        if ((value ?? 0) === 0) {
          return createError({
            message: t('formValidation.required'),
          })
        }
      }
      return true
    })
  }

  const validateNotEmptyMove = (schema: Yup.AnySchema) => {
    return schema.test((value, { createError, parent }) => {
      const nbBoxes = parent.nbBoxes ?? 0
      const furnitureRatio = parent.furnitureRatio ?? 0
      const specialItemsTotal = parent.specialItemsTotal ?? 0

      if (!nbBoxes && !furnitureRatio && !specialItemsTotal) {
        return createError({
          message: 'Move should include boxes, furniture or special items',
        })
      }
      return true
    })
  }

  return Yup.object<MoveContentFormValues>({
    residenceRoomsTotal: validateRooms(Yup.number()),
    nbBoxes: validateNotEmptyMove(Yup.number()),
    furnitureRatio: validateNotEmptyMove(Yup.number()),
  }) as Yup.ObjectSchema<MoveContentFormValues, MoveContentFormValues>
}

export default useValidationSchema
