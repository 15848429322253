import { useTranslation } from 'react-i18next'

import {
  type UpdateMovePayload,
  useMove,
  useUpdateMoveAction,
} from '../../../modules/moves'
import { useNavigate } from '../../../modules/navigation'
import { dateInTimezone, useDateFormatter } from '../../../utils/date'
import { type EditableSectionProps } from '../../EditableSections/editableSections.type'
import {
  Label,
  Value,
  Values,
  Row,
  Cell,
} from '../../data'
import DateTimeForm from '../../forms/editmove/DateTimeForm'
import { sortByNumericValues } from '../../../utils/sort'
import Route from '../../../app/Route.enum'
import { useErrorFormatter } from '../../errors/useErrorFormatter'
import { errorNotification, successNotification } from '../../ToastNotifications'

const DateTimeSection: React.FC<EditableSectionProps> = ({
  isEditing,
  onClose,
}) => {
  const { t } = useTranslation()
  const { data: move } = useMove()
  const formatDate = useDateFormatter()
  const { updateMove } = useUpdateMoveAction()
  const preferredTime = sortByNumericValues(move.preferredTime ?? [])
  const { navigate } = useNavigate()
  const formatError = useErrorFormatter()

  const onSubmit = async (data: UpdateMovePayload) => {
    try {
      await updateMove(data)
      onClose()
      navigate(Route.Move)
      successNotification((
        <>
          { t('components.move.dateTime.success') }
          <div className="font-bold">
            { t('components.move.quoteRecalculated') }
          </div>
        </>
      ))
    } catch (error) {
      errorNotification(formatError(error))
    }
  }

  if (isEditing) {
    return (
      <DateTimeForm
        onSubmit={onSubmit}
        onCancel={onClose}
      />
    )
  }

  return (
    <Row>
      <Cell>
        <Label>
          { t('components.move.dateTime.movingDate') }
        </Label>
        <Value>{ formatDate(dateInTimezone(move.movingDate, move.originTimezone)) }</Value>
      </Cell>

      <Cell>
        <Label>
          { t('components.move.dateTime.preferredTime') }
        </Label>
        <Values>{ preferredTime?.map(preferredTime => t(`arrivalWindows.${preferredTime}.title`)) }</Values>
      </Cell>
    </Row>
  )
}

export default DateTimeSection
