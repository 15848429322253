import { type ButtonProps as MuiButtonProps } from '@mui/material/Button'

import {
  ButtonColor,
  type ButtonProps,
  ButtonSize,
  ButtonVariant,
} from './Button'
import { mergeClassName } from '../../utils/mergeClassName'

export const getMuiButtonProps = ({
  variant = ButtonVariant.Filled,
  color = ButtonColor.Primary,
  size = ButtonSize.Medium,
  contentClassName,
  className,
  ...restMuiProps
}: ButtonProps): MuiButtonProps => {
  const muiProps: Partial<MuiButtonProps> = restMuiProps

  const muiVariant = {
    [ButtonVariant.Filled]: 'contained',
    [ButtonVariant.Outlined]: 'outlined',
    [ButtonVariant.Transparent]: '',
  }[variant]
  muiProps.variant = muiVariant as any

  const muiColor = {
    [ButtonColor.Primary]: 'primary',
    [ButtonColor.Secondary]: 'secondary',
  }[color]
  muiProps.color = muiColor as any

  const muiSize = {
    [ButtonSize.Small]: 'small',
    [ButtonSize.Medium]: 'medium',
    [ButtonSize.Large]: 'large',
  }[size]
  muiProps.size = muiSize as any

  /* custom className */
  muiProps.className = mergeClassName(
    color === ButtonColor.Primary && '!bg-primary',
    color === ButtonColor.Secondary && '!bg-secondary !border-secondary',
    variant === ButtonVariant.Outlined && '!bg-transparent',
    variant === ButtonVariant.Outlined && color === ButtonColor.Secondary && '!text-secondary',
    'disabled:!bg-neutral-200',
    variant === ButtonVariant.Transparent && '!bg-transparent',
    className,
  )

  /* custom content wrapper */
  muiProps.children = (
    <div className={mergeClassName(
      'text-md p-1 font-sans normal-case',
      contentClassName,
    )}
    >
      { muiProps.children }
    </div>
  )

  return muiProps
}
