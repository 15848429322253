import Link, { LinkVariant } from '../../components/Link'

const EnglishContent: React.FC = () => (
  <>
    <h2>Introduction</h2>
    <p>The mission of MovingWaldo Inc (“we”, “us”, “MovingWaldo”, “movingwaldo.com”) is to develop technology that makes it easier for people moving. We hope that this service will help users  (“the user”, “you”, “your”) save time, save money and leave nothing behind when moving. We believe that MovingWaldo Inc. offers a win-win solution to users and suppliers by streamlining the administration of services related to moving. We understand that in order to offer such a service, we need to gain the trust of everyone involved. To do so, we took the time to explicitly describe how our service works, why your information is safe with us and finally, to whom and under which circumstance we will share your information.</p>

    <h2>Purpose and scope of this policy</h2>
    <p>The purpose of the policy is to describe the context in which we collect, use, and share your personal information. If you disagree with this privacy policy, you shall not use the services offered by MovingWaldo Inc. Without any notice, we can review and modify this privacy policy by updating this page. This privacy policy was last updated at the date included at the bottom of this page.</p>

    <h2>Services</h2>
    <p>MovingWaldo offers multiple services to help users with their moving needs. These services are rendered to users through different platforms and subdomains owned by MovingWaldo Inc, or through our customer service and booking agents. The current privacy policy applies to the services described below. For more information on other services, please refer to <Link variant={LinkVariant.Primary} target="_blank" to="https://www.movingwaldo.com/privacy-policy">https://www.movingwaldo.com/privacy-policy</Link>. </p>

    <h2>Moving Services Booking Platform</h2>
    <p>MovingWaldo's main service is a booking platform for moving services. Users can visit MovingWaldo and obtain moving quotes from multiple vendors, either online at https://booking.movingwaldo.com or through a booking agent. To benefit from this, users are required to enter their contact information such as their name, email and phone number. In addition, they will be required to enter information about their move, including but not limited to their address, the address where they are moving their belongings, the inventory of the items to move, the moving date, information about the size and access to their home, etc. Once they do, they will be presented with moving quotes from selected moving companies, and they will have the option to accept one of the moving quotes to book moving services. MovingWaldo may collect a deposit to confirm the reservation, and may also process the payment of the full service as will be explained to the user during the purchase process. </p>

    <h2>Information we share</h2>
    <p>We do not share your personal information (such as your name, old or new address, move date, etc.) with companies, organizations or individuals outside of MovingWaldo Inc. unless one of the following circumstances applies:</p>
    <ol>
      <li>To implement
        <p>In order to implement the service that we offer at www.movingwaldo.com or any of its subdomain, through our customer service or booking agents, or via other websites owned by MovingWaldo inc, we may need to share your personal information with third parties. In such situations, we may share only such personal information as is necessary to complete the ordered service. For example, if you instruct us to send a change of address notification to a certain organization, we share your applicable information with the selected organization so that they may process your requested address change. The same logistic applies if you instruct us to share a service request to one or more of our suppliers, or if you require us to book moving services for you. </p>
      </li>
      <li>For legal
        <p>We will share your personal information with companies, organizations or individuals outside of MovingWaldo Inc. if we believe doing so is necessary to: Meet any applicable law, regulation, legal process or enforceable governmental request. Detect, prevent, or otherwise address fraud, security or technical issues.</p>
      </li>
      <li>For aggregate
        <p>We may share aggregated, non-personally identifiable information publicly and with our partners (“Aggregate Data”). For example, we may share information publicly to show moving trends and other information about new movers. Data of all Users (as defined below) may be included.</p>
      </li>
    </ol>

    <h2>Information we collect from users</h2>
    <p>The information that we collect from “Users” varies based on the service the user is requesting, and includes, at a minimum, their email address. Additional information we collect may include, moving date, old and new address, information about their home, moving inventory, etc. We will also collect information regarding the service provider that a user will request MovingWaldo Inc. to notify of a moving quote or booking request. All information collected aside from standard browsing “visitor” data (“visitors” in this case includes “users”), including but not restricted to, location of visitors, time of the visit and time spend on website, computer IP Address, etc., is deliberately given by the user himself in order to receive a service that requires such information. </p>

    <h2>General Data Protection Regulation (GDPR)</h2>
    <p>We are a Data Controller of your information.</p>

    <p>MovingWaldo inc. legal basis for collecting and using the personal information described in this Privacy Policy depends on the Personal Information we collect and the specific context in which we collect the information:</p>
    <ul>
      <li>MovingWaldo inc. needs to perform a contract with you.</li>
      <li>You have given MovingWaldo inc. permission to do so.</li>
      <li>Processing your personal information is in MovingWaldo inc. legitimate interests.</li>
      <li>MovingWaldo inc. needs to comply with the law.</li>
    </ul>

    <p>MovingWaldo inc. will retain your personal information only for as long as is necessary for the purposes set out in this Privacy Policy. We will retain and use your information to the extent necessary to comply with our legal obligations, resolve disputes, and enforce our policies.</p>

    <p>If you are a resident of the European Economic Area (EEA), you have certain data protection rights. If you wish to be informed what Personal Information we hold about you and if you want it to be removed from our systems, please contact us.</p>

    <p>In certain circumstances, you have the following data protection rights:</p>
    <ul>
      <li>The right to access, update or to delete the information we have on you.</li>
      <li>The right of rectification.</li>
      <li>The right to object.</li>
      <li>The right of restriction.</li>
      <li>The right to data portability</li>
      <li>The right to withdraw consent</li>
    </ul>

    <h2>Log Files</h2>

    <p>MovingWaldo follows a standard procedure of using log files. These files log visitors when they visit websites. All hosting companies do this as part of hosting services' analytics. The information collected by log files include internet protocol (IP) addresses, browser type, Internet Service Provider (ISP), date and time stamp, referring/exit pages, and possibly the number of clicks. These are not linked to any information that is personally identifiable. The purpose of the information is for analyzing trends, administering the site, tracking users' movement on the website, and gathering demographic information.</p>

    <h2>Cookies and Web Beacons</h2>

    <p>Cookies are small text files which embed themselves on your hard drive from some of the Site's pages. Cookies are harmless in the sense that they cannot extract any information from your hard drive, over which you have complete control. We, and our network of advertisers, may use cookies to determine site traffic, convey useful information about your interests, and to personalize any future visits you make to the Site or optimize your advertising experience. Cookies do not systematically provide us with any personal information about you, and you can opt to stop receiving cookies at any time by simply modifying your browser's configuration. We also use Web beacons which are small strings of code that are placed in a website page or in an e-mail message. They are sometimes called “clear GIFs” (Graphics Interchange Format) or “pixel tags.” When you visit a particular page, web beacons notify us of your visit. Some of our advertising partners and other third parties may also install cookies or web beacons when:</p>
    <ul>
      <li>You click on their advertising messages.</li>
      <li>You click on links to their websites.</li>
      <li>You click on services offered on our websites.</li>
    </ul>

    <p>The installation of these cookies or web beacons by our partners and other third parties is done for advertising purposes. This Privacy Policy does not apply to third-party websites or third party advertising providers.</p>

    <p>Since web beacons are used in combination with cookies, if you disable cookies the web beacons will only detect an anonymous site visit. When used in an e-mail, web beacons enable us to know whether you have received the e-mail.</p>

    <h2>Third-party Websites</h2>
    <p>Our website contains links to other websites which are owned and operated by third parties. These links will let you leave the website. The linked sites are not under our control and we are not responsible for the contents of any linked site or any link contained in a linked site, or any changes or updates to such sites. We are not responsible for webcasting or any other form of transmission received from any linked site. We provide links to you only as a convenience. We do not endorse the site or its use or contents.</p>

    <p>We encourage you to review and understand the terms and conditions and privacy policies published on these websites in order to make sure that you understand their view and practices regarding the treatment of your Personal Information. We accept no responsibility or liability in respect of these third-party services.</p>

    <h2>Children's Information</h2>

    <p>Another part of our priority is adding protection for children while using the internet. We encourage parents and guardians to observe, participate in, and/or monitor and guide their online activity.</p>

    <p>MovingWaldo inc.'s booking platform does not knowingly collect any Personal Identifiable Information from children under the age of 13. If you think that your child provided this kind of information on our website, we strongly encourage you to contact us immediately and we will do our best efforts to promptly remove such information from our records.</p>

    <h2>Online Privacy Policy Only</h2>

    <p>Our Privacy Policy applies only to our online activities and is valid for visitors to our website with regards to the information that they shared and/or collect in MovingWaldo inc.'s booking platform. This policy is not applicable to any information collected offline or via channels other than this website.</p>

    <h2>Consent</h2>

    <p>By using our website, you hereby consent to our Privacy Policy and agree to its terms.</p>
  </>
)

export default EnglishContent
