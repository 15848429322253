import * as Yup from 'yup'

import { usePlaceValidator } from '../../../Form/fields/PlaceField'
import { type AddressesFormValues } from './AddressesForm.type'

const useValidationSchema = () => {
  const placeValidator = usePlaceValidator()

  return Yup.object<AddressesFormValues>().shape({
    origin: Yup.object({
      address: placeValidator,
      nbStairs: Yup.number().min(0).max(9),
    }),
    destination: Yup.object({
      address: placeValidator,
      nbStairs: Yup.number().min(0).max(9),
    }),
  })
}

export default useValidationSchema
