import { Fragment } from 'react'

import Value from './Value'

type MultiValuesProps = {
  Icon?: React.FC<{ className: string }>
  separator?: React.ReactNode
  className?: string
  inverted?: boolean
  children?: React.ReactNode[]
}

const MultiValues: React.FC<MultiValuesProps> = ({
  Icon,
  separator,
  className,
  inverted,
  children,
}) => {
  const childrenLength = children?.length ?? 0
  return (
    <Value
      Icon={Icon}
      inverted={inverted}
      className={className}
    >
      { children?.map((children, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <Fragment key={index}>
          { children }
          { separator && index !== (childrenLength - 1) && (
            <div className="mx-2 hidden sm:block">
              { separator }
            </div>
          ) }
        </Fragment>
      )) }

    </Value>
  )
}

export default MultiValues
