import {
  type Actions,
  ACTION_TYPES,
} from './consent.actions'
import { ConsentCategory } from './consent.types'

export const key = 'consent'

const initialState = {
  accepted: [
    ConsentCategory.Necessary,
  ] as ConsentCategory[],
}

export const reducer = (state = initialState, action: Actions) => {
  switch (action.type) {
    case ACTION_TYPES.SET_ACCEPTED: {
      return {
        ...state,
        accepted: action.accepted,
      }
    }
    default:
      return state
  }
}
