import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import usePlacesAutocomplete from 'use-places-autocomplete'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import MarkerIcon from '@mui/icons-material/RoomOutlined'
import WarningIcon from '@mui/icons-material/WarningAmber'

import { useLanguage } from '../../../../modules/i18n'
import { useCountries } from '../../../../modules/localization'
import { getMuiTextfieldProps } from '../../controls/Input'
import { type GoogleMapsPlace, type Place } from '../../../../common/place'
import PlaceLine from './PlaceLine'

const GOOGLE_MAP_API_DEBOUNCE = 300

type PlaceAutoCompleteControlProps = {
  onSelect?: (place: GoogleMapsPlace) => void
  onClear?: () => void
  onCustomAddressClick?: () => void
  editing?: boolean
  hasError?: boolean
  initialValue?: Place
}

const PlaceAutoCompleteControl: React.FC<PlaceAutoCompleteControlProps> = ({
  onSelect,
  onClear,
  onCustomAddressClick,
  editing = false,
  initialValue,
}) => {
  const { t } = useTranslation()
  const inputRef = useRef<HTMLDivElement>(null)
  const language = useLanguage() ?? 'en'
  const countries = Object.keys(useCountries())
  const [open, setOpen] = useState(false)

  const initialInputValue = initialValue && 'placeTextLine1' in initialValue ? [initialValue.placeTextLine1, initialValue.placeTextLine2].join(', ') : undefined

  useEffect(() => {
    if (editing) {
      inputRef.current?.click()
    }
  }, [editing])

  const {
    suggestions: { data: places, status },
    setValue: setPlaceValue,
    value: placeValue,
  } = usePlacesAutocomplete({
    cacheKey: `language-${language}`,
    requestOptions: {
      language,
      componentRestrictions: {
        country: countries,
      },
    },
    debounce: GOOGLE_MAP_API_DEBOUNCE,
  })

  useEffect(() => {
    if (initialInputValue) {
      setPlaceValue(initialInputValue)
      setOpen(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialInputValue])

  const noResults = status === 'ZERO_RESULTS'

  const onInputChange = (_: unknown, newValue: string, reason: string) => {
    if (reason !== 'input') {
      return
    }
    setPlaceValue(newValue)
  }

  const onPlaceSelect = (event: React.SyntheticEvent<Element, Event>, selectedPlace: any) => {
    if (!selectedPlace) {
      onClear?.()
      return
    }

    const placeId = selectedPlace?.place_id
    const placeTextLine1 = selectedPlace?.structured_formatting?.main_text
    const placeTextLine2 = selectedPlace?.structured_formatting?.secondary_text

    if (!placeId || !placeTextLine1) {
      return
    }

    onSelect?.({
      placeId,
      placeTextLine1,
      placeTextLine2,
    })
  }

  return (
    <>
      <Autocomplete
        open={open}
        onOpen={() => { setOpen(true) }}
        onClose={() => { setOpen(false) }}
        options={places}
        onInputChange={onInputChange}
        defaultValue={initialInputValue}
        onChange={onPlaceSelect}
        filterSelectedOptions
        getOptionLabel={(data: any) => data?.description ?? placeValue}
        renderInput={params => {
          const textFieldProps = getMuiTextfieldProps({
            placeholder: t('components.formFields.PlaceField.searchAddressPlaceholder'),
            endNode: (
              <div
                className="-mr-7 hidden cursor-pointer text-right text-sm text-neutral-500 underline lg:block"
                onClick={onCustomAddressClick}
              >
                { t('components.formFields.PlaceField.enterManually') }<br />
              </div>
            ),
          })

          return (
            <TextField
              ref={inputRef}
              {...textFieldProps}
              {...params}
              InputProps={{
                ...params.InputProps,
                ...textFieldProps.InputProps,
              }}
            />
          )
        }}
        clearOnBlur={false}
        renderOption={(props, data) => (
          <PlaceLine {...props}>
            <MarkerIcon color="primary" sx={{ mr: 1 }} /> { data?.description }
          </PlaceLine>
        )}
        filterOptions={() => places}
        isOptionEqualToValue={(option, value) => option.value === value.value}
        noOptionsText={noResults && onCustomAddressClick
          ? (
            <div
              className="flex cursor-pointer items-center"
              onClick={onCustomAddressClick}
            >
              <WarningIcon color="warning" className="mr-1 !text-[18px]" />
              { t('components.formFields.PlaceField.addressNotFound') }
              <span className="ml-2 cursor-pointer text-secondary underline">
                { t('components.formFields.PlaceField.enterManually') }
              </span>
            </div>
            )
          : undefined}
        popupIcon={null}
      />
      { open && !noResults && (
        <style type="text/css">
          { `
          .MuiAutocomplete-noOptions{
            display: none;
          }
        ` }
        </style>
      ) }
    </>
  )
}

export default PlaceAutoCompleteControl
