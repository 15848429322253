import clsx from 'clsx'
import { cva, type VariantProps } from 'class-variance-authority'
import { Link as ReactRouterLink } from 'react-router-dom'

import { useLocation, useParams, usePathGenerator } from '../../modules/navigation'

export enum LinkVariant {
  Default = 'default',
  Primary = 'primary',
  Underlined = 'underlined',
}

export const linkVariants = cva(
  'focus:outline-none [&.disabled]:cursor-default [&.disabled]:text-gray-300',
  {
    variants: {
      variant: {
        [LinkVariant.Primary as string]: 'text-primary underline',
        [LinkVariant.Underlined as string]: 'underline',
      },
    },
    defaultVariants: {
      variant: LinkVariant.Default,
    },
  },
)

type ReactLink = React.DetailedHTMLProps<React.AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement>

export type LinkProps = {
  to: string
  reload?: boolean
  params?: Record<string, string>
  disabled?: boolean
} & Omit<ReactLink, 'ref'> & VariantProps<typeof linkVariants>

const Link: React.FC<LinkProps> = ({
  to,
  params = {},
  reload = false,
  children,
  className,
  variant,
  disabled = false,
  ...linkProps
}) => {
  const location = useLocation()
  const existingParams = useParams()
  const generatePath = usePathGenerator()

  if (!existingParams.companyId && !params.companyId) {
    params.companyId = ''
  }
  if (!existingParams.branchId && !params.branchId) {
    params.branchId = ''
  }

  const generatedPath = generatePath(to, params)
  const current = location.pathname === generatedPath

  if (disabled) {
    return (
      <span
        className={clsx(
          linkVariants({ variant, className }),
          current && 'current',
          'disabled',
        )}
      >
        { children }
      </span>
    )
  }

  return (
    <ReactRouterLink
      to={generatedPath}
      className={clsx(
        linkVariants({ variant, className }),
        current && 'current',
      )}
      reloadDocument={reload}
      {...linkProps}
    >
      { children }
    </ReactRouterLink>
  )
}

export default Link
