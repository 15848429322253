import {
  type Quote,
  useQuoteConstraints,
} from '../../../../../modules/quotes'
import QuoteDetails from '../QuoteDetails'
import AlternativeDates from '../AlternativeDates'
import QuoteConstraints from '../QuoteConstraints'

type QuoteContentProps = {
  quote: Quote
}

const QuoteContent: React.FC<QuoteContentProps> = ({
  quote,
}) => {
  const constraints = useQuoteConstraints(quote)

  const stopEventPropagation = (event: React.MouseEvent) => {
    event.stopPropagation()
  }

  return (
    <div
      className="cursor-auto border-t border-dashed border-gray-300 px-3 pt-2 sm:px-6 md:pb-6 lg:px-10"
      onClick={stopEventPropagation}
    >

      <div className="pt-3 lg:hidden">
        { (constraints?.length ?? 0) > 0 && (
          <QuoteConstraints quote={quote} />
        ) }
      </div>

      <div className="flex flex-col-reverse justify-between gap-2 lg:flex-row lg:gap-6">
        <div className="flex flex-col pt-4 ">

          { (constraints?.length ?? 0) > 0 && (
            <div className="hidden lg:block">
              <QuoteConstraints quote={quote} />
            </div>
          ) }

          { quote.alternativeDates?.length && (
            <AlternativeDates
              quote={quote}
              className="mb-5"
            />
          ) }

        </div>

        <div className="lg:w-1/2 lg:min-w-[500px]">
          <QuoteDetails quote={quote} />
        </div>
      </div>
    </div>
  )
}

export default QuoteContent
