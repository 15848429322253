type ReactLi = React.DetailedHTMLProps<React.LiHTMLAttributes<HTMLLIElement>, HTMLLIElement>

type NumberedListItemProps = {
  question: React.ReactNode
} & ReactLi

const NumberedListItem: React.FC<NumberedListItemProps> = ({
  question,
  children,
  ...props
}) => {
  return (
    <li
      {...props}
    >
      <div className="mb-4 flex items-center gap-x-3 md:gap-x-4">
        <div className="-ml-2 font-sans text-3xl font-extralight text-gray-200 md:ml-0 md:text-4xl">
          Q
        </div>
        <div className="pt-2 font-body2 text-xl font-bold leading-6 text-gray-700">
          { question }
        </div>
      </div>
      <div className="flex gap-x-6">
        <div className="ml-6 border-l border-l-primary pl-6 font-sans text-lg leading-6 text-gray-500 md:ml-10">
          { children }
        </div>
      </div>
    </li>
  )
}

export default NumberedListItem
