import { useTranslation } from 'react-i18next'

import {
  type QuoteDetail,
  useQuoteItemValueFormatter,
} from '../../../../../modules/quotes'
import Price from '../../../../../components/Price'

type QuoteRowProps = {
  quoteDetail: QuoteDetail
}

const QuoteRow: React.FC<QuoteRowProps> = ({ quoteDetail }) => {
  const { t } = useTranslation()
  const getItemValue = useQuoteItemValueFormatter()

  const item = getItemValue(quoteDetail.description, quoteDetail.item)

  const description = t(`quotes.pricing.description.${quoteDetail.description}`, { defaultValue: '' })

  return (
    <tr className="border-b border-dashed align-bottom sm:align-middle">
      <td className="w-1/2 sm:w-auto">
        <div className="mb-1 text-sm  text-gray-500 sm:mb-0">
          { description
            ? (
              <>
                { description }
                { item && <> ({ item })</> }
              </>
              )
            : item }
        </div>
      </td>
      <td>
        <div className="hidden whitespace-nowrap text-right text-sm text-gray-400 sm:block">
          { quoteDetail.quantity }
          { quoteDetail.unit && t(`units.${quoteDetail.unit}`) }
        </div>
      </td>
      <td>
        <div className="hidden whitespace-nowrap text-right text-sm text-gray-400 sm:block">
          <Price amount={quoteDetail.unitPrice} compact />
        </div>
      </td>
      <td className="w-1/2 sm:w-auto">
        <div className="text-right text-gray-500">
          <div className="my-1 inline-flex flex-wrap justify-end gap-1 rounded bg-gray-200 px-2 py-px text-xs sm:hidden">
            <div>
              { quoteDetail.quantity }
              { quoteDetail.unit && t(`units.${quoteDetail.unit}`) } @
            </div>
            <div className="whitespace-nowrap">
              <Price amount={quoteDetail.unitPrice} compact />
            </div>
          </div>
          <div className="mb-1 whitespace-nowrap font-semibold sm:mb-0 sm:font-normal">
            <Price amount={quoteDetail.subtotal} compact />
          </div>
        </div>
      </td>
    </tr>
  )
}

export default QuoteRow
