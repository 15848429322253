import { mergeClassName } from '../../utils/mergeClassName'

type ReactDiv = React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>

const Cell: React.FC<ReactDiv> = ({
  className,
  children,
  ...props
}) => {
  return (
    <div
      className={mergeClassName(
        'flex-1',
        className,
      )}
      {...props}
    >
      { children }
    </div>
  )
}

export default Cell
