import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useForm, useWatch } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

import {
  ResidenceAndRoomsType,
  MoveContent,
  boxesQuantities,
  furnitureRatio,
  maxAppliances,
  defaultBoxesPerResidence,
  usePreliminaryMoveSizeEstimate,
} from '../../../../modules/quotes'
import Form, { CardCheckboxGroup, CardLabel, Select, SubmitButton } from '../../../Form'
import { ButtonSize } from '../../../Button'
import { Heading, Subtitle } from '../../../typography'
import { type MoveSizeFormOutput, type MoveSizeFormValues } from './MoveSizeForm.type'
import useValidationSchema from './validationSchema'
import { mergeClassName } from '../../../../utils/mergeClassName'
import { formatFormOutput } from './formDataBuilder'
import InfoTooltip from '../../../InfoTooltip'
import MoveEstimatePreview from './MoveEstimatePreview'

type MoveSizeFormProps = {
  onSubmit: (data: MoveSizeFormOutput) => Promise<void> | void
}

const MoveSizeForm: React.FC<MoveSizeFormProps> = ({
  onSubmit,
}) => {
  const { t } = useTranslation()
  const schema = useValidationSchema()
  const form = useForm<MoveSizeFormValues>({
    resolver: yupResolver(schema),
    defaultValues: {
      residenceAndRoomsType: ResidenceAndRoomsType.Apartment2Bedroom,
      furnitureRatio: '100',
      moveContent: [MoveContent.Boxes, MoveContent.Furniture],
      nbAppliancesAndMore: '0',
    },
  })

  const moveDetails = useWatch({ control: form.control }) as MoveSizeFormValues

  const residenceAndRoomsType = moveDetails.residenceAndRoomsType
  const moveContent = moveDetails.moveContent
  const movingBoxes = moveContent?.includes(MoveContent.Boxes)
  const movingFurniture = moveContent?.includes(MoveContent.Furniture)
  const movingApplianceAndMore = moveContent?.includes(MoveContent.ApplianceAndMore)

  const moveSizeEstimate = usePreliminaryMoveSizeEstimate({
    ...moveDetails,
    nbBoxes: parseInt(moveDetails.nbBoxes ?? '0'),
    furnitureRatio: parseInt(moveDetails.furnitureRatio ?? '0'),
    nbAppliancesAndMore: parseInt(moveDetails.nbAppliancesAndMore ?? '0'),
  })

  const residenceAndRoomsTypeOptions = Object.values(ResidenceAndRoomsType).map((residenceAndRoomsType) => ({
    value: residenceAndRoomsType,
    label: t(`residenceAndRoomsType.${residenceAndRoomsType}.title`),
  }))

  const moveContentOptions = Object.fromEntries(Object.values(MoveContent).map((moveContent) => ([
    moveContent,
    <CardLabel key={moveContent} title={t(`moveContent.${moveContent}.title`)} />,
  ])))

  const nbBoxesOptions = Object.values(boxesQuantities).map((nbBoxes) => ({
    value: String(nbBoxes),
    label: t(`nbBoxes.${nbBoxes}.title`),
  }))

  const furnitureRatioOptions = Object.values(furnitureRatio).map((furnitureRatio) => ({
    value: String(furnitureRatio),
    label: t(`furnitureRatio.${furnitureRatio}.title`),
  }))

  const nbAppliances = Array.from({ length: maxAppliances + 1 }).map((_, index) => String(index))

  const handleSubmit = async (values: MoveSizeFormValues) => {
    await onSubmit(
      formatFormOutput(values, moveSizeEstimate),
    )
  }

  /**
   * update nb of boxes when residence type is updated
   */
  useEffect(() => {
    const defaultBoxes = defaultBoxesPerResidence[residenceAndRoomsType]
    form.setValue('nbBoxes', String(defaultBoxes))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [residenceAndRoomsType])

  return (
    <Form form={form} onSubmit={handleSubmit}>
      <Heading variant="h1" className="text-center lg:text-left">
        { t('forms.quotesRequest.moveSize.title') }
      </Heading>
      <Subtitle>
        { t('forms.quotesRequest.moveSize.subtitle') }
      </Subtitle>

      <div className="my-12">
        <div className="xl:w-1/2">
          <div className="my-2 font-body2">
            { t('forms.fields.residenceAndRoomsType.label') }
            { ' ' }
            <InfoTooltip
              tooltip={t('forms.fields.residenceAndRoomsType.tooltip')}
            />
          </div>
          <Select
            name="residenceAndRoomsType"
            options={residenceAndRoomsTypeOptions}
          />
        </div>
      </div>

      <div className="my-12">
        <div className="my-2 font-body2">
          { t('forms.fields.moveContent.label') }
        </div>
        <CardCheckboxGroup
          name="moveContent"
          options={moveContentOptions}
          className="xl:flex-row xl:justify-start"
          itemClassName="basis-1/3"
        />
      </div>

      <div className="my-12 flex flex-col gap-4 xl:flex-row xl:items-end">
        <div
          className={mergeClassName(
            'basis-1/3',
            !movingBoxes && 'hidden lg:block opacity-30',
          )}
        >
          <div className="my-2 font-body2">
            { t('forms.fields.nbBoxes.label') }
            { ' ' }
            { movingBoxes && (
              <InfoTooltip
                tooltip={t('forms.fields.nbBoxes.tooltip')}
              />
            ) }
          </div>
          <Select
            name="nbBoxes"
            options={nbBoxesOptions}
            disabled={!movingBoxes}
          />
        </div>

        <div
          className={mergeClassName(
            'basis-1/3',
            !movingFurniture && 'hidden lg:block opacity-30',
          )}
        >
          <div className="my-2 font-body2">
            { t('forms.fields.furnitureRatio.label') }
            { ' ' }
            { movingFurniture && (
              <InfoTooltip
                tooltip={t('forms.fields.furnitureRatio.tooltip')}
              />
            ) }
          </div>
          <Select
            name="furnitureRatio"
            options={furnitureRatioOptions}
            disabled={!movingFurniture}
          />
        </div>

        <div
          className={mergeClassName(
            'basis-1/3',
            !movingApplianceAndMore && 'hidden lg:block opacity-30',
          )}
        >
          <div className="my-2 font-body2">
            { t('forms.fields.nbAppliancesAndMore.label') }
            { ' ' }
            { movingApplianceAndMore && (
              <InfoTooltip
                tooltip={t('forms.fields.nbAppliancesAndMore.tooltip')}
              />
            ) }
          </div>
          <Select
            name="nbAppliancesAndMore"
            options={nbAppliances}
            disabled={!movingApplianceAndMore}
          />
        </div>
      </div>

      <div className="flex flex-col justify-between gap-2 gap-y-6 sm:flex-row">
        <div>
          <SubmitButton
            className="w-full lg:w-auto"
            size={ButtonSize.Large}
          >
            { t('forms.quotesRequest.nextStepCta', {
              nextStep: t('forms.quotesRequest.steps.quotes'),
            }) }
          </SubmitButton>
        </div>

        { moveSizeEstimate && moveSizeEstimate.volumeCubicFeet > 0 && (
          <MoveEstimatePreview moveSizeEstimate={moveSizeEstimate} />
        ) }

      </div>
    </Form>
  )
}

export default MoveSizeForm
