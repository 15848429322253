import { ThemeProvider as MuiThemeProvider } from '@emotion/react'

import { useMuiTheme } from '../core/theme.mui'
import ThemeVariables from './ThemeVariables'

type ThemeProviderProps = {
  children?: React.ReactNode
}

const ThemeProvider: React.FC<ThemeProviderProps> = ({ children }) => {
  const muiTheme = useMuiTheme()

  return (
    <MuiThemeProvider theme={muiTheme}>
      <ThemeVariables />
      { children }
    </MuiThemeProvider>
  )
}

export default ThemeProvider
