// based on https://ui.shadcn.com/docs/components/tooltip

import { forwardRef, useRef, useState } from 'react'
import * as TooltipPrimitive from '@radix-ui/react-tooltip'

import { mergeClassName } from '../../utils/mergeClassName'
import useClickoutHandler from '../../utils/useClickoutHandler'

const TooltipContent = forwardRef<
React.ElementRef<typeof TooltipPrimitive.Content>,
React.ComponentPropsWithoutRef<typeof TooltipPrimitive.Content>
>(({ className, sideOffset = 4, ...props }, ref) => (
  <TooltipPrimitive.Content
    ref={ref}
    sideOffset={sideOffset}
    className={mergeClassName(
      'z-50 overflow-hidden rounded-md border bg-popover px-3 py-1.5 text-sm text-popover-foreground shadow-md animate-in fade-in-50 data-[side=bottom]:slide-in-from-top-1 data-[side=left]:slide-in-from-right-1 data-[side=right]:slide-in-from-left-1 data-[side=top]:slide-in-from-bottom-1',
      className,
    )}
    {...props}
  />
))
TooltipContent.displayName = TooltipPrimitive.Content.displayName

export enum Position {
  Top = 'top',
  Right = 'right',
  Bottom = 'bottom',
  Left = 'left',
}

export enum Alignment {
  Start = 'start',
  Center = 'center',
  End = 'end',
}

export type TooltipProps = {
  children?: React.ReactNode
  tooltip?: React.ReactNode
  className?: string
  wrapperClassName?: string
  arrow?: boolean
  position?: Position | string
  alignment?: Alignment | string
  offset?: number
  alignOffset?: number
} & TooltipPrimitive.TooltipProps

export const Tooltip: React.FC<TooltipProps> = ({
  children,
  tooltip,
  className,
  arrow = true,
  position,
  alignment,
  offset,
  alignOffset,
  wrapperClassName,
  delayDuration = 150,
  ...tooltipProps
}) => {
  const [open, setOpen] = useState(false)
  const [clicked, setClicked] = useState(false)

  const triggerRef = useRef<HTMLDivElement>(null)
  useClickoutHandler(triggerRef, () => { setClicked(false) })

  const handleIconClick = (event: React.MouseEvent) => {
    event.preventDefault()
    event.stopPropagation()
    setClicked(!clicked)
  }

  return (
    <TooltipPrimitive.Provider>
      <TooltipPrimitive.Root
        delayDuration={delayDuration}
        {...tooltipProps}
        open={open || clicked}
        onOpenChange={setOpen}
      >
        <TooltipPrimitive.Trigger asChild>
          <div
            ref={triggerRef}
            className={mergeClassName('inline', wrapperClassName)}
            onClick={handleIconClick}
          >
            { children }
          </div>
        </TooltipPrimitive.Trigger>
        <TooltipContent
          className={mergeClassName('shadow-[0px_0px_10px_0px_rgba(0,0,0,0.2)] max-w-[100vw]', className)}
          data-testid="tooltip"
          side={position as Position}
          align={alignment as Alignment}
          sideOffset={offset}
          alignOffset={alignOffset}
        >
          { arrow && <TooltipPrimitive.Arrow className="fill-slate-400" /> }
          { tooltip }
        </TooltipContent>
      </TooltipPrimitive.Root>
    </TooltipPrimitive.Provider>
  )
}

export default Tooltip
