import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useIntercom } from 'react-use-intercom'

import {
  useMove,
  usePrepareMoveDepositAction,
} from '../../../modules/moves'
import { usePayment } from '../../../modules/payment/core/payment.hooks'
import { useEffectOnce } from '../../../utils/useEffectOnce'
import { PageContent } from '../../../components/Container'
import ContainedPage from '../../../components/layout/ContainedPage'
import { Heading } from '../../../components/typography'
import ServerError from '../../../components/errors/ServerError'
import Spinner from '../../../components/Spinner'
import DepositForm from '../../../components/forms/DepositForm'
import { errorNotification } from '../../../components/ToastNotifications'
import Alert from '../../../components/Alert'
import Button, { ButtonVariant } from '../../../components/Button'

type PendingDepositPageProps = {
  paymentError?: Error
}

const PendingDepositPage: React.FC<PendingDepositPageProps> = ({ paymentError }) => {
  const { t } = useTranslation()
  const { data: move } = useMove()
  const { prepareMoveDeposit, loading, error, called } = usePrepareMoveDepositAction()
  const [hasValidPaymentField, setHasValidPaymentField] = useState(true)
  const getPaymentGateway = usePayment({ onValidationUpdate: setHasValidPaymentField })
  const intercom = useIntercom()

  const formatPaymentError = (error: any) => {
    return t(
      'pages.move.pendingDeposit.error',
      { reason: String(error.message ?? 'Unknown') },
    )
  }

  const needPaymentRequestUpdate = !paymentError
  const paymentRequestReady = !needPaymentRequestUpdate || (called && !loading && !error)

  const paymentGateway = paymentRequestReady && move.paymentProfile?.depositRequest
    ? getPaymentGateway(move.paymentProfile.depositRequest.gateway)
    : undefined

  const paymentFormReady = !error && !!paymentGateway && move.paymentProfile?.depositRequest

  useEffectOnce(() => {
    if (needPaymentRequestUpdate) {
      prepareMoveDeposit().catch(console.error)
    }
  })

  const handleSubmit = async () => {
    if (!paymentGateway) {
      return
    }
    if (paymentGateway.preSubmit) {
      if (!await paymentGateway.preSubmit()) {
        return
      }
    }

    try {
      await paymentGateway.submit()
    } catch (error: any) {
      console.error(error)
      errorNotification(formatPaymentError(error))
    }
  }

  const openChat = () => {
    intercom.show()
  }

  return (
    <ContainedPage>
      <PageContent>
        <div className="pt-4 text-center">

          <Heading variant="h1">
            { t('pages.move.pendingDeposit.title') }
          </Heading>

          { paymentError
            ? (
              <div className="my-8">
                <Alert className="text-md justify-center">
                  { formatPaymentError(paymentError) }
                </Alert>
              </div>
              )
            : (
              <div className="my-8 font-sans text-lg">
                { t('pages.move.pendingDeposit.subtitle') }
              </div>
              ) }

          <div className="flex justify-center">
            <div className="xl:w-[650px]">

              { error && <ServerError error={error} /> }
              { !error && (!paymentFormReady) && <Spinner floating /> }
              { paymentFormReady && !!move.paymentProfile?.depositRequest && (
                <DepositForm
                  onSubmit={handleSubmit}
                  paymentField={paymentGateway.renderPaymentField({
                    paymentRequest: move.paymentProfile.depositRequest,
                    billingAddress: move.origin.address,
                  })}
                  paymentRequest={move.paymentProfile.depositRequest}
                  hasValidPaymentField={hasValidPaymentField}
                />
              ) }

            </div>
          </div>

          <div className="my-4 text-center">
            <Button
              onClick={openChat}
              variant={ButtonVariant.Transparent}
              className="!text-base !text-neutral-600 !underline"
            >
              { t('pages.move.pendingDeposit.havingPaymentIssue') }
            </Button>
          </div>
        </div>

      </PageContent>
    </ContainedPage>
  )
}

export default PendingDepositPage
