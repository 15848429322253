import { useTranslation } from 'react-i18next'
import { PageContent } from '../../../components/Container'
import MoveRecapBanner from '../../../components/MoveSummaryBanner'
import ContainedPage from '../../../components/layout/ContainedPage'
import { Quotes } from '../../../components/quotes'
import { Heading, Subtitle } from '../../../components/typography'
import { useMove } from '../../../modules/moves'

const QuotesPage: React.FC = () => {
  const { t } = useTranslation()
  const { data: move } = useMove()

  return (
    <ContainedPage>
      <PageContent topContent={<MoveRecapBanner />}>
        <div className="mb-12 mt-2 text-center">
          <Heading variant="h1">
            { t('pages.move.quotes.title') }
          </Heading>
          <Subtitle className="lg:text-center">
            { t('pages.move.quotes.subtitleLine1') }
            <br />
            { t('pages.move.quotes.subtitleLine2') }
          </Subtitle>
        </div>

        { move.quotes && (
          <Quotes quotes={move.quotes} />
        ) }

      </PageContent>
    </ContainedPage>
  )
}

export default QuotesPage
