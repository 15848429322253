import {
  ToastContainer as ToastifyContainer,
  type ToastContainerProps as ToastifyToastContainerProps,
} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import './style.css'
import { useThemeState } from '../../modules/theme'

const ONE_SEC_MS = 1000
const FIVE_SECS_MS = 5 * ONE_SEC_MS

type ToastContainerProps = Omit<ToastifyToastContainerProps, 'theme'>

const ToastContainer: React.FC<ToastContainerProps> = (toastifyProps) => {
  const { darkModeEnabled } = useThemeState()
  return (
    <ToastifyContainer
      position="top-right"
      autoClose={FIVE_SECS_MS}
      className="toastContainer"
      theme={darkModeEnabled ? 'dark' : 'light'}
      {...toastifyProps}
      toastStyle={{ borderRadius: 0, maxWidth: 650 }}
      progressStyle={{ height: 3 }}
    />
  )
}

export default ToastContainer
