import { type Move, type UpdateMovePayload } from '../../../../modules/moves'
import { type Place } from '../../../../common/place'
import { type AddressesFormValues } from './AddressesForm.type'

const removeEmptyStrings = <T,>(object: Record<string, any>): T => {
  return Object.fromEntries(Object.entries(object).filter(([key, value]) => {
    return value !== ''
  })) as T
}

const boolToString = (value?: boolean) => {
  if (value === null) {
    return undefined
  }
  return value ? '1' : '0'
}

const getPlaceFormData = (place: Place, specificAddresses = false) => {
  if ('placeId' in place && specificAddresses) {
    return {
      ...place,
      placeId: undefined,
      placeTextLine1: undefined,
      placeTextLine2: undefined,
    } as unknown as Place
  } else {
    return place
  }
}

type MoveToFormDataOptions = {
  specificAddresses?: boolean
}

export const moveToFormData = (
  move: Move,
  {
    specificAddresses = false,
  }: MoveToFormDataOptions = {}): AddressesFormValues => {
  return {
    origin: {
      ...move.origin,
      address: getPlaceFormData(move.origin.address, specificAddresses),
      nbStairs: move.origin.nbStairs ?? 0,
      elevatorReservationRequired: boolToString(move.origin.elevatorReservationRequired),
    },
    destination: {
      ...move.destination,
      address: getPlaceFormData(move.destination.address, specificAddresses),
      nbStairs: move.destination.nbStairs ?? 0,
      elevatorReservationRequired: boolToString(move.destination.elevatorReservationRequired),
    },
  }
}

export const formatFormOutput = (values: AddressesFormValues): Partial<UpdateMovePayload> => {
  return {
    ...values,
    origin: {
      ...values.origin,
      address: removeEmptyStrings(values.origin.address),
      elevatorReservationRequired: values.origin.elevatorReservationRequired === undefined ? undefined : values.origin.elevatorReservationRequired === '1',
    },
    destination: {
      ...values.destination,
      address: removeEmptyStrings(values.destination.address),
      elevatorReservationRequired: values.destination.elevatorReservationRequired === undefined ? undefined : values.destination.elevatorReservationRequired === '1',
    },
  }
}
