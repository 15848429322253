import { useTranslation } from 'react-i18next'

import { PageContent } from '../../../components/Container'
import SpecialItemsForm from '../../../components/forms/SpecialItemsForm'
import ContainedPage from '../../../components/layout/ContainedPage'
import { Heading } from '../../../components/typography'
import { type UpdateMovePayload, useMove, useUpdateMoveAction } from '../../../modules/moves'
import { useErrorFormatter } from '../../../components/errors/useErrorFormatter'
import { errorNotification } from '../../../components/ToastNotifications'

const PersonnalizedMoveSizePage: React.FC = () => {
  const { t } = useTranslation()
  const { data: move } = useMove()
  const { updateMove } = useUpdateMoveAction()
  const formatError = useErrorFormatter()

  const onSubmit = async (data: UpdateMovePayload) => {
    try {
      await updateMove(data)
    } catch (error) {
      errorNotification(formatError(error))
    }
  }

  return (
    <ContainedPage>
      <PageContent>

        <div className="my-4">
          <Heading variant="h1" className="text-center">
            { t('pages.move.personnalizedMoveSize.title') }
          </Heading>

          <SpecialItemsForm
            move={move}
            onSubmit={onSubmit}
          />
        </div>

      </PageContent>
    </ContainedPage>
  )
}

export default PersonnalizedMoveSizePage
