enum Route {
  HomePage = '/',
  TermsOfUse = '/terms-of-use',
  PrivacyPolicy = '/privacy-policy',
  QuotesRequest = '/quotes-request',
  Move = '/move/:moveId',
  EditMove = '/move/:moveId/edit',
  StripeLandingPage = '/move/:moveId/stripe',
  FreeConsultation = '/free-consultation',
}

export default Route
