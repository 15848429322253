import { useMemo } from 'react'

import { buildTheme } from '../core/theme.builder'
import { useTheme } from '../core/theme.hooks'

const ThemeVariables: React.FC = () => {
  const themeColors = useTheme()
  const theme = useMemo(() => buildTheme(themeColors), [themeColors])

  return (
    <style> { `:root{ ${String(theme)} }` } </style>
  )
}

export default ThemeVariables
