import { type Amount } from '../../common/amount'

const DEFAULT_LOCALE = 'en-US'

type PriceProps = {
  amount: Amount
  compact?: boolean
  showDecimals?: boolean
}

const Price: React.FC<PriceProps> = ({
  amount,
  compact = false,
  showDecimals = true,
}) => {
  const priceFormatter = new Intl.NumberFormat(DEFAULT_LOCALE, {
    style: 'currency',
    currency: amount.currency,
  })

  const price = showDecimals ? amount.price : Math.ceil(amount.price)

  const isMinus = price < 0
  const formattedPrice = priceFormatter.format(price).split('$')[1]

  const parts = formattedPrice.split('.')
  // todo : use correct currency symbol
  return (
    <div className="inline-flex whitespace-nowrap">
      { isMinus && '-' }
      { !compact && ' ' }
      $
      { !compact && ' ' }
      { parts[0] }
      { !compact && showDecimals && ' ' }
      { showDecimals && (
        <span className={compact ? undefined : 'text-sm'}>.{ parts[1] }</span>
      ) }
    </div>
  )
}

export default Price
