/* eslint-disable @typescript-eslint/no-misused-promises */
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import PromoCodeIcon from '@mui/icons-material/LocalOfferOutlined'
import CheckIcon from '@mui/icons-material/Check'
import RemoveIcon from '@mui/icons-material/CancelOutlined'

import useValidationSchema from './validationSchema'
import { useMove } from '../../../modules/moves'
import Form, { Input, SubmitButton } from '../../Form'
import { InputSize } from '../../Form/controls/Input'
import Button, { ButtonSize, ButtonVariant } from '../../Button'
import Tooltip from '../../Tooltip'
import Spinner from '../../Spinner'

type PromoCodeFormProps = {
  onSubmit: (promoCode: string | null) => Promise<void> | void
}

const PromoCodeForm: React.FC<PromoCodeFormProps> = ({
  onSubmit,
}) => {
  const { t } = useTranslation()
  const schema = useValidationSchema()
  const { data: move } = useMove()
  const form = useForm({
    resolver: yupResolver(schema),
  })
  const [visible, setVisible] = useState(!!move.promoCode)
  const [isRemovingPromoCode, setIsRemovingPromoCode] = useState(false)

  if (!visible) {
    return (
      <div className="text-right">
        <Button
          onClick={() => { setVisible(true) }}
          variant={ButtonVariant.Transparent}
          className="!p-0 !text-primary !underline"
        >
          { t('forms.promoCode.haveACoupon') }
        </Button>
      </div>
    )
  }

  const removePromoCode = async (event: React.MouseEvent) => {
    event.stopPropagation()
    try {
      setIsRemovingPromoCode(true)
      setVisible(false)
      await onSubmit(null)
    } finally {
      setIsRemovingPromoCode(false)
    }
  }

  return (
    <div className="my-4 flex flex-col justify-between gap-y-2 rounded-md border bg-white p-4 sm:flex-row lg:p-8">
      <Form
        form={form}
        formProps={{
          className: 'w-full',
        }}
        onSubmit={async (data: { promoCode: string }) => { await onSubmit(data.promoCode) }}
      >

        <div className="flex flex-col justify-between gap-y-2 sm:flex-row">
          <div className="flex items-center gap-3 font-sans text-neutral-500">
            <PromoCodeIcon className="!text-[18px]" />
            { t('forms.promoCode.coupon') }
          </div>

          { move.promoCode
            ? (
              <div className="flex items-center gap-x-2">
                <div className="text-green-700">
                  <CheckIcon className="!text-[16px]" />
                  { ' ' }
                  { move.promoCode.code }
                </div>

                { isRemovingPromoCode
                  ? (
                    <Spinner small />
                    )
                  : (
                    <Tooltip tooltip={t('forms.promoCode.removeCoupon')}>
                      <div
                        className="cursor-pointer text-red-800"
                        onClick={removePromoCode}
                      >
                        <RemoveIcon className="!mt-[-3px] !text-[20px]" />
                      </div>
                    </Tooltip>
                    ) }
              </div>
              )
            : (
              <div className="flex sm:w-[350px]">
                <Input
                  name="promoCode"
                  size={InputSize.Small}
                  className="child:!rounded-r-none"
                />
                <div>
                  <SubmitButton
                    size={ButtonSize.Small}
                    className="!h-[40px] !rounded-l-none !rounded-r-[4px] !border-l-0 !bg-neutral-400 !shadow-none"
                  >
                    { t('actions.apply') }
                  </SubmitButton>
                </div>
              </div>
              ) }

        </div>
      </Form>
    </div>
  )
}

export default PromoCodeForm
