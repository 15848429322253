/* eslint-disable @typescript-eslint/no-non-null-assertion */
import {
  type Quote,
  type QuoteAlternativeDate,
} from '../../../../../modules/quotes'
import { ignoreTimezone } from '../../../../../utils/date'
import DateBox from './DateBox'

type AlternativeDateProps = {
  quote: Quote
  alternativeDate: QuoteAlternativeDate
}

const AlternativeDate: React.FC<AlternativeDateProps> = ({
  quote,
  alternativeDate,
}) => {
  const date = ignoreTimezone(alternativeDate.date)!

  const getClassName = () => {
    if (!alternativeDate.dateAvailable) {
      return 'bg-red-300'
    }
    if (alternativeDate.subtotal.price < quote.subtotal.price) {
      return 'bg-green-200'
    }

    if (alternativeDate.subtotal.price > quote.subtotal.price) {
      return 'bg-red-100'
    }
  }

  return (
    <DateBox
      key={date.getTime()}
      date={date}
      price={alternativeDate.subtotal.price}
      className={getClassName()}
      floatingNode={!alternativeDate.dateAvailable && (
        <div className="before:absolute before:left-[30%] before:top-[-30%] before:h-[200%] before:w-px before:rotate-45 before:bg-red-400 before:content-[''] after:absolute after:right-[30%] after:top-[-30%] after:h-[200%] after:w-px after:-rotate-45 after:bg-red-400 after:content-['']" />
      )}
    />
  )
}

export default AlternativeDate
