import Link, { LinkVariant } from '../../components/Link'

const FrenchContent: React.FC = () => (
  <>
    <h2>Introduction</h2>
    <p>La mission de MovingWaldo Inc («nous», «MovingWaldo», «movingwaldo.com») est de développer des technologies pour simplifier les tâches liées au déménagement. Nous souhaitons que ce service permette à nos utilisateurs («vous», «le client») de sauver du temps, de l'argent et de s'assurer de ne rien oublier lors du déménagement. Nous croyons que MovingWaldo Inc propose une solution gagnante autant pour les utilisateurs que pour les fournisseurs de services participants en simplifiant l'administration des services liés au déménagement. Dans le but d'offrir un tel service, nous devons gagner la confiance de toutes les personnes impliquées. Pour ce faire, nous décrivons ici explicitement comment notre service fonctionne, pourquoi votre information est en sécurité avec nous et, enfin, à qui et dans quelles circonstances nous partagerons vos informations.</p>

    <h2>But et portée de cette politique</h2>
    <p>Le but de cette politique de confidentialité est de décrire de quelle façon nous comptons recueillir, utiliser et divulguer les renseignements personnels que vous nous fournissez. Si vous n'êtes pas d'accord avec cette politique de confidentialité, il vous sera impossible d'utiliser les services offerts par MovingWaldo. Nous pouvons réviser cette politique de confidentialité à tout moment en mettant à jour les informations mentionnées sur cette page. Cette politique de confidentialité a été mise à jour et est en vigueur à la date d'effet indiquée ci-dessous. Nous vous recommandons donc de consulter cette page de temps à autre. </p>

    <h2>Services</h2>
    <p>MovingWaldo propose plusieurs services pour aider les utilisateurs dans leurs besoins de déménagement. Ces services sont rendus aux utilisateurs via différentes plateformes et sous-domaines appartenant à MovingWaldo Inc, ou via notre service client et nos agents de réservation. La politique de confidentialité actuelle s'applique aux services décrits ci-dessous. Pour plus d'informations sur d'autres services, veuillez vous référer au <Link variant={LinkVariant.Primary} target="_blank" to="https://www.movingwaldo.com/fr/politique-de-confidentialite/">https://www.movingwaldo.com/fr/politique-de-confidentialite/</Link>. </p>

    <h3>Plateforme de réservation de services de déménagement</h3>
    <p>Le service principal de MovingWaldo est une plateforme de réservation de services de déménagement. Les utilisateurs peuvent visiter MovingWaldo et obtenir des devis de déménagement auprès de plusieurs fournisseurs, soit en ligne sur https://booking.movingwaldo.com, soit par l'intermédiaire d'un agent de réservation. Pour en bénéficier, les utilisateurs doivent saisir leurs coordonnées telles que leur nom, leur adresse courriel et leur numéro de téléphone. De plus, ils devront inscrire des informations concernant leur déménagement, incluant mais sans s'y limiter, leur adresse, l'adresse où ils déménagent leurs biens, l'inventaire des objets à déménager, la date du déménagement, des informations sur la taille et l'accès à leur maison, etc. Une fois cela fait, des soumissions de déménagement de nos partenaires leur seront présentées, et ils auront la possibilité d'accepter l'une des soumissions de déménagement pour réserver des services de déménagement. MovingWaldo pourra percevoir un acompte pour confirmer la réservation, et pourra également procéder au paiement du service complet comme cela sera expliqué à l'utilisateur lors du processus d'achat.</p>

    <h2>Informations que nous partageons</h2>
    <p>Nous ne partagerons aucune information personnelle (notamment votre nom, ancienne ou nouvelle adresse, date de déménagement, etc.) avec des entreprises, organisations ou individus autre que MovingWaldo Inc. à moins qu'une des circonstances suivantes se produise:</p>
    <ol>
      <li>Pour mettre en œuvre notre service
        <p>Afin de mettre en œuvre le service que nous offrons sur www.movingwaldo.com ou l'un de ses sous-domaines, par l'intermédiaire de notre service client ou de nos agents de réservation, ou via d'autres sites Web appartenant à MovingWaldo inc, nous pouvons être amenés à partager vos informations personnelles avec des tiers. Dans de telles situations, nous pouvons partager uniquement les informations personnelles nécessaires à l'exécution du service commandé. Par exemple, si vous nous demandez de réserver un déménagement pour vous, nous partageons vos informations applicables avec le déménageur sélectionné afin qu'il puisse traiter votre demande. </p>
      </li>
      <li>Pour des raisons juridiques
        <p>Nous partagerons vos informations personnelles avec des sociétés, des organisations ou des individus en dehors de MovingWaldo Inc. si nous pensons que cela est nécessaire pour : respecter toute loi, réglementation, procédure judiciaire ou demande gouvernementale exécutoire applicable, ou, afin de détecter, prévenir ou résoudre de toute autre manière les problèmes de fraude, de sécurité ou techniques.</p>
      </li>
      <li>Les données agrégées
        <p>Nous pouvons partager des informations agrégées, non identifiables personnellement publiquement et avec nos partenaires ('données globales'). Par exemple, nous pourrions partager des informations pour montrer les tendances dans les déménagements et d'autres informations sur les personnes déménageant. Les données de tous les utilisateurs (tel que défini ci-dessous) peuvent être incluses. </p>
      </li>
    </ol>

    <h2>Informations que nous recueillons auprès des utilisateurs</h2>
    <p>Les informations que nous collectons auprès des « Utilisateurs » varient en fonction du service demandé par l'utilisateur et incluent, au minimum, son adresse courriel. Les informations supplémentaires que nous collectons peuvent inclure la date du déménagement, l'ancienne et la nouvelle adresse, des informations sur leur maison, l'inventaire du déménagement, etc. Nous collectons également des informations concernant le fournisseur de services qu'un utilisateur demandera à MovingWaldo Inc. d'informer d'une demande de soumission de déménagement ou d'une demande de réservation. Toutes les informations collectées en dehors des données de navigation standard des « visiteurs » (« les visiteurs » dans ce cas incluent les « utilisateurs »), y compris, mais sans s'y limiter, l'emplacement des visiteurs, l'heure de la visite et le temps passé sur le site Web, l'adresse IP de l'ordinateur, etc. , est délibérément fourni par l'utilisateur lui-même afin de bénéficier d'un service nécessitant de telles informations.</p>

    <h2>Règlement général sur la protection des données (RGPD)</h2>
    <p>Nous sommes un contrôleur de données de vos informations.</p>

    <p>La base juridique de MovingWaldo inc. pour la collecte et l'utilisation des informations personnelles décrites dans la présente politique de confidentialité dépend des informations personnelles que nous collectons et du contexte spécifique dans lequel nous collectons les informations :</p>
    <ul>
      <li>MovingWaldo inc. doit exécuter un contrat avec vous.</li>
      <li>Vous avez autorisé MovingWaldo inc. à le faire.</li>
      <li>Le traitement de vos informations personnelles est dans l'intérêt légitime de MovingWaldo inc.</li>
      <li>MovingWaldo inc. doit se conformer à la loi.</li>
    </ul>

    <p>MovingWaldo inc. ne conservera vos informations personnelles que le temps nécessaire aux fins énoncées dans la présente politique de confidentialité. Nous conserverons et utiliserons vos informations dans la mesure nécessaire pour nous conformer à nos obligations légales, résoudre les litiges et appliquer nos politiques.</p>

    <p>Si vous résidez dans l'Espace économique européen (EEE), vous disposez de certains droits en matière de protection des données. Si vous souhaitez être informé des informations personnelles que nous détenons à votre sujet et si vous souhaitez qu'elles soient supprimées de nos systèmes, veuillez nous contacter.</p>

    <p>Dans certaines circonstances, vous disposez des droits suivants en matière de protection des données :</p>
    <ul>
      <li>Le droit d'accéder, de mettre à jour ou de supprimer les informations que nous avons sur vous.</li>
      <li>Le droit de rectification.</li>
      <li>Le droit d'opposition.</li>
      <li>Le droit de restriction.</li>
      <li>Le droit à la portabilité des données</li>
      <li>Le droit de retirer son consentement</li>
    </ul>

    <h2>Fichiers journaux</h2>

    <p>MovingWaldo suit une procédure standard d'utilisation des fichiers journaux. Ces fichiers enregistrent les visiteurs lorsqu'ils visitent des sites Web. Toutes les sociétés d'hébergement le font dans le cadre des analyses des services d'hébergement. Les informations collectées par les fichiers journaux incluent les adresses IP (protocole Internet), le type de navigateur, le fournisseur d'accès Internet (FAI), l'horodatage, les pages de renvoi/sortie et éventuellement le nombre de clics. Ceux-ci ne sont liés à aucune information personnellement identifiable. Le but de l'information est d'analyser les tendances, d'administrer le site, de suivre les mouvements des utilisateurs sur le site Web et de recueillir des informations démographiques.</p>

    <h2>Cookies et balises Web</h2>

    <p>Les témoins sont de petits fichiers texte qui s'intègrent sur votre disque dur à partir de certaines pages du site. Les témoins sont inoffensifs dans le sens où ils ne peuvent extraire aucune information de votre disque dur, sur laquelle vous avez un contrôle total. Nous, et notre réseau de fournisseurs pouvons utiliser des témoins pour déterminer le trafic du site, transmettre des informations utiles sur vos intérêts et personnaliser vos futures visites sur le site ou optimiser votre expérience publicitaire. Les témoins ne nous fournissent pas systématiquement d'informations personnelles vous concernant, et vous pouvez choisir de cesser de partager des témoins à tout moment en modifiant simplement la configuration de votre navigateur. Nous utilisons également des balises Web qui sont de petites chaînes de code placées dans une page de site Web ou dans un message électronique. Ils sont parfois appelés «GIF clairs» (Graphics Interchange Format) ou «pixels invisibles». Lorsque vous visitez une page particulière, les balises Web nous informent de votre visite. Certains de nos partenaires publicitaires et autres tiers peuvent aussi installer des témoins ou pixels invisibles au moment où :</p>

    <ul>
      <li>Vous cliquez sur leurs messages publicitaires.</li>
      <li>Vous cliquez sur des liens menant vers leurs sites web.</li>
      <li>Vous cliquez sur des services offerts sur nos sites web.</li>
    </ul>
    <p>L'installation de ces témoins ou pixels invisibles par nos partenaires et autres tiers est effectuée à des fins publicitaires. La présente Politique de confidentialité ne s'applique pas aux sites web de tiers ni aux fournisseurs publicitaires de ces tiers.</p>

    <p>Étant donné que les balises Web sont utilisées en combinaison avec des témoins, si vous désactivez les témoins, les balises Web ne détectent qu'une visite anonyme du site. Lorsqu'elles sont utilisées dans un courriel, les balises Web nous permettent de savoir si vous avez reçu le courriel.</p>

    <h2>Sites de tierces parties</h2>
    <p>Notre site web contient des liens vers d'autres sites qui sont détenus et exploités par des tiers. Ces liens vous permettront de quitter notre site web. Les sites liés ne sont pas sous notre contrôle et nous ne sommes pas responsables du contenu de tout site lié ou de tout lien contenu dans un site lié, ni des changements ou mises à jour de ces sites. Nous ne sommes pas responsables de la diffusion Web ou de toute autre forme de transmission reçue d'un site lié. Nous fournissons des liens vers ceux-ci pour votre commodité. Nous ne cautionnons pas le site, ni son utilisation ou son contenu.</p>

    <p>Nous vous encourageons à examiner et à comprendre les termes, conditions et politiques de confidentialité publiés sur ces sites afin de vous assurer que vous comprenez leur fonctionnement et pratique concernant le traitement de vos informations personnelles. Nous déclinons toute responsabilité à l'égard de ces services de tiers.</p>

    <h2>Informations sur les enfants</h2>

    <p>Une autre partie de notre priorité est d'ajouter une protection aux enfants lorsqu'ils utilisent Internet. Nous encourageons les parents et les tuteurs à observer, participer et/ou surveiller et guider leur activité en ligne.</p>

    <p>La plateforme de réservation ne collecte sciemment aucune information personnelle identifiable d'enfants de moins de 13 ans. Si vous pensez que votre enfant a fourni ce type d'informations sur notre site Web, nous vous encourageons vivement à nous contacter immédiatement et nous vous faisons de notre mieux pour supprimer rapidement ces informations de nos dossiers.</p>

    <h2>Politique de confidentialité en ligne uniquement</h2>

    <p>Notre politique de confidentialité s'applique uniquement à nos activités en ligne et est valable pour les visiteurs de notre site Web en ce qui concerne les informations qu'ils ont partagées et/ou collectées dans la plateforme de MovingWaldo. Cette politique ne s'applique pas aux informations collectées hors ligne ou via des canaux autres que ce site Web.</p>

    <h2>Consentement</h2>

    <p>En utilisant notre site Web, vous consentez par la présente à notre politique de confidentialité et acceptez ses conditions.</p>
  </>
)

export default FrenchContent
