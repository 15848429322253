import { ResidenceAndRoomsType, ResidenceType } from '../quotes.types'

export const residenceTypeFromRooms: Record<ResidenceAndRoomsType, ResidenceType> = {
  [ResidenceAndRoomsType.Studio]: ResidenceType.Studio,
  [ResidenceAndRoomsType.Apartment1Bedroom]: ResidenceType.Apartment,
  [ResidenceAndRoomsType.Apartment2Bedroom]: ResidenceType.Apartment,
  [ResidenceAndRoomsType.Apartment3Bedroom]: ResidenceType.Apartment,
  [ResidenceAndRoomsType.Apartment4Bedroom]: ResidenceType.Apartment,
  [ResidenceAndRoomsType.House1Bedroom]: ResidenceType.House,
  [ResidenceAndRoomsType.House2Bedroom]: ResidenceType.House,
  [ResidenceAndRoomsType.House3Bedroom]: ResidenceType.House,
  [ResidenceAndRoomsType.House4Bedroom]: ResidenceType.House,
  [ResidenceAndRoomsType.AssistedLiving]: ResidenceType.AssistedLiving,
  [ResidenceAndRoomsType.OfficeSpace]: ResidenceType.OfficeSpace,
  [ResidenceAndRoomsType.StorageUnit5By5]: ResidenceType.StorageUnit5By5,
  [ResidenceAndRoomsType.StorageUnit5By10]: ResidenceType.StorageUnit5By10,
  [ResidenceAndRoomsType.StorageUnit10By20]: ResidenceType.StorageUnit10By20,
}
