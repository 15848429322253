import { I18nextProvider } from 'react-i18next'
import { Provider as ReduxProvider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { ApolloProvider } from '@apollo/client'
import { BrowserRouter, Routes, Route as ReactRouterRoute } from 'react-router-dom'
import CssBaseline from '@mui/material/CssBaseline'

import { GoogleMapProvider } from '../modules/googleMaps'
import i18n from '../modules/i18n'
import { ThemeProvider } from '../modules/theme'
import client from '../modules/graphQl/core/client'
import { ScrollRestauration } from '../modules/navigation'
import { GoogleAnalytics, FacebookPixel } from '../modules/analytics'
import { Cookieyes } from '../modules/consent'
import Route from './Route.enum'
import store, { persistor } from '../store'
import MuiDatePickerProvider from './MuiDatePickerProvider'
import ErrorBoundary from '../components/errors/ErrorBoundary'
import { ToastContainer } from '../components/ToastNotifications'
import HomeDispatcherPage from '../pages/HomeDispatcherPage'
import TermsOfUsePage from '../pages/termsOfUse'
import PrivacyPolicyPage from '../pages/privacyPolicy'
import { Error403Page, Error404Page, Error500Page } from '../pages/errors'
import QuotesRequestPage from '../pages/quotes/QuotesRequestPage'
import {
  MoveGatePage,
  MoveDispatcherPage,
  EditMovePage,
} from '../pages/move'
import IntercomWrapper from './IntercomWrapper'
import FreeConsultationPage from '../pages/FreeConsultationPage'
import StripeLandingPage from '../modules/payment/gateway/stripe/StripeLandingPage'

const App: React.FC = () => {
  return (
    <I18nextProvider i18n={i18n}>
      <MuiDatePickerProvider>
        <ReduxProvider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            { () => (
              <ThemeProvider>
                <GoogleMapProvider>
                  <ApolloProvider client={client}>
                    <CssBaseline />

                    <BrowserRouter>
                      <ErrorBoundary>
                        <IntercomWrapper>
                          <Routes>
                            <ReactRouterRoute path={Route.HomePage} element={<HomeDispatcherPage />} />
                            <ReactRouterRoute path={Route.TermsOfUse} element={<TermsOfUsePage />} />
                            <ReactRouterRoute path={Route.PrivacyPolicy} element={<PrivacyPolicyPage />} />

                            <ReactRouterRoute path={Route.QuotesRequest} element={<QuotesRequestPage />} />

                            <ReactRouterRoute path={Route.Move} element={<MoveGatePage />}>
                              <ReactRouterRoute path="" element={<MoveDispatcherPage />} />
                              <ReactRouterRoute path={Route.EditMove} element={<EditMovePage />} />
                              <ReactRouterRoute path={Route.StripeLandingPage} element={<StripeLandingPage />} />
                            </ReactRouterRoute>

                            <ReactRouterRoute path={Route.FreeConsultation} element={<FreeConsultationPage />} />

                            <ReactRouterRoute path="/403" element={<Error403Page />} />
                            <ReactRouterRoute path="/500" element={<Error500Page />} />
                            <ReactRouterRoute path="*" element={<Error404Page />} />
                          </Routes>

                          <Cookieyes />
                          <GoogleAnalytics />
                          <FacebookPixel />
                          <ScrollRestauration />
                          <ToastContainer />
                        </IntercomWrapper>
                      </ErrorBoundary>
                    </BrowserRouter>
                  </ApolloProvider>
                </GoogleMapProvider>
              </ThemeProvider>
            ) }
          </PersistGate>
        </ReduxProvider>
      </MuiDatePickerProvider>
    </I18nextProvider>
  )
}

export default App
