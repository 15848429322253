import { Fragment } from 'react'

import { mergeClassName } from '../../utils/mergeClassName'
import { type KeyValueMap } from '../../common/keyValueMap'
import Values, { type ValuesProps } from './Values'

type QuantitiesProps = Omit<ValuesProps, 'children'> & {
  children?: KeyValueMap<string, string | number>
  quantityClassName?: string
}

const Quantities: React.FC<QuantitiesProps> = ({
  children,
  quantityClassName,
  ...props
}) => {
  if (!children && !Array.isArray(children)) {
    return (<Values />)
  }

  return (
    <Values {...props}>
      { children.map(({ key, value }) => (
        <Fragment
          key={key}
        >
          <div className={mergeClassName(
            'inline-block font-bold mr-1 text-neutral-600',
            quantityClassName,
          )}
          >
            { value }
          </div>
          { key }
        </Fragment>
      )) }
    </Values>
  )
}

export default Quantities
