import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  type StripePaymentElementChangeEvent,
} from '@stripe/stripe-js'
import {
  PaymentElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js'

import { type PaymentFieldProps } from '../gateway.types'
import Alert from '../../../../components/Alert'

type StripePaymentFieldProps = PaymentFieldProps & {
  setStripe: (stripe: ReturnType<typeof useStripe>) => void
  setElements: (elements: ReturnType<typeof useElements>) => void
  onValidationUpdate: (valid: boolean) => void
  hasError?: boolean
}

const StripePaymentField: React.FC<StripePaymentFieldProps> = ({
  billingAddress,
  setStripe,
  setElements,
  onValidationUpdate,
  hasError = false,
}) => {
  const { t } = useTranslation()
  const stripe = useStripe()
  const elements = useElements()
  const [ready, setReady] = useState(false)

  /**
   * check if payment fields are valid when changed
   */
  const onChange = (event: StripePaymentElementChangeEvent) => {
    if (!ready) {
      return
    }
    onValidationUpdate(!event.empty && event.complete)
  }

  /**
   * forward stripe and elements to the gateway
   */
  useEffect(() => { setStripe(stripe) }, [setStripe, stripe])
  useEffect(() => { setElements(elements) }, [setElements, elements])

  /**
   * if a billing address is provided, set country + postal code
   */
  const defaultValues = useMemo(() => {
    if (!billingAddress) {
      return
    }
    const country = 'country' in billingAddress ? billingAddress.country : undefined
    const postalCode = 'postalCode' in billingAddress ? billingAddress.postalCode : undefined

    return {
      billingDetails: {
        address: {
          country,
          postal_code: postalCode,
        },
      },
    }
  }, [billingAddress])

  return (
    <>
      <PaymentElement
        onReady={() => { setReady(true) }}
        onChange={onChange}
        options={{
          defaultValues,
        }}
      />
      { hasError && (
        <Alert>
          { t('errors.paymentError') }
        </Alert>
      ) }
    </>
  )
}

export default StripePaymentField
