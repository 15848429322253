import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Hamburger from 'hamburger-react'

import { useThemeLogo } from '../../../modules/theme'
import { useLogoutAction, useSavedMoveId } from '../../../modules/moves'
import Route from '../../../app/Route.enum'
import { ReactComponent as Logo } from '../../../assets/logo.svg'
import Container from '../../Container/Container'
import Link from '../../Link'
import Button, { ButtonVariant, ButtonSize } from '../../Button'
import ThemeLogo from './ThemeLogo'
import LanguageDropdown from './LanguageDropdown'
import MobileMenu from '../MobileMenu'
import { mergeClassName } from '../../../utils/mergeClassName'

const Header: React.FC = () => {
  const { t } = useTranslation()
  const [mobileMenuOpened, setMobileMenuOpened] = useState(false)
  const themeLogo = useThemeLogo()
  const moveId = useSavedMoveId()
  const logout = useLogoutAction()

  const hasMoveSaved = !!moveId

  return (
    <>
      <div className="sticky top-0 z-10 flex h-[70px] w-full items-center border-b-2 bg-white">
        <Container>
          <div className="flex items-center justify-between">
            <Link to={Route.HomePage} className="inline-flex h-[45px] items-center">
              { themeLogo ? <ThemeLogo /> : <Logo className="h-full" /> }
            </Link>
            <div className="flex items-center gap-x-4">

              <div className={mergeClassName(
                'flex items-center gap-x-4',
                mobileMenuOpened && 'opacity-0',
              )}
              >
                <div className="hidden md:block">
                  <LanguageDropdown />
                </div>
                { hasMoveSaved && (
                  <div className="hidden lg:block">
                    <Button
                      variant={ButtonVariant.Outlined}
                      size={ButtonSize.Small}
                      onClick={logout}
                    >
                      { t('header.logout') }
                    </Button>
                  </div>
                ) }
              </div>

              <div className={mergeClassName(
                mobileMenuOpened && 'hidden',
              )}
              >
                <Hamburger
                  toggled={false}
                  onToggle={() => { setMobileMenuOpened(true) }}
                  direction="right"
                  size={25}
                  rounded
                />
              </div>
            </div>
          </div>
        </Container>
      </div>
      <MobileMenu
        open={mobileMenuOpened}
        onClose={() => { setMobileMenuOpened(false) }}
      />
    </>
  )
}

export default Header
