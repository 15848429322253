import { useEffect } from 'react'
import { replaceColor } from 'lottie-colorify'

import calendarAnimation from '../../../../assets/lotties/calendar.json'
import { usePrimaryColor } from '../../../../modules/theme'
import { useAnimatedSvg } from '../../../../utils/animatedSvg'
import { FormCarouselSlide, useFormCarouselContext } from '../../../../components/FormCarousel'
import DateTimeForm from '../../../../components/forms/quotesRequest/DateTimeForm'

const DateTimeStep: React.FC = () => {
  const { handleSubmit, isCurrentSlide } = useFormCarouselContext()
  const primaryColor = usePrimaryColor()

  const [calendar, animation] = useAnimatedSvg(
    replaceColor([250, 55, 44], primaryColor.hex(), calendarAnimation),
    {
      autoplay: false,
      loop: false,
      className: 'w-[120px] mb-4 lg:mb-0 lg:w-[220px]',
    },
  )

  /**
   * play the animation with a delay every time the step is active
   */
  useEffect(() => {
    if (!animation || !isCurrentSlide) {
      return
    }
    animation.setSpeed(1)
    animation.goToAndStop(0)

    animation.goToAndStop(0)
    const timeout = setTimeout(() => {
      animation.goToAndPlay(0)
    }, 350)

    return () => { clearInterval(timeout) }
  }, [animation, isCurrentSlide])

  return (
    <FormCarouselSlide>
      <div className="flex w-full flex-col-reverse items-center justify-around gap-4 lg:flex-row">

        <DateTimeForm onSubmit={handleSubmit} />

        <div>
          { calendar }
        </div>
      </div>
    </FormCarouselSlide>
  )
}

export default DateTimeStep
