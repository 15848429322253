import { mergeClassName } from '../../../utils/mergeClassName'

type ReactLi = React.DetailedHTMLProps<React.LiHTMLAttributes<HTMLLIElement>, HTMLLIElement>

type NumberedListItemProps = {
  number: number
} & ReactLi

const NumberedListItem: React.FC<NumberedListItemProps> = ({
  number,
  className,
  children,
  ...props
}) => {
  return (
    <li
      className={mergeClassName(
        'flex gap-x-3 md:gap-x-4 lg:items-center',
        className,
      )}
      {...props}
    >

      <div className="-ml-2 mt-2 flex size-6 shrink-0 items-center justify-center rounded-full bg-primary font-body2 text-sm font-bold text-white md:ml-0 md:mt-0 md:size-8 md:text-base">
        { number }
      </div>
      <div className="font-sans text-lg leading-6 text-gray-600">
        { children }
      </div>
    </li>
  )
}

export default NumberedListItem
