import FullWidthPage, { type FullWidthPageProps } from '../FullWidthPage'
import Container from '../../Container/Container'

const ContainedPage: React.FC<FullWidthPageProps> = ({
  children,
  ...props
}) => {
  return (
    <FullWidthPage {...props}>
      <Container>
        { children }
      </Container>
    </FullWidthPage>
  )
}

export default ContainedPage
