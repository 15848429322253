import { PaymentGatewayName } from './gateway.enums'
import { type PaymentGateway, type PaymentGatewayOptions } from './gateway.types'
import { useStripeGateway } from './stripe'

export const usePaymentGateway = (options: PaymentGatewayOptions = {}) => {
  const stripeGateway = useStripeGateway(options)

  return (gateway: PaymentGatewayName | string): PaymentGateway => {
    switch (gateway) {
      case PaymentGatewayName.stripe:
        return stripeGateway
      default:
        throw new Error(`Invalid payment gateway : ${gateway}`)
    }
  }
}
