/* eslint-disable react/no-array-index-key */
import { useEffect } from 'react'

import './style.css'
import Wrapper from './Wrapper'
import { mergeClassName } from '../../utils/mergeClassName'

export type CarouselProps = {
  children: React.ReactNode[]
  currentSlide: number
  movementDuration?: number
  opacityDuration?: number
  contentWidth?: number
  className?: string
  wrapperClassName?: string
}

const Carousel: React.FC<CarouselProps> = ({
  children,
  currentSlide,
  movementDuration = 350,
  opacityDuration = 500,
  contentWidth = 1200,
  className,
  wrapperClassName,
}) => {
  /**
   * disable css transition when the screen is resized
   */
  useEffect(() => {
    if (!window || !document) {
      return
    }
    let timeout: NodeJS.Timeout | null = null
    const onResize = () => {
      const bodyClasses = document.body.classList

      if (timeout) {
        clearTimeout(timeout)
        timeout = null
      } else {
        bodyClasses.add('resizing')
      }

      timeout = setTimeout(() => {
        bodyClasses.remove('resizing')
        timeout = null
      }, 500)
    }

    window.addEventListener('resize', onResize)
    return () => {
      window.removeEventListener('resize', onResize)
      if (timeout) {
        clearTimeout(timeout)
        timeout = null
      }
    }
  }, [])

  /**
   * reset scroll when slide is changed
   */
  useEffect(() => {
    const timeout = setTimeout(() => {
      window.scrollTo(0, 0)
      Array.from(document.getElementsByClassName('scrollable'))?.forEach(element => { element.scrollTo(0, 0) })
    }, movementDuration)

    return () => { clearTimeout(timeout) }
  }, [currentSlide, movementDuration])

  return (
    <div className={mergeClassName(
      'w-screen overflow-hidden',
      className,
    )}
    >
      <div
        className="m-auto w-full"
        style={{ maxWidth: contentWidth }}
      >
        <div
          className="transition-spacing flex ease-linear"
          style={{
            marginLeft: `${currentSlide * -100}%`,
            transitionDuration: `${movementDuration}ms`,
          }}
        >
          { children.map((page, index) => (
            <Wrapper
              key={index}
              visible={currentSlide === index}
              opacityDuration={opacityDuration}
              contentWidth={contentWidth}
              className={wrapperClassName}
            >
              { page }
            </Wrapper>
          )) }
        </div>
      </div>
    </div>
  )
}

export default Carousel
