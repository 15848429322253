import { useTranslation } from 'react-i18next'
import { type LazyQueryHookOptions, useLazyQuery } from '@apollo/client'

import { type QueryResult } from '../../graphQl'
import {
  type PreliminaryQuotesRequestPayload,
  type MoveDetails,
  type MoveSizeEstimate,
  QuotePricingDescription,
  type MoveSizeDetails,
} from './quotes.types'
import { estimateMoveSize, estimatePreliminaryMoveSize } from './estimators/moveEstimator'
import * as queries from './quotes.queries'
import { type PreliminaryQuotesResponse } from './quotes.models'
import { pushQuotesRequestedEvent } from '../../analytics'

type FetchQuotesOptions = { reload: boolean } & LazyQueryHookOptions

type UsePreliminaryQuotesHookResult = {
  fetchPreliminaryQuotes: (quotesRequest: PreliminaryQuotesRequestPayload, options?: FetchQuotesOptions) => Promise<PreliminaryQuotesResponse>
} & QueryResult<PreliminaryQuotesResponse>

/**
 * fetch preliminary quotes (lazy query)
 */
export const usePreliminaryQuotes = (): UsePreliminaryQuotesHookResult => {
  const [fetch, { data, networkStatus, loading, error, ...queryProps }] = useLazyQuery(
    queries.getPreliminaryQuotes,
  )
  const fetchPreliminaryQuotes = async (preliminaryQuotesRequest?: PreliminaryQuotesRequestPayload, { reload = false, ...queryArgs } = {}) => {
    const fetchPolicy = reload ? 'network-only' : 'cache-first'
    const { data, error } = await fetch({
      variables: { preliminaryQuotesRequest },
      fetchPolicy,
      ...queryArgs,
    })

    if (error) {
      /* tracking "agent" quotes */

      pushQuotesRequestedEvent({
        nbQuotesReceived: 0,
      })

      throw new Error(error.message)
    }

    const response = data.getPreliminaryQuotes as PreliminaryQuotesResponse

    /* tracking "online" quotes */

    const nbQuotesReceived = response.totalQuotes ?? 0
    const cheapestPriceReceived = response.preliminaryQuotes?.[0]?.price
    const fromCity = response.resolvedOriginAddress?.city
    const fromRegion = response.resolvedOriginAddress?.region
    const toCity = response.resolvedDestinationAddress?.city
    const toRegion = response.resolvedDestinationAddress?.region

    pushQuotesRequestedEvent({
      nbQuotesReceived,
      cheapestPriceReceived,
      fromCity,
      fromRegion,
      toCity,
      toRegion,
    })

    return response
  }

  return {
    fetchPreliminaryQuotes,
    data: data?.getPreliminaryQuotes,
    loading,
    error,
    ...queryProps,
  }
}

/**
 * estimate preliminary move size based move entity
 */
export const usePreliminaryMoveSizeEstimate = (moveDetails: MoveDetails): MoveSizeEstimate => {
  return estimatePreliminaryMoveSize(moveDetails)
}

/**
 * estimate move size based on form input
 */
export const useMoveSizeEstimate = () => {
  return (move: MoveSizeDetails): MoveSizeEstimate => estimateMoveSize(move)
}

/*
 * the "item" value is based on the pricing description
 */
export const useQuoteItemValueFormatter = () => {
  const { t } = useTranslation()

  return (description: QuotePricingDescription, value?: string) => {
    switch (description) {
      case QuotePricingDescription.movingLabour:
      case QuotePricingDescription.packingLabour:
      case QuotePricingDescription.travelLabour:
      case QuotePricingDescription.transportLabour:
      case QuotePricingDescription.additionalChargesStairs:
      {
        const nbMen = parseInt(value ?? '0')
        if (nbMen === 0) {
          return
        }
        return t('quotes.pricing.item.man', { count: nbMen })
      }
      case QuotePricingDescription.travelTruckFeePerKilometer:
      case QuotePricingDescription.transportTruckFeePerKilometer:
      case QuotePricingDescription.additionalChargesOvernight:
      {
        const nbTrucks = parseInt(value ?? '0')
        if (nbTrucks < 2) {
          return
        }
        return t('quotes.pricing.item.truck', { count: nbTrucks })
      }
      case QuotePricingDescription.fixedTruckFee:
      {
        if (!value) {
          return
        }
        return t(`trucks.${value}.title`)
      }
      case QuotePricingDescription.additionalChargesSpecialServices:
      {
        if (!value) {
          return
        }
        return t(`specialServices.${value}.title`)
      }
      case QuotePricingDescription.additionalChargesSpecialItems:
      {
        if (!value) {
          return
        }
        return t(`specialItems.${value}.title`)
      }
      case QuotePricingDescription.protectiveMaterialProduct:
      {
        if (!value) {
          return
        }
        return t(`protectiveMaterial.${value}.title`)
      }
      default:
        return value
    }
  }
}
