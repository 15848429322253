/**
 * this sort an array of string by looking only the numbers in the strings
 *
 * ex:
 * ['from9to10', 'from7to8', 'from8to9']
 * returns
 * ['from7to8', 'from8to9', 'from9to10']
 */
export const sortByNumericValues = <T extends string[]>(values: T): T => {
  return values?.sort((a, b) => {
    const numericValues = [a, b].map(v => parseInt(v.replace(/[a-z]/gi, '')))
    return numericValues[0] - numericValues[1]
  })
}
