import { useRef, useEffect } from 'react'
import MuiModal from '@mui/material/Modal'
import { XMarkIcon } from '@heroicons/react/24/outline'

import ModalBackdrop from './ModalBackdrop'

type ModalProps = {
  open: boolean
  onClose?: () => void
  showCloseIcon?: boolean
  className?: string
  style?: React.CSSProperties
  children?: React.ReactNode
}

const Modal: React.FC<ModalProps> = ({
  open,
  onClose,
  showCloseIcon = true,
  className,
  style,
  children,
}) => {
  const modalContentRef = useRef<HTMLDivElement>(null)
  const onBackdropClick = (event: React.MouseEvent<HTMLElement>) => {
    if (event.target instanceof HTMLElement && event.target.dataset.component === 'backdrop') {
      onClose?.()
    }
  }

  useEffect(() => {
    if (modalContentRef.current) {
      modalContentRef.current.scrollTo(0, 0)
    }
  }, [open])

  return (
    <MuiModal
      open={open}
      onClose={() => onClose?.()}
      hideBackdrop
      className="z-[9999]"
    >
      <div>
        <ModalBackdrop
          onClick={onBackdropClick}
          data-state={open ? 'open' : 'closed'}
        >
          <div className="flex h-screen flex-col items-center justify-center px-[5-vw] py-[5vh]" data-component="backdrop">

            <div
              ref={modalContentRef}
              data-state={open ? 'open' : 'closed'}
              className="fixed grid max-h-[calc(100dvh-2rem)] w-full max-w-[95vw] cursor-default gap-4 overflow-y-auto rounded-b-lg border bg-background py-6 shadow-lg animate-in data-[state=open]:fade-in-90 data-[state=open]:slide-in-from-bottom-10 sm:rounded-lg sm:zoom-in-90 data-[state=open]:sm:slide-in-from-bottom-0 md:max-w-screen-md lg:max-w-screen-lg"
              style={style}
            >
              { showCloseIcon && (
                <div
                  className="absolute right-4 top-4 cursor-pointer opacity-70 transition-opacity hover:opacity-100"
                  data-state={open ? 'open' : 'closed'}
                >
                  <XMarkIcon className="size-6" onClick={onClose} />
                </div>
              ) }

              { children }
            </div>
          </div>
        </ModalBackdrop>
      </div>
    </MuiModal>
  )
}

export default Modal
