import { type KeyValueMap } from '../../../common/keyValueMap'
import { type Place } from '../../../common/place'
import { type Quote } from './quotes.models'

type PlacePayload = {
  address: Place
  nbStairs?: number
  elevatorReservationRequired?: boolean
}

export type PreliminaryQuotesRequestPayload = {
  origin: PlacePayload
  destination: PlacePayload
  movingDate: Date
  flexibleDate?: boolean
  preferredTime?: ArrivalWindow[]
  nbMen: number
  estimatedMovingLabourTime: number
  nbTrucks: Array<{ key: Truck, value: number }>
  specialItems?: Array<{ key: SpecialItem, value: number }> | null
}

export enum MoveContent {
  Boxes = 'boxes',
  Furniture = 'furniture',
  ApplianceAndMore = 'applianceAndMore',
}

export enum ResidenceAndRoomsType {
  Studio = 'studio',
  Apartment1Bedroom = 'apartment1Bedroom',
  Apartment2Bedroom = 'apartment2Bedroom',
  Apartment3Bedroom = 'apartment3Bedroom',
  Apartment4Bedroom = 'apartment4Bedroom',
  House1Bedroom = 'house1Bedroom',
  House2Bedroom = 'house2Bedroom',
  House3Bedroom = 'house3Bedroom',
  House4Bedroom = 'house4Bedroom',
  AssistedLiving = 'assistedLiving',
  OfficeSpace = 'officeSpace',
  StorageUnit5By5 = 'storageUnit5By5',
  StorageUnit5By10 = 'storageUnit5By10',
  StorageUnit10By20 = 'storageUnit10By20',
}

export enum ResidenceType {
  Studio = 'studio',
  Apartment = 'apartment',
  House = 'house',
  AssistedLiving = 'assistedLiving',
  OfficeSpace = 'officeSpace',
  StorageUnit5By5 = 'storageUnit5By5',
  StorageUnit5By10 = 'storageUnit5By10',
  StorageUnit10By20 = 'storageUnit10By20',
}

export type MoveDetails = {
  residenceAndRoomsType: ResidenceAndRoomsType
  moveContent: MoveContent[]
  nbBoxes?: number | null
  furnitureRatio?: number | null
  nbAppliancesAndMore?: number
}

export type MoveSizeDetails = {
  residenceType?: ResidenceType
  nbBoxes?: number | null
  furnitureRatio?: number | null
  residenceRooms?: KeyValueMap<Room, number> | null
  specialItems?: KeyValueMap<SpecialItem, number> | null
}

export type MoveSizeEstimate = {
  volumeCubicFeet: number
  weightPounds: number
  nbMen: number
  estimatedMovingLabourTime: number
  nbTrucks: KeyValueMap<Truck, number>
}

export enum Room {
  Bedroom = 'bedroom',
  LivingRoom = 'livingRoom',
  Kitchen = 'kitchen',
  Bathroom = 'bathroom',
  HomeOffice = 'homeOffice',
  DiningRoom = 'diningRoom',
  Hall = 'hall',
  LaundryRoom = 'laundryRoom',
  HomeGym = 'homeGym',
  Attic = 'attic',
  Garage = 'garage',
  Shed = 'shed',
  Outdoor = 'outdoor',
  StorageRoom = 'storageRoom',
  Workshop = 'workshop',
}

export enum SpecialService {
  Crating = 'crating',
}

export enum SpecialItem {
  Appliance = 'appliance',
  LargeAppliance = 'largeAppliance',
  CommercialFreezer = 'commercialFreezer',
  SportEquipment = 'sportEquipment',
  UprightPiano = 'uprightPiano',
  BabyGrandPiano = 'babyGrandPiano',
  GrandPiano = 'grandPiano',
  PoolTable = 'poolTable',
  MarbleTableTop = 'marbleTableTop',
  HeavyItem200 = 'heavyItem200',
  HeavyItem300 = 'heavyItem300',
  HeavyItem400 = 'heavyItem400',
}

export enum ProtectiveMaterial {
  WardrobeBoxRent = 'wardrobeBoxRent',
  WardrobeBoxBuy = 'wardrobeBoxBuy',
  MattressBagQueen = 'mattressBagQueen',
  MattressBagsKing = 'mattressBagsKing',
  BoxSmall = 'boxSmall',
  BoxMedium = 'boxMedium',
  BoxesLarge = 'boxesLarge',
  ArtBox = 'artBox',
  BookBox = 'bookBox',
  DishBarrel = 'dishBarrel',
  TVBox32in = 'tVBox32in',
  TVBox40in = 'tVBox40in',
  TVBox46in = 'tVBox46in',
  TVBox55in = 'tVBox55in',
  TVBox60in = 'tVBox60in',
  TVBox72in = 'tVBox72in',
  BubbleWrapRoll = 'bubbleWrapRoll',
  BoxLabels = 'boxLabels',
  PackingPaper = 'packingPaper',
  ShrinkWrap = 'shrinkWrap',
  Tape = 'tape',
  PackingPeanuts = 'packingPeanuts',
}

export enum ArrivalWindow {
  From7to8 = 'from7to8',
  From8to9 = 'from8to9',
  From9to10 = 'from9to10',
  From13to15 = 'from13to15',
  From16to19 = 'from16to19',
}

export enum Truck {
  Trucks16ft = 'trucks16ft',
  Trucks20ft = 'trucks20ft',
  Trucks22ft = 'trucks22ft',
  Trucks24ft = 'trucks24ft',
  Trucks26ft = 'trucks26ft',
  Trucks28ft = 'trucks28ft',
  Trucks30ft = 'trucks30ft',
  Trucks36ft = 'trucks36ft',
  Trailers53ft = 'trailers53ft',
}

export enum QuoteConstraint {
  unavailableDate = 'unavailableDate',
  unavailablePreferredTime = 'unavailablePreferredTime',
  notEnoughTrucks = 'notEnoughTrucks',
  doesNotOfferedSingleManService = 'doesNotOfferedSingleManService',
  additionalServicesNotOffered = 'additionalServicesNotOffered',
  overnightNotOffered = 'overnightNotOffered',
  specialServicesNotOffered = 'specialServicesNotOffered',
  specialItemsNotSupported = 'specialItemsNotSupported',
  protectiveMaterialNotOffered = 'protectiveMaterialNotOffered',
}

export enum QuoteUnit {
  hour = 'hour',
  km = 'km',
}

export enum QuotePricingCategory {
  labour = 'labour',
  tripAndTravel = 'tripAndTravel',
  additionalCharges = 'additionalCharges',
  protectiveMaterial = 'protectiveMaterial',
}

export enum QuotePricingDescription {
  movingLabour = 'movingLabour',
  packingLabour = 'packingLabour',
  travelLabour = 'travelLabour',
  transportLabour = 'transportLabour',

  travelTruckFeePerKilometer = 'travelTruckFeePerKilometer',
  transportTruckFeePerKilometer = 'transportTruckFeePerKilometer',
  fixedTruckFee = 'fixedTruckFee',

  additionalChargesOvernight = 'additionalChargesOvernight',
  additionalChargesStairs = 'additionalChargesStairs',
  additionalChargesSpecialServices = 'additionalChargesSpecialServices',
  additionalChargesSpecialItems = 'additionalChargesSpecialItems',

  protectiveMaterialProduct = 'protectiveMaterialProduct',
}

export enum QuotesView {
  best = 'best',
  cheapest = 'cheapest',
}

export type ConstraintsFilter = (quote: Quote) => QuoteConstraint[]

export type QuotesViewHandler = {
  sort: (quotes: Quote[]) => Quote[]
  getConstraints?: ConstraintsFilter
}
