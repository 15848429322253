import MuiCircularProgress, { type CircularProgressProps } from '@mui/material/CircularProgress'
import Box from '@mui/material/Box'

export type SpinnerProps = {
  small?: boolean
  expanded?: boolean
  floating?: boolean
  minHeight?: string | number
  backdrop?: boolean
} & CircularProgressProps

const sizes = {
  regular: { thickness: 2, size: 100 },
  small: { thickness: 7, size: 15 },
}

const CircularProgress = (props: CircularProgressProps) => <MuiCircularProgress {...props} data-testid="spinner" />

const Spinner: React.FC<SpinnerProps> = ({
  small = false,
  expanded = false,
  minHeight,
  floating = false,
  backdrop = false,
  ...props
}) => {
  if (floating && expanded) {
    console.warn('"expanded" cannot be use along with "floating"')
  }
  if (minHeight && !expanded) {
    console.warn('"minHeight" can only be use with "expanded"')
  }
  if (backdrop && !floating) {
    console.warn('"backdrop" can only be use with "floating"')
  }

  const circularProgressSizes = small ? sizes.small : sizes.regular
  const circularProgressProps = { ...circularProgressSizes, ...props }

  if (floating) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        data-testid={backdrop ? 'spinner-floating-with-backdrop' : 'spinner-floating'}
        sx={{
          position: 'fixed',
          left: 0,
          top: 0,
          width: '100vw',
          height: '100dvh',
          overflow: 'hidden',
          background: backdrop ? 'rgba(255, 255, 255, .4)' : undefined,
          zIndex: backdrop ? 999999999 : 1000,
        }}
      >
        <CircularProgress {...circularProgressProps} />
      </Box>
    )
  }

  if (expanded) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        data-testid="spinner-expanded"
        sx={{
          height: '100%',
          minHeight,
          overflow: 'hidden',
        }}
      >
        <CircularProgress {...circularProgressProps} />
      </Box>
    )
  }

  return (
    <CircularProgress {...circularProgressProps} />
  )
}

export default Spinner
