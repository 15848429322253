import { mergeClassName } from '../../utils/mergeClassName'

type ReactDiv = React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>

type PageContentProps = {
  topContent?: React.ReactNode
} & ReactDiv

const PageContent: React.FC<PageContentProps> = ({
  topContent,
  className,
  children,
  ...props
}) => {
  return (
    <div
      className={mergeClassName(
        'flex w-full justify-center',
        className,
      )}
    >
      <div className={mergeClassName(
        'mt-3 sm:mt-6 w-full max-w-[1236px] rounded-3xl bg-white lg:my-12',
      )}
      >
        { topContent && (
          <div className="rounded-t-3xl">
            { topContent }
          </div>
        ) }
        <div className="px-2 py-6 sm:px-4 md:px-6 lg:p-12">
          { children }
        </div>
      </div>
    </div>

  )
}

export default PageContent
