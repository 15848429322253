import Language from './app/Language.enum'
import localeEn from './locales/app.en.json'
import localeFr from './locales/app.fr.json'

const ONE_MINUTE_SEC = 60
const THIRTY_MINUTES_SEC = 30 * ONE_MINUTE_SEC

const ONE_DAY_SEC = 60 * 60 * 24

const TWO_DAYS = 2
const ONE_YEAR_DAYS = 365

const configByEnv = <T>(configs: Record<string, T>, defaultValue?: T) => {
  return configs[env] ?? (defaultValue as T)
}

const nodeEnv = process.env.NODE_ENV ?? 'production'
const defaultAppEnv = nodeEnv === 'production' ? 'production' : 'local'
const env = process.env.REACT_APP_ENV ?? defaultAppEnv
const hostname = window?.location?.hostname ?? 'localhost'
const isProduction = env === 'production'
const isProductionOrStaging = ['production', 'staging'].includes(env)
const isMovingWaldoDomain = /movingwaldo\.com$/.test(hostname)

const config = {
  env,
  isProduction,
  app: {
    url: configByEnv({
      production: 'https://booking.movingwaldo.com',
      staging: 'https://staging.booking.movingwaldo.com',
    }, 'http://localhost:3000'),
  },
  api: {
    url: configByEnv({
      production: 'https://ib-api.movingwaldo.com',
      staging: 'https://staging.ib-api.movingwaldo.com',
    }, 'http://localhost:4000'),
    includeCredentials: true,
  },
  i18n: {
    locales: {
      en: { app: localeEn },
      fr: { app: localeFr },
    },
    defaultLanguage: 'en',
    defaultNs: 'app',
  },
  platforms: {
    blue: {
      theme: 'blueDemo',
      logo: 'blueDemo',
      url: configByEnv({
        staging: 'https://blue.staging.booking.movingwaldo.com',
      }, 'http://blue.local.movingwaldo.com:3000'),
    },
    pillartopost: {
      theme: 'pillartopost',
      logo: 'pillartopost',
      url: configByEnv({
        production: 'https://pillartopost.booking.movingwaldo.com',
        staging: 'https://pillartopost.staging.booking.movingwaldo.com',
      }),
    },
    maxwellrealty: {
      theme: 'maxwellrealty',
      logo: 'maxwellrealty',
      url: configByEnv({
        production: 'https://maxwellrealty.booking.movingwaldo.com',
        staging: 'https://maxwellrealty.staging.booking.movingwaldo.com',
      }),
    },
  },
  cookieyes: {
    enabled: isMovingWaldoDomain,
  },
  facebook: {
    pixelId: '491519291039914',
    debug: !isProduction,
  },
  googleAnalytics: {
    debug: !isProduction,
    measurementId: isProductionOrStaging ? 'G-2GGKNFZRE3' : undefined,
  },
  googleMaps: {
    apiKey: 'AIzaSyCvdu2K9_H9ifL6XzYkVCL4g-trS6Jr9js',
  },
  intercom: {
    appId: 'lxsy1njh',
  },
  calendly: {
    requestMovingQuote: {
      url: {
        [Language.Fr]: 'https://calendly.com/agents-de-reservation/calendrier',
        [Language.En]: 'https://calendly.com/booking-agents/calendar',
      },
    },
    freeConsultation: {
      url: {
        [Language.Fr]: 'https://calendly.com/agents-de-reservation',
        [Language.En]: 'https://calendly.com/booking-agents',
      },
    },
  },
  stripe: {
    publishableKey: 'pk_test_CAn21vTxgmR9NTWMSZcRTAXJ',
  },
  instantBooking: {
    quote: {
      minDateDays: TWO_DAYS,
      maxDateDays: ONE_YEAR_DAYS,
      quoteMaxAge: ONE_DAY_SEC - THIRTY_MINUTES_SEC,
    },
  },
}

export default config
