import React from 'react'
import ReactDOM from 'react-dom/client'
import setupYupGenericLocale from '@concrete-form/core/yup/setupYupGenericLocale'

import './index.css'
import App from './app/App'
import reportWebVitals from './reportWebVitals'

setupYupGenericLocale()

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
)
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
)

reportWebVitals()
