import { type Place } from './place.type'

/**
 * format postal code
 * h0h0h0  =>  H0H 0H0
 */
export const formatPostalCode = (postalCode?: string) => {
  if (!postalCode) {
    return
  }
  if (postalCode.length === 6) {
    return [
      postalCode.substring(0, 3).toUpperCase(),
      postalCode.substring(3).toUpperCase(),
    ].join(' ')
  }
  return postalCode
}

type FormatPlaceOptions = {
  addressOnly?: boolean
  compact?: boolean
}

/**
 * format place (google maps or address)
 */
export const formatPlace = (
  place?: Place,
  {
    addressOnly = false,
    compact = false,
  }: FormatPlaceOptions = {},
) => {
  if (!place) {
    return []
  }
  if (!addressOnly && 'placeId' in place) {
    return [place.placeTextLine1, place.placeTextLine2].filter(line => !!line)
  }
  if ('country' in place) {
    return [
      [
        place.street,
        // eslint-disable-next-line no-irregular-whitespace
        place.apartment ? `(# ${place.apartment})` : undefined,
      ],
      [
        [place.city, place.region].filter(l => !!l).join(', '),
        compact ? formatPostalCode(place.postalCode) : undefined,
      ],
      [
        compact ? undefined : formatPostalCode(place.postalCode),
      ],
    ].map(line => line.filter(d => !!d).join(' ')).filter(l => !!l)
  }
  return []
}
