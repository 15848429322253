import Color from 'color'

import { type ColorParam } from './theme.types'
import {
  colorVariants,
  defaultColorVariant,
  cssVariablePrefix,
  tailwindDefaultColorName,
} from './theme.const'

const withOpacity = (cssVariable: string) => `rgb(var(--${cssVariable}) / <alpha-value>)`

const join = (...items: any[]) => items.filter(i => !!i).join('-')

const formatColor = (color: Color) => color.rgb().array().map(Math.round).join(' ')

const getColorVariant = (color: Color, variant: number) => {
  if (variant < defaultColorVariant) {
    return color.lighten(1 - (variant / defaultColorVariant))
  }
  if (variant > defaultColorVariant) {
    return color.darken((variant / defaultColorVariant) - 1)
  }
  return color
}

export const generateColorVariants = (color: string, prefix?: string) => {
  const defaultKey = prefix ?? tailwindDefaultColorName
  return {
    [defaultKey]: withOpacity(join(cssVariablePrefix, color, prefix, defaultColorVariant)),
    ...Object.fromEntries(colorVariants.map(variant => {
      const tailwindVariableName = join(prefix, variant)
      const cssVariableName = join(cssVariablePrefix, color, prefix, variant)
      return [
        tailwindVariableName,
        withOpacity(cssVariableName),
      ]
    })),
  }
}

export const buildColors = (name: string, color?: ColorParam) => {
  if (!color) {
    return []
  }
  return colorVariants.map(variant => {
    return `--${cssVariablePrefix}-${name}-${variant}: ${formatColor(getColorVariant(Color(color), variant))};`
  })
}

type CssVariables = Record<string, Record<string, string> | string>

const getCssVariableValue = (key: string, variablesObjects: CssVariables[]) => {
  const values = variablesObjects.map(variablesObject => variablesObject[key])
  let merged: Record<string, string> = {}
  for (const value of values) {
    if (typeof value === 'string') {
      return value
    }
    merged = { ...merged, ...value }
  }
  return merged
}

export const mergeCssVariables = (...variablesObjects: CssVariables[]) => {
  const keys = Array.from(new Set(variablesObjects.map(variablesObject => Object.keys(variablesObject)).flat()))
  return Object.fromEntries(keys.map(key => ([key, getCssVariableValue(key, variablesObjects)])))
}
