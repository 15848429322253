/* istanbul ignore file */

import {
  ApolloClient,
  InMemoryCache,
} from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'

// @ts-expect-error ignore type error whit this esm module
import createUploadLink from 'apollo-upload-client/createUploadLink.mjs'
import i18n from 'i18next'

import config from '../../../config'
import { setValidationErrors } from './graphQl.actions'
import store from '../../../store'

const {
  isProduction,
  api,
} = config

/**
 * detect backend errors
 *  - validation errors
 *  - graphQl core error (ex: missing backend validations)
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const errorLink = onError(({ graphQLErrors }) => {
  if (!graphQLErrors) {
    return
  }

  /* detect validation errors and set them in the redux store */

  const fieldsErrors: string[] = []
  graphQLErrors.forEach((graphQLError) => {
    const originalError: any = graphQLError.extensions?.originalError ?? graphQLError.extensions?.response
    const message = originalError?.message
    const fields = originalError?.error
    if (message === 'VALIDATION_ERROR' && fields) {
      fieldsErrors.push(fields.split('|'))
    }
  })
  if (fieldsErrors.length > 0) {
    store.dispatch(setValidationErrors(Array.from(new Set(fieldsErrors.flat()))))
  } else {
    /* detect graphQl core errors */

    const fieldsErrors: string[] = []
    graphQLErrors.forEach((graphQLError) => {
      const code = (graphQLError.extensions as any)?.code
      const fields: string[] = Object.keys((graphQLError.extensions?.exception as any)?.errors || {})
      if (code === 'INTERNAL_SERVER_ERROR' && fields.length > 0) {
        fieldsErrors.push(...fields)
      }
    })
    store.dispatch(setValidationErrors(Array.from(new Set(fieldsErrors.flat()))))
  }
})

/**
 * use current app language as accepted language when calling API
 */
const authLink = setContext((_, { headers }) => {
  store.dispatch(setValidationErrors([]))
  return {
    headers: {
      ...headers,
      'Accept-Language': i18n.language ?? 'en',
    },
  }
})

/**
 * include http only cookies when calling API
 * this also uses a 3rd party library to support file uploads
 */
const uploadLink = createUploadLink({
  uri: `${api?.url}/graphql`,
  credentials: api?.includeCredentials ? 'include' : undefined,
  headers: { 'Apollo-Require-Preflight': 'true' },
})

const client = new ApolloClient({
  // fixme: this doen't handle nested validations (ex: user.email)
  // link: errorLink.concat(authLink.concat(uploadLink)),
  link: authLink.concat(uploadLink),
  cache: new InMemoryCache(),
  connectToDevTools: !isProduction,
})

export default client
