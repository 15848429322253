import { shadCnTailwindConfig, shadCnVariables } from './theme.shadCn'
import { colors, cssVariableDarkName } from './theme.const'
import { generateColorVariants, mergeCssVariables } from './theme.utils'

export const _themeColors = Object.fromEntries(colors.map(color => ([color, {
  ...generateColorVariants(color),
  ...generateColorVariants(color, cssVariableDarkName),
}])))

export const themeColors = mergeCssVariables(_themeColors, shadCnVariables)

export const themeColorsSafeList = colors.map(color => ({
  pattern: new RegExp(`^(bg|text|border)-${color}`),
}))

export const themeExtendedConfig = shadCnTailwindConfig
