import { mergeClassName } from '../../utils/mergeClassName'

type ModalFooterProps = React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>

const ModalFooter: React.FC<ModalFooterProps> = ({
  className,
  children,
  ...props
}) => {
  return (
    <div
      className={mergeClassName(
        'flex-row justify-end space-x-2 flex px-6 border-t md:border-t-0 pt-4 md:pt-0',
        className,
      )}
      {...props}
    >
      { children }
    </div>
  )
}

export default ModalFooter
