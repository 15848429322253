import { useMemo } from 'react'
import { useTranslation, Trans } from 'react-i18next'

import Link, { LinkVariant } from '../../../components/Link'
import { Heading } from '../../../components/typography'
import { useLanguage } from '../../../modules/i18n'
import { type ResolvedAddress } from '../../../modules/quotes'
import Language from '../../../app/Language.enum'

const ReferPartnerLink: React.FC = () => {
  const { t } = useTranslation()
  const language = useLanguage()
  const url = language === Language.En
    ? 'https://www.movingwaldo.com/move-campaign/'
    : 'https://www.movingwaldo.com/fr/campagne-demenagement/'

  return (
    <Link to={url} variant={LinkVariant.Primary} target="_blank">
      { t('pages.quotesRequest.preliminaryQuotes.noResults.referPartnerCta') }
    </Link>
  )
}

type QuotesPreviewNoResultsProps = {
  originAddress?: ResolvedAddress
}

const QuotesPreviewNoResults: React.FC<QuotesPreviewNoResultsProps> = ({ originAddress }) => {
  const { t } = useTranslation()
  const language = useLanguage()

  const appUrl = useMemo(() => {
    const country = originAddress?.country ?? 'CA'

    let url = language === Language.En
      ? 'https://app.movingwaldo.com/en-ca/moving'
      : 'https://app.movingwaldo.com/fr-ca/demenagement'

    if (country.toUpperCase() === 'US') {
      url = 'https://app.movingwaldo.com/en-us/moving'
    }

    const params = new URLSearchParams({
      category: 'moving-company',
    })

    if (originAddress?.postalCode) {
      params.set('postal', originAddress?.postalCode)
    }

    if (originAddress?.country && originAddress?.region) {
      if (['CA', 'US'].includes(originAddress.country.toUpperCase())) {
        params.set('region', originAddress.region.toUpperCase())
      }
    }

    url += `?${params.toString()}`
    return url
  }, [originAddress, language])

  return (
    <div className="py-8 text-center">
      <Heading variant="h1">
        { t('pages.quotesRequest.preliminaryQuotes.noResults.title') }
      </Heading>

      <div className="py-8 text-lg">
        { t('pages.quotesRequest.preliminaryQuotes.noResults.description') }

        <br />

        <Link
          to={appUrl}
          variant={LinkVariant.Underlined}
        >
          { t('pages.quotesRequest.preliminaryQuotes.noResults.seePartners') }
        </Link>

        <br />
        <br />

        <Trans i18nKey="pages.quotesRequest.preliminaryQuotes.noResults.referPartner">
          <ReferPartnerLink />
        </Trans>

      </div>
    </div>
  )
}

export default QuotesPreviewNoResults
