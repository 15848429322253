import { useTranslation } from 'react-i18next'
import BackIcon from '@mui/icons-material/ChevronLeft'
import UserIcon from '@mui/icons-material/PersonOutlineOutlined'
import CalendarIcon from '@mui/icons-material/CalendarMonthOutlined'
import MarkerIcon from '@mui/icons-material/RoomOutlined'
import BoxIcon from '@mui/icons-material/Inventory2Outlined'
// import TruckIcon from '@mui/icons-material/LocalShippingOutlined'

import { PageContent } from '../../../components/Container'
import FullWidthPage from '../../../components/layout/FullWidthPage'
import { Heading, Subtitle } from '../../../components/typography'
import Button, { ButtonColor, ButtonSize } from '../../../components/Button'
import EditableSections from '../../../components/EditableSections/EditableSections'
import { useNavigate } from '../../../modules/navigation'
import Route from '../../../app/Route.enum'
import {
  IdentificationSection,
  DateTimeSection,
  AddressesSection,
  MoveContentSection,
  // MoveRequirementsSection,
} from '../../../components/move/sections'
// import InfoTooltip from '../../../components/InfoTooltip'
import { useSelectedQuote } from '../../../modules/moves'
import Spinner from '../../../components/Spinner'
import { useEffect } from 'react'

const EditMovePage: React.FC = () => {
  const { t } = useTranslation()
  const { navigate } = useNavigate()
  const selectedQuote = useSelectedQuote()

  useEffect(() => {
    if (selectedQuote) {
      navigate(Route.Move)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedQuote])

  if (selectedQuote) {
    return <Spinner floating />
  }

  return (
    <FullWidthPage>
      <PageContent>
        <div className="mb-12 text-center">
          <Heading variant="h1">
            { t('pages.move.edit.title') }
          </Heading>
          <Subtitle className="mb-2 lg:text-center">
            { t('pages.move.edit.subtitle') }
          </Subtitle>
          <Button
            color={ButtonColor.Secondary}
            size={ButtonSize.Small}
            className="!mt-4"
            startIcon={<BackIcon className="mr-[-10px]" />}
            onClick={() => { navigate(Route.Move) }}
          >
            { t('actions.back') }
          </Button>
        </div>

        <EditableSections
          wide
          sections={[
            {
              key: 'user',
              title: t('components.move.identification.title'),
              icon: UserIcon,
              component: IdentificationSection,
            },
            {
              key: 'dateTime',
              title: t('components.move.dateTime.title'),
              icon: CalendarIcon,
              component: DateTimeSection,
            },
            {
              key: 'addresses',
              title: t('components.move.addresses.title'),
              icon: MarkerIcon,
              component: AddressesSection,
            },
            {
              key: 'moveContent',
              title: t('components.move.moveContent.title'),
              icon: BoxIcon,
              component: MoveContentSection,
            },
            // {
            //   key: 'moveRequirements',
            //   title: (
            //     <>
            //       { t('components.move.moveRequirements.title') }
            //       <InfoTooltip
            //         tooltip={(
            //           <>
            //             { t('components.move.moveRequirements.tooltip') }
            //             <br />
            //             <strong>
            //               { t('components.move.moveRequirements.tooltipTime') }
            //             </strong>
            //           </>
            //         )}
            //       />
            //     </>
            //   ),
            //   icon: TruckIcon,
            //   component: MoveRequirementsSection,
            // },
          ]}
        />

        <div className="my-4 text-center">
          <Button
            color={ButtonColor.Secondary}
            size={ButtonSize.Small}
            className="!mt-4"
            startIcon={<BackIcon className="mr-[-10px]" />}
            onClick={() => { navigate(Route.Move) }}
          >
            { t('actions.back') }
          </Button>
        </div>

      </PageContent>
    </FullWidthPage>
  )
}

export default EditMovePage
