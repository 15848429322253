import { useTranslation } from 'react-i18next'
import ErrorIcon from '@mui/icons-material/Warning'

import ContainedPage from '../../components/layout/ContainedPage'
import Link from '../../components/Link'
import Route from '../../app/Route.enum'

const Error404Page: React.FC = () => {
  const { t } = useTranslation()

  return (
    <ContainedPage>
      <div className="mt-64 text-center">
        <ErrorIcon className="!text-[5rem] text-destructive" />
        <br />
        <h1 className="mb-3 font-body2 text-4xl lg:text-6xl">{ t('pages.404.title') }</h1>
        <h2 className="mb-6 whitespace-pre-line font-body2 text-lg lg:text-2xl">{ t('pages.404.subtitle') }</h2>
        <Link to={Route.HomePage} variant="primary">{ t('actions.backToHomepage') }</Link>
      </div>
    </ContainedPage>
  )
}

export default Error404Page
