import LabourIcon from '@mui/icons-material/GroupOutlined'
import TripIcon from '@mui/icons-material/MapOutlined'
import ChargesIcon from '@mui/icons-material/ShoppingCartOutlined'
import ProtectionIcon from '@mui/icons-material/HealthAndSafetyOutlined'

import { QuotePricingCategory } from '../../../modules/quotes'

export type Icon = React.FC<{ className?: string }>

export const categoryIcons: Record<QuotePricingCategory, Icon> = {
  [QuotePricingCategory.labour]: LabourIcon,
  [QuotePricingCategory.tripAndTravel]: TripIcon,
  [QuotePricingCategory.additionalCharges]: ChargesIcon,
  [QuotePricingCategory.protectiveMaterial]: ProtectionIcon,
}
