import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useFormContext, useFormState, useWatch } from 'react-hook-form'
import ReactCountryFlag from 'react-country-flag'

import { Input, Select } from '../../controls'
import { InputSize } from '../../controls/Input'
import { useCountries, useRegions } from '../../../../modules/localization'
import AddressAutoCompleteControl from './AddressAutoCompleteControl'
import { type Address } from '../../../../common/address'

type AddressFieldsProps = {
  name: string
}

const AddressFields: React.FC<AddressFieldsProps> = ({
  name,
}) => {
  const { t } = useTranslation()
  const { unregister, trigger, setValue } = useFormContext()
  const { submitCount } = useFormState()
  const address = useWatch({ name })
  const shouldValidate = submitCount > 0

  const streetName = `${name}.street`
  const apartmentName = `${name}.apartment`
  const cityName = `${name}.city`
  const regionName = `${name}.region`
  const countryName = `${name}.country`
  const postalCodeName = `${name}.postalCode`

  const countries = Object.keys(useCountries())
  const country = address?.country ?? countries[0]
  const regions = useRegions(country)

  const regionLabel = country === 'US'
    ? t('forms.fields.state.label')
    : t('forms.fields.province.label')

  const postalCodeLabel = country === 'US'
    ? t('forms.fields.zipCode.label')
    : t('forms.fields.postalCode.label')

  const countriesOptions = countries.map(country => ({
    value: country,
    label: (
      <div className="flex items-center gap-2">
        <div className="flex size-6 items-center justify-center rounded-full bg-white/90">
          <ReactCountryFlag
            countryCode={country}
            svg
            title={t(`countries.${country}`)}
          />
        </div>
        { t(`countries.${country}`) }
      </div>
    ),
  }))

  const regionsOptions = Object.entries(regions).map(([code, name]) => ({
    label: name,
    value: code,
  }))

  /**
   * when country is updated, make sure the selected region belongs to the country
   */
  useEffect(() => {
    if (!address?.country || !address?.region) {
      return
    }
    if (!Object.keys(regions).includes(address.region)) {
      unregister(regionName)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address?.country, address?.region])

  useEffect(() => {
    if (shouldValidate) {
      trigger(name).catch(() => {})
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address])

  const prefill = (address: Partial<Address>) => {
    setValue(cityName, address.city, { shouldValidate })
    setValue(regionName, address.region, { shouldValidate: true })
    setValue(postalCodeName, address.postalCode, { shouldValidate })
    setValue(countryName, address.country, { shouldValidate: true })
  }

  return (
    <div className="flex flex-col gap-2">
      <div className="flex flex-col gap-2 md:flex-row">
        <AddressAutoCompleteControl
          name={streetName}
          onSelect={prefill}
        />

        <Input
          name={apartmentName}
          placeholder={t('forms.fields.apartment.label')}
          size={InputSize.Small}
          className="md:!w-[150px]"
        />
      </div>
      <div className="flex flex-col gap-2 md:flex-row">
        <Input
          name={cityName}
          placeholder={t('forms.fields.city.label')}
          size={InputSize.Small}
        />

        <Select
          name={regionName}
          placeholder={regionLabel}
          options={regionsOptions}
          size={InputSize.Small}
          allowEmpty
        />

      </div>
      <div className="flex flex-col gap-2 md:flex-row">
        <Select
          name={countryName}
          options={countriesOptions}
          size={InputSize.Small}
        />

        <Input
          name={postalCodeName}
          placeholder={postalCodeLabel}
          size={InputSize.Small}
          className="md:!w-[200px]"
        />
      </div>
    </div>
  )
}

export default AddressFields
