import persistReducer from '../../../store/persistReducer'
import {
  type Actions,
  ACTION_TYPES,
} from './quotes.actions'

export const key = 'quotes'
export const version = 1
export const persistFields = [
  'quotesView',
]

const initialState = {
  quotesView: undefined,
}

const rawReducer = (state = initialState, action: Actions) => {
  switch (action.type) {
    case ACTION_TYPES.SET_QUOTES_VIEW: {
      return {
        ...state,
        quotesView: action.quotesView,
      }
    }
    default:
      return state
  }
}

export const reducer = persistReducer(rawReducer, {
  key,
  version,
  persistFields,
}) as typeof rawReducer
