import { useEffect } from 'react'
import { useForm, useFormState } from 'react-hook-form'

import { type PaymentRequest } from '../../../modules/moves'
import Form, {
  MoveDepositField,
  SubmitButton,
} from '../../../components/Form'

type DepositFormProps = {
  onSubmit: () => Promise<void> | void
  paymentField: React.ReactNode
  paymentRequest: PaymentRequest
  hasValidPaymentField: boolean
}

const DepositForm: React.FC<DepositFormProps> = ({
  onSubmit,
  paymentField,
  paymentRequest,
  hasValidPaymentField = true,
}) => {
  const form = useForm()
  const paymentFieldName = '_payment'
  const { submitCount } = useFormState({ control: form.control })

  /**
   * set form error when there's a payment field is invalid
   */
  useEffect(() => {
    if (submitCount === 0) {
      return
    }
    if (hasValidPaymentField) {
      form.clearErrors(paymentFieldName)
    } else {
      form.setError(paymentFieldName, {})
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasValidPaymentField, submitCount])

  return (
    <Form form={form} onSubmit={onSubmit}>

      <MoveDepositField
        paymentRequest={paymentRequest}
      >
        { paymentField }
      </MoveDepositField>

      <div className="mb-6 mt-8 flex w-full justify-center sm:w-auto xl:mb-0 xl:mt-6">
        <SubmitButton className="w-full sm:w-auto">
          Continue
        </SubmitButton>
      </div>
    </Form>
  )
}

export default DepositForm
