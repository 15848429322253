import Alert from '@mui/material/Alert'

import { useErrorFormatter } from './useErrorFormatter'

type ServerErrorProps = {
  error: any
}

const ServerError: React.FC<ServerErrorProps> = ({ error }) => {
  const formatErrorMessage = useErrorFormatter()

  return (
    <Alert severity="error">
      { formatErrorMessage(error) }
    </Alert>
  )
}

export default ServerError
