import { createContext, useContext } from 'react'

import { type FormCarouselContextType } from './formCarousel.type'

const FormCarouselContext = createContext<FormCarouselContextType | null>(null)

type FormCarouselContextProviderProps = FormCarouselContextType & {
  children?: React.ReactNode
}

export const FormCarouselContextProvider: React.FC<FormCarouselContextProviderProps> = ({
  children,
  ...context
}) => {
  return (
    <FormCarouselContext.Provider value={context}>
      { children }
    </FormCarouselContext.Provider>
  )
}

export const useFormCarouselContext = () => {
  const context = useContext(FormCarouselContext)
  if (!context) {
    throw new Error('Missing Form carousel context. Did you forget to use <FormCarousel/> ?')
  }
  return context
}
