import persistReducer from '../../../store/persistReducer'
import {
  type Actions,
  ACTION_TYPES,
} from './moves.actions'

export const key = 'moves'
export const version = 1
export const persistFields = [
  'moveId',
]

const initialState = {
  moveId: undefined,
  isLoggingOut: false,
}

const rawReducer = (state = initialState, action: Actions) => {
  switch (action.type) {
    case ACTION_TYPES.SET_MOVE_ID: {
      return {
        ...state,
        moveId: action.moveId,
      }
    }
    case ACTION_TYPES.SET_IS_LOGGING_OUT: {
      return {
        ...state,
        isLoggingOut: true,
      }
    }
    default:
      return state
  }
}

export const reducer = persistReducer(rawReducer, {
  key,
  version,
  persistFields,
}) as typeof rawReducer
