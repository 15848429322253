import { useForm, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { yupResolver } from '@hookform/resolvers/yup'
import { addDays } from 'date-fns/addDays'

import { ArrivalWindow } from '../../../../modules/quotes'
import { ButtonSize } from '../../../Button'
import { Heading, Subtitle } from '../../../typography'
import useValidationSchema from './validationSchema'
import { type DateTimeFormValues } from './DateTimeForm.type'
import { useDateFormatter } from '../../../../utils/date'
import InfoTooltip from '../../../InfoTooltip'
import Form, {
  CalendarInput,
  CardCheckboxGroup,
  CardLabel,
  SingleCheckbox,
  SubmitButton,
} from '../../../Form'
import { useConfig } from '../../../../modules/config'

type DateTimeFormProps = {
  onSubmit: (data: DateTimeFormValues) => Promise<void> | void
}

const DateTimeForm: React.FC<DateTimeFormProps> = ({
  onSubmit,
}) => {
  const { t } = useTranslation()
  const schema = useValidationSchema()
  const form = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      flexibleDate: true,
      preferredTime: [
        ArrivalWindow.From8to9,
        ArrivalWindow.From9to10,
        ArrivalWindow.From13to15,
      ],
    },
  })
  const selectedDate = useWatch({ control: form.control, name: 'movingDate' })
  const formatDate = useDateFormatter()
  const minDateDays = useConfig<number>('instantBooking.quote.minDateDays')
  const maxDateDays = useConfig<number>('instantBooking.quote.maxDateDays')

  const now = new Date()

  const preferredTimeOptions = Object.fromEntries(Object.values(ArrivalWindow).map(arrivalWindow => ([
    arrivalWindow,
    <CardLabel
      key={arrivalWindow}
      title={t(`arrivalWindows.${arrivalWindow}.title`)}
    />,
  ])))

  return (
    <Form form={form} onSubmit={onSubmit}>
      <Heading variant="h1" className="text-center lg:text-left">
        { t('forms.quotesRequest.dateTime.title') }
      </Heading>
      <Subtitle>
        { t('forms.quotesRequest.dateTime.subtitle') }
      </Subtitle>

      <div className="my-6 flex flex-col items-center gap-2 md:my-12 lg:flex-row lg:gap-6">

        <div className="w-full">
          <div className="mb-4 lg:min-h-[40px]">
            { selectedDate
              ? (
                <div className="flex flex-col items-center md:flex-row md:gap-12">
                  <div className="font-sans text-2xl">
                    { formatDate(selectedDate) }
                  </div>
                  <div>
                    <SingleCheckbox
                      name="flexibleDate"
                      label={(
                        <>
                          <span className="text-zinc-500">
                            { t('forms.fields.flexibleDate.label') }
                          </span>
                          { ' ' }
                          <InfoTooltip
                            tooltip={t('forms.fields.flexibleDate.tooltip')}
                          />
                        </>
                      )}
                    />
                  </div>
                </div>
                )
              : (
                <div className="font-sans text-2xl text-neutral-500">
                  { t('forms.quotesRequest.dateTime.noDateSelected') }
                </div>
                ) }
          </div>

          <div className="flex flex-col gap-4 md:flex-row">
            <div>
              <CalendarInput
                name="movingDate"
                className="max-w-[400px]"
                wrapperClassName="rounded-xl border border-neutral-300 p-4"
                minDate={addDays(now, minDateDays + 1)}
                maxDate={addDays(now, maxDateDays)}
              />
            </div>

            <CardCheckboxGroup
              name="preferredTime"
              options={preferredTimeOptions}
              className="lg:min-h-[367px] lg:min-w-[280px]"
            />
          </div>
        </div>
      </div>

      <SubmitButton
        className="w-full lg:w-auto"
        size={ButtonSize.Large}
      >
        { t('forms.quotesRequest.nextStepCta', {
          nextStep: t('forms.quotesRequest.steps.moveSize'),
        }) }
      </SubmitButton>
    </Form>
  )
}

export default DateTimeForm
