import { loadStripe } from '@stripe/stripe-js'
import { Elements } from '@stripe/react-stripe-js'

import config from '../../../../config'
import { useLanguage } from '../../../../modules/i18n'
import Language from '../../../../app/Language.enum'

const stripePromise = loadStripe(config.stripe.publishableKey)

type StripeWrapperProps = {
  clientSecret: string
  children?: React.ReactNode
}

const StripeWrapper: React.FC<StripeWrapperProps> = ({
  clientSecret,
  children,
}) => {
  const language = useLanguage()
  const stripeLanguage = language === Language.Fr ? 'fr-CA' : 'en-CA'

  return (
    <Elements
      stripe={stripePromise}
      options={{
        clientSecret,
        locale: stripeLanguage,
        appearance: {
          theme: 'stripe',
          variables: {
            colorDanger: '#d32f2f',
          },
          rules: {
            '.Input': {
              borderColor: '#c4c4c4',
            },
            '.Input:hover': {
              borderColor: '#000',
            },
            '.Input:focus': {
              borderColor: '#000',
              boxShadow: '0px 0px 0px 1px rgb(0, 0, 0)',
            },
          },
        },
      }}
    >
      { children }
    </Elements>
  )
}

export default StripeWrapper
