import { useTranslation } from 'react-i18next'

import { type MoveSizeEstimate } from '../../../../modules/quotes'
import { formatNumber } from '../../../../utils/number'
import InfoTooltip from '../../../InfoTooltip'
import MoveEstimateTooltip from './MoveEstimateTooltip'

type MoveEstimatePreviewProps = {
  moveSizeEstimate: MoveSizeEstimate
}

const MoveEstimatePreview: React.FC<MoveEstimatePreviewProps> = ({
  moveSizeEstimate,
}) => {
  const { t } = useTranslation()

  return (
    <div className="flex items-center">
      <div className="w-full rounded-2xl border border-neutral-100 px-4 py-2 text-center shadow sm:w-auto">

        <div className="mb-1 font-body2 text-sm text-neutral-500">
          { t('pages.quotesRequest.moveEstimatePreview.title') }
          { ': ' }
          <strong>
            { formatNumber(moveSizeEstimate.volumeCubicFeet) }
          </strong>
          { ' ' }
          { t('units.cubicFeet') }
          { ' ' }
          <InfoTooltip
            tooltip={<MoveEstimateTooltip />}
            modal
            modalTitle={t('tooltips.moveSizeEstimate.title')}
            className="-mt-2"
          />
        </div>
      </div>
    </div>
  )
}

export default MoveEstimatePreview
