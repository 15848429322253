import Header from '../Header'
import Footer from '../Footer'
import { mergeClassName } from '../../../utils/mergeClassName'

export type FullWidthPageProps = {
  children?: React.ReactNode
  className?: string
}

const FullWidthPage: React.FC<FullWidthPageProps> = ({
  children,
  className,
}) => {
  return (
    <div className={mergeClassName(
      'flex min-h-screen w-full flex-col items-center bg-neutral-50',
      className,
    )}
    >
      <Header />
      <div className="w-full grow">
        { children }
      </div>
      <Footer />
    </div>
  )
}

export default FullWidthPage
