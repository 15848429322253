import { mergeClassName } from '../../utils/mergeClassName'

type ReactDiv = React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>

const ItemsRow: React.FC<ReactDiv> = ({
  className,
  children,
  ...props
}) => {
  return (
    <div
      className={mergeClassName(
        'flex flex-wrap justify-center gap-2',
        className,
      )}
      {...props}
    >
      { children }
    </div>
  )
}

export default ItemsRow
