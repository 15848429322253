import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useForm, useWatch } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

import { type UpdateMovePayload, useMove } from '../../../../modules/moves'
import {
  ResidenceType,
  Room,
  SpecialItem,
  boxesQuantities,
  furnitureRatio,
  residenceHasRooms,
  useMoveSizeEstimate,
} from '../../../../modules/quotes'
import Form, { QuantityCollectionField, Select } from '../../../Form'
import SubmitBar from '../SubmitBar'
import { Label, Row, Cell, Field } from '../../../data'
import useValidationSchema from './validationSchema'
import { type MoveContentFormValues } from './MoveContentForm.type'
import { formatFormOutput, moveToFormData } from './formDataBuilder'
import InfoTooltip from '../../../InfoTooltip'
import { mergeClassName } from '../../../../utils/mergeClassName'

type MoveContentFormProps = {
  onSubmit: (data: UpdateMovePayload) => Promise<void> | void
  onCancel?: () => void
  wide?: boolean
}

const MoveContentForm: React.FC<MoveContentFormProps> = ({
  onCancel,
  onSubmit,
  wide = false,
}) => {
  const { t } = useTranslation()
  const { data: move } = useMove()

  const schema = useValidationSchema()
  const form = useForm({
    resolver: yupResolver(schema),
    defaultValues: moveToFormData(move),
  })
  const values = useWatch({ control: form.control })
  const residenceType = values.residenceType
  const hasFurniture = (values.furnitureRatio ?? 0) > 0
  const hasRooms = residenceHasRooms(residenceType)
  const estimateMoveSize = useMoveSizeEstimate()

  const handleSubmit = async (data: MoveContentFormValues) => {
    const outputData = formatFormOutput(data)
    const newVolume = estimateMoveSize({
      ...move,
      ...outputData,
    })
    await onSubmit({
      ...outputData,
      ...newVolume,
    })
  }

  const residenceTypeOptions = Object.values(ResidenceType).map((residenceType) => ({
    value: residenceType,
    label: t(`residenceType.${residenceType}.title`),
  }))

  const residenceRoomsOptions = Object.values(Room).map(room => ({
    value: room,
    label: t(`rooms.${room}.title`, { count: 2 }),
  }))

  const nbBoxesOptions = Object.values([0, ...boxesQuantities]).map((nbBoxes) => ({
    value: String(nbBoxes),
    label: t(`nbBoxes.${nbBoxes}.title`),
  }))

  const furnitureRatioOptions = Object.values([0, ...furnitureRatio]).map((furnitureRatio) => ({
    value: String(furnitureRatio),
    label: t(`furnitureRatio.${furnitureRatio}.title`),
  }))

  const specialItemsOptions = Object.values(SpecialItem).map(specialItem => ({
    value: specialItem,
    label: t(`specialItems.${specialItem}.title`, { count: 2 }),
  }))

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    form.trigger()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values])

  return (
    <Form
      form={form}
      onSubmit={handleSubmit}
    >
      <Row className={mergeClassName(
        !wide && 'lg:flex-col',
      )}
      >
        <Cell className={mergeClassName(
          wide && 'lg:mr-4',
        )}
        >
          <Label>
            { t('components.move.moveContent.residenceType') }
          </Label>
          <Field>
            <Select
              name="residenceType"
              options={residenceTypeOptions}
            />
          </Field>

          <Label>
            { t('components.move.moveContent.nbBoxes') }
          </Label>
          <Field>
            <Select
              name="nbBoxes"
              options={nbBoxesOptions}
            />
          </Field>

          <Label>
            { t('components.move.moveContent.furniture') }
            { ' ' }
            <InfoTooltip
              tooltip={t('forms.fields.furnitureRatio.tooltip')}
            />
          </Label>
          <Field>
            <Select
              name="furnitureRatio"
              options={furnitureRatioOptions}
            />
          </Field>
        </Cell>
        <Cell>

          { hasRooms && hasFurniture &&
              (
                <>
                  <Label>{ t('rooms.title') }</Label>
                  <Field>
                    <QuantityCollectionField
                      name="residenceRooms"
                      options={residenceRoomsOptions}
                      small
                    />
                  </Field>
                </>
              ) }

          <Label>{ t('specialItems.title') }</Label>
          <Field>
            <QuantityCollectionField
              name="specialItems"
              options={specialItemsOptions}
              small
            />
          </Field>
        </Cell>
      </Row>

      <SubmitBar onCancel={onCancel} />
    </Form>
  )
}

export default MoveContentForm
