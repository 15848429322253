import * as Yup from 'yup'
import { useTranslation } from 'react-i18next'

import { type MoveSizeFormValues } from './MoveSizeForm.type'

const useValidationSchema = () => {
  const { t } = useTranslation()
  return Yup.object<MoveSizeFormValues>().shape({
    moveContent: Yup.array().min(1, t('formValidation.required')),
  }) as Yup.ObjectSchema<MoveSizeFormValues, MoveSizeFormValues>
}

export default useValidationSchema
