import ReactGa4 from 'react-ga4'
import ReactFacebookPixel from 'react-facebook-pixel'

import { type Amount } from '../../../common/amount'
import config from '../../../config'

const debug = config.googleAnalytics?.debug ?? false

enum GOOGLE_EVENTS {
  page_view,
  bookingApp_quotesRequested,
  bookingApp_moveCreated,
  bookingApp_agentBooked,
  bookingApp_quoteSelected,
  bookingApp_quoteUnselected,
  bookingApp_quoteAccepted,
  bookingApp_PromoCodeAdded,
  bookingApp_PromoCodeRemoved,
}

enum FACEBOOK_EVENTS {
  CompleteRegistration,
  Schedule,
  InitiateCheckout,
  Purchase,
}

type NewMoveParams = {
  nbQuotesReceived: number
  cheapestPriceReceived?: Amount
  fromCity?: string
  fromRegion?: string
  toCity?: string
  toRegion?: string
}

/**
 * push event to Google
 */
const pushGoogleEvent = (eventName: GOOGLE_EVENTS, params?: any) => {
  try {
    const event = GOOGLE_EVENTS[eventName]
    ReactGa4.event(event, params)

    if (debug) {
      console.log(`[TRACKING Google] ${event}`, params)
    }
  } catch (error) {
    /* istanbul ignore next */
    console.error(error)
  }
}

/**
 * push event to Facebook
 */
const pushFacebookEvent = (eventName: FACEBOOK_EVENTS, params?: any) => {
  try {
    const event = FACEBOOK_EVENTS[eventName]
    ReactFacebookPixel.trackCustom(event, params)

    if (debug) {
      console.log(`[TRACKING Facebook] ${event}`, params)
    }
  } catch (error) {
    /* istanbul ignore next */
    console.error(error)
  }
}

export const pushCustomPageView = (url: string) => { pushGoogleEvent(GOOGLE_EVENTS.page_view, { pagePath: url }) }

export const pushQuotesRequestedEvent = ({
  nbQuotesReceived,
  cheapestPriceReceived,
  ...metrics
}: NewMoveParams) => {
  pushGoogleEvent(GOOGLE_EVENTS.bookingApp_quotesRequested, {
    agentRequired: nbQuotesReceived === 0 ? 'yes' : 'no',
    nbQuotesReceived,
    cheapestPriceReceived: cheapestPriceReceived?.price,
    currency: cheapestPriceReceived?.currency,
    ...metrics,
  })
}

export const pushMoveCreatedEvent = ({
  nbQuotesReceived,
  cheapestPriceReceived,
  ...metrics
}: NewMoveParams) => {
  pushGoogleEvent(GOOGLE_EVENTS.bookingApp_moveCreated, {
    agentRequired: nbQuotesReceived === 0 ? 'yes' : 'no',
    nbQuotesReceived,
    cheapestPriceReceived: cheapestPriceReceived?.price,
    currency: cheapestPriceReceived?.currency,
    ...metrics,
  })

  pushFacebookEvent(FACEBOOK_EVENTS.CompleteRegistration)
}

export const pushAgentBookedEvent = () => {
  pushGoogleEvent(GOOGLE_EVENTS.bookingApp_agentBooked)

  pushFacebookEvent(FACEBOOK_EVENTS.Schedule)
}

type QuotesSelectedEventParams = {
  companyId: string
  companyName: string
  price: Amount
  companyCity?: string
  position: number
}

export const pushQuoteSelectedEvent = ({
  price,
  ...metrics
}: QuotesSelectedEventParams) => {
  pushGoogleEvent(GOOGLE_EVENTS.bookingApp_quoteSelected, {
    price: price.price,
    currency: price.currency,
    ...metrics,
  })
  pushFacebookEvent(FACEBOOK_EVENTS.InitiateCheckout)
}

export const pushQuoteUnselectedEvent = () => {
  pushGoogleEvent(GOOGLE_EVENTS.bookingApp_quoteUnselected)
}

type QuotesAcceptedEventParams = {
  companyId: string
  companyName: string
  price: Amount
  companyCity?: string
  promoCode?: string
}

export const pushQuoteAcceptedEvent = ({
  price,
  ...metrics
}: QuotesAcceptedEventParams) => {
  pushGoogleEvent(GOOGLE_EVENTS.bookingApp_quoteAccepted, {
    price: price.price,
    currency: price.currency,
    ...metrics,
  })

  pushFacebookEvent(FACEBOOK_EVENTS.Purchase, {
    value: price.price,
    currency: price.currency,
  })
}

export const pushPromoCodeAddedEvent = (code: string) => {
  pushGoogleEvent(GOOGLE_EVENTS.bookingApp_PromoCodeAdded, {
    code,
  })
}

export const pushPromoCodeRemovedEvent = (code: string) => {
  pushGoogleEvent(GOOGLE_EVENTS.bookingApp_PromoCodeRemoved, {
    code,
  })
}
