import { mergeClassName } from '../../utils/mergeClassName'

type WrapperProps = {
  children: React.ReactNode
  visible: boolean
  opacityDuration: number
  contentWidth: number
  className?: string
}

const Wrapper: React.FC<WrapperProps> = ({
  children,
  visible,
  opacityDuration,
  contentWidth,
  className,
}) => (
  <div
    className={mergeClassName(
      'scrollable shrink-0 w-screen duration-0 overflow-y-auto',
      !visible && 'opacity-0 transition-opacity ease-in-out pointer-events-none',
      className,
    )}
    style={{
      transitionDuration: `${opacityDuration}ms`,
      maxWidth: contentWidth,
    }}
  >
    { children }
  </div>
)

export default Wrapper
