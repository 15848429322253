import { mergeClassName } from '../../../utils/mergeClassName'

type ReactOl = React.DetailedHTMLProps<React.OlHTMLAttributes<HTMLOListElement>, HTMLOListElement>

const NumberedList: React.FC<ReactOl> = ({
  className,
  children,
  ...props
}) => {
  return (
    <ol
      className={mergeClassName(
        'flex flex-col gap-y-6 lg:ml-6',
        className,
      )}
      {...props}
    >
      { children }
    </ol>
  )
}

export default NumberedList
