import CfSubmitButton, { type SubmitButtonProps as CfSubmitButtonProps } from '@concrete-form/mui/SubmitButton'

import { type ButtonProps, getMuiButtonProps } from '../../../Button'

type SubmitButtonProps = Omit<CfSubmitButtonProps, 'variant' | 'color' | 'size'> & ButtonProps

const SubmitButton: React.FC<SubmitButtonProps> = (buttonProps) => {
  return (
    <CfSubmitButton {...getMuiButtonProps(buttonProps)} />
  )
}

export default SubmitButton
