type MobileStepProps = {
  step: React.ReactNode
  children?: React.ReactNode
}

const MobileStep: React.FC<MobileStepProps> = ({
  step,
  children,
}) => {
  return (
    <div className="flex items-center gap-4 rounded-md bg-gray-200/60 p-4 xl:hidden">
      <span
        className="inline-flex size-[30px] shrink-0 items-center justify-center rounded-full bg-secondary font-body2 text-lg text-white"
      >
        { step }
      </span>
      <span className="font-sans">
        { children }
      </span>
    </div>
  )
}

export default MobileStep
