import { useTranslation } from 'react-i18next'
import Language from '../../app/Language.enum'
import Calendly from '../../components/Calendly'
import { PageContent } from '../../components/Container'
import FullWidthPage from '../../components/layout/FullWidthPage'
import { Heading } from '../../components/typography'
import { useConfig } from '../../modules/config'
import { useLanguage } from '../../modules/i18n'

const MoveCalendly: React.FC = () => {
  const { t } = useTranslation()
  const language = useLanguage() ?? Language.En
  const calendlyUrl = useConfig(`calendly.freeConsultation.url.${language}`)

  return (
    <FullWidthPage>
      <PageContent>

        <div className="px-2 pt-6 text-center">
          <Heading variant="h1">
            { t('pages.freeConsultation.title') }
          </Heading>

          <div className="mt-4">
            <Calendly
              calendlyUrl={calendlyUrl}
            />
          </div>
        </div>

      </PageContent>
    </FullWidthPage>
  )
}

export default MoveCalendly
