import CfInput from '@concrete-form/mui/Input'

import { getMuiTextfieldProps } from './getMuiTextfieldProps'

export enum InputSize {
  Small = 'small',
  Medium = 'medium',
}

export type InputProps = {
  name: string
  size?: InputSize
  width?: number
  label?: string
  placeholder?: string
  startNode?: React.ReactNode
  endNode?: React.ReactNode
  type?: string
  center?: boolean
  maxLength?: number
  className?: string
}

const Input: React.FC<InputProps> = ({
  name,
  ...inputProps
}) => {
  return (
    <CfInput
      name={name}
      {...getMuiTextfieldProps(inputProps)}
    />
  )
}

export default Input
