import { useTranslation } from 'react-i18next'
import ErrorIcon from '@mui/icons-material/Warning'

import ContainedPage from '../../components/layout/ContainedPage'

const Error403Page: React.FC = () => {
  const { t } = useTranslation()

  return (
    <ContainedPage>
      <div className="mt-64 text-center">
        <ErrorIcon className="!text-[5rem] text-destructive" />
        <br />
        <h1 className="mb-3 font-body2 text-4xl lg:text-6xl">{ t('pages.403.title') }</h1>
        <h2 className="whitespace-pre-line font-body2 text-lg lg:text-2xl">{ t('pages.403.subtitle') }</h2>
      </div>
    </ContainedPage>
  )
}

export default Error403Page
