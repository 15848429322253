import defaultTailwindConfig from 'tailwindcss/defaultConfig'
import { useMediaQuery } from 'react-responsive'
import { useDispatch, useSelector } from 'react-redux'
import Color from 'color'

import themes from '../themes'
import * as selectors from './theme.selectors'
import * as actions from './theme.actions'
import { usePlatform } from '../../whiteLabel'

/**
 * get theme colors based on current domain
 */
export const useTheme = () => {
  const platform = usePlatform()
  if (!platform) {
    return themes.movingwaldo
  }
  return themes[platform.theme as keyof typeof themes] ?? themes.movingwaldo
}

/**
 * get theme logo based on current domain
 */
export const useThemeLogo = () => {
  const platform = usePlatform()
  return platform?.logo
}

/**
 * use a color by variable name
 */
export const useColor = (variableName: string) => {
  const theme = useTheme()
  const { darkModeEnabled } = useThemeState()
  const colorValues = theme[variableName as keyof typeof theme]
  if (!colorValues) {
    return new Color()
  }
  const color = darkModeEnabled ? colorValues.dark ?? colorValues.light : colorValues.light
  return new Color(color)
}

/**
 * return primary color
 */
export const usePrimaryColor = () => {
  return useColor('primary')
}

/**
 * return secondary color
 */
export const useSecondaryColor = () => {
  return useColor('secondary')
}

/**
 * returns theme state (dark/light mode)
 */
export const useThemeState = () => {
  return {
    darkModeEnabled: useSelector(selectors.darkModeEnabled),
  }
}

/**
 * action to switch between light and dark theme
 */
export const useThemeDarkModeSwitcherAction = () => {
  const dispatch = useDispatch()
  const { darkModeEnabled } = useThemeState()
  return {
    switchDarkMode: () => dispatch(actions.setDarkMode(!darkModeEnabled)),
  }
}

/**
 * use breakpoints
 * returns a boolean if we're matching tailwind breakpoint
 */
export const useBreakpoint = (breakpointKey: string) => {
  const breakpoints = (defaultTailwindConfig.theme?.screens as Record<string, string>) ?? {}
  return useMediaQuery({
    query: breakpoints ? `(min-width: ${breakpoints[breakpointKey]})` : '',
  })
}
