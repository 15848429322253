import FullWidthPage from '../FullWidthPage'

type SplittedPageProps = {
  children: [React.ReactNode, React.ReactNode]
}

const SplittedPage: React.FC<SplittedPageProps> = ({
  children,
}) => {
  return (
    <FullWidthPage className="bg-white">
      <div className="flex flex-col bg-white xl:min-h-[calc(100dvh-111px)] xl:flex-row">

        <div className="flex flex-1 shrink-0 justify-center border-t p-4 xl:justify-end xl:border-r xl:border-t-0 xl:p-8">
          <div className="flex w-full max-w-[900px] xl:max-w-[750px]">
            { children[0] }
          </div>
        </div>

        <div className="mr-px flex flex-1 shrink-0 justify-center bg-neutral-50 p-4 xl:justify-start xl:p-8">
          <div className="flex w-full max-w-[900px] xl:max-w-[750px]">
            { children[1] }
          </div>
        </div>

      </div>
    </FullWidthPage>
  )
}

export default SplittedPage
