import MuiButton, { type ButtonProps as MuiButtonProps } from '@mui/material/Button'
import MuiIconButton from '@mui/material/IconButton'

import { getMuiButtonProps } from './getMuiButtonProps'

export enum ButtonVariant {
  Transparent = '',
  Filled = 'filled',
  Outlined = 'outlined',
}

export enum ButtonColor {
  Primary = 'primary',
  Secondary = 'secondary',
}

export enum ButtonSize {
  Small = 'small',
  Medium = 'medium',
  Large = 'large',
}

export type ButtonProps = {
  variant?: ButtonVariant
  color?: ButtonColor
  size?: ButtonSize
  contentClassName?: string
  iconButton?: boolean
} & Omit<MuiButtonProps, 'variant' | 'color' | 'size'>

const Button: React.FC<ButtonProps> = ({ iconButton = false, ...buttonProps }) => {
  const ButtonComponent: React.FC = iconButton ? MuiIconButton : MuiButton
  return (
    <ButtonComponent {...getMuiButtonProps(buttonProps)} />
  )
}

export default Button
