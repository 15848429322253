import { mergeClassName } from '../../utils/mergeClassName'

type ReactDiv = React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>

const Container: React.FC<ReactDiv> = ({
  children,
  className,
}) => {
  return (
    <div className={mergeClassName(
      'relative m-auto w-full max-w-[1600px] px-3 sm:px-5 lg:px-6 grow',
      className,
    )}
    >
      { children }
    </div>
  )
}

export default Container
