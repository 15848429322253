import { type QuotesView } from './quotes.types'

export enum ACTION_TYPES {
  SET_QUOTES_VIEW = 'SET_QUOTES_VIEW',
}

export const setQuotesView = (quotesView: QuotesView) => ({
  type: ACTION_TYPES.SET_QUOTES_VIEW as const,
  quotesView,
})

export type Actions = ReturnType<typeof setQuotesView>
