import { set } from 'date-fns/set'
import { fromZonedTime } from 'date-fns-tz'

import { recordToKeyValueMap } from '../../../../common/keyValueMap'
import { type RegisterFormValues } from '../../../../components/forms/RegisterForm/RegisterForm.type'
import { type CreateMovePayload } from '../../../../modules/moves'
import {
  MoveContent,
  type PreliminaryQuotesRequestPayload,
  type Room,
  SpecialItem,
  residenceTypeFromRooms,
  roomsByResidenceMatrix,
} from '../../../../modules/quotes'
import { type QuotesRequestFormValues } from '../QuotesRequestPage.type'

export const formatPreliminaryQuotesRequest = (formValues: QuotesRequestFormValues): PreliminaryQuotesRequestPayload => {
  const nbAppliances = formValues.moveSize.nbAppliancesAndMore ?? 0
  const nbTrucks = formValues.moveSize.nbTrucks

  /* moving date is expected to be midnight UTC */
  const movingDate = fromZonedTime(set(formValues.dateTime.movingDate, {
    hours: 0,
    minutes: 0,
    seconds: 0,
    milliseconds: 0,
  }), 'UTC')

  return {
    ...formValues.addresses,
    ...formValues.dateTime,
    movingDate,
    nbMen: formValues.moveSize.nbMen,
    estimatedMovingLabourTime: formValues.moveSize.estimatedMovingLabourTime,
    specialItems: nbAppliances > 0
      ? [
          {
            key: SpecialItem.Appliance,
            value: nbAppliances,
          },
        ]
      : undefined,
    nbTrucks,
  }
}

type FormatMoveRequestOptions = {
  requestFormValues: QuotesRequestFormValues
  userFormValues: RegisterFormValues
  queryString?: Record<string, string>
}

export const formatMoveRequest = ({
  requestFormValues,
  userFormValues,
  queryString,
}: FormatMoveRequestOptions): CreateMovePayload => {
  const moveSize = requestFormValues.moveSize

  const {
    utm_source: utmSource,
    utm_medium: utmMedium,
    utm_campaign: utmCampaign,
  } = queryString ?? {}

  const movingFurniture = requestFormValues.moveSize.moveContent.includes(MoveContent.Furniture)
  const residenceRooms = movingFurniture ? roomsByResidenceMatrix[moveSize.residenceAndRoomsType] : undefined

  return {
    ...formatPreliminaryQuotesRequest(requestFormValues),
    user: userFormValues,
    nbBoxes: moveSize.nbBoxes,
    furnitureRatio: moveSize.furnitureRatio,
    residenceType: residenceTypeFromRooms[moveSize.residenceAndRoomsType],
    residenceRooms: residenceRooms ? recordToKeyValueMap<Room, number>(residenceRooms) : undefined,
    volumeCubicFeet: moveSize.volumeCubicFeet,
    weightPounds: moveSize.weightPounds,
    utmSource,
    utmMedium,
    utmCampaign,
  }
}
