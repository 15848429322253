import Language from '../../app/Language.enum'
import { type Translation } from './Translation.type'
import { useTranslation } from 'react-i18next'

/**
 * this returns the current version of "Translation" based on selected language
 */
export const useTranslatedValue = () => {
  const { i18n } = useTranslation()

  return (data?: Translation, forceLanguage?: Language) => {
    if (forceLanguage) {
      return data?.[forceLanguage]
    }
    const language = (i18n.language as Language) ?? Language.En
    return data?.[language] ? data?.[language] : data?.[Language.En]
  }
}
