import { useDispatch, useSelector } from 'react-redux'

import { type ConsentCategory } from './consent.types'
import * as selectors from './consent.selectors'
import * as actions from './consent.actions'

/**
 * returns true if the given category is accepted
 */
export const useConsent = (category: ConsentCategory) => {
  const categories = useSelector(selectors.accepted)
  return categories.includes(category)
}

/**
 * return all accepted categories
 */
export const useConsentCategories = () => {
  return useSelector(selectors.accepted)
}

/**
 * returns a callback to updated the accepted categories
 */
export const useUpdateConsentAction = () => {
  const dispatch = useDispatch()
  const actual = useConsentCategories()

  return (categories: ConsentCategory[]) => {
    if (JSON.stringify(categories) === JSON.stringify(actual)) {
      return
    }
    dispatch(actions.setConsentAccepted(categories))
  }
}
