import { useEffect } from 'react'
import { replaceColor } from 'lottie-colorify'

import mapAnimation from '../../../../assets/lotties/map.json'
import { usePrimaryColor } from '../../../../modules/theme'
import { useAnimatedSvg } from '../../../../utils/animatedSvg'
import { FormCarouselSlide, useFormCarouselContext } from '../../../../components/FormCarousel'
import AddressesForm from '../../../../components/forms/quotesRequest/AddressesForm'

const AddressesStep: React.FC = () => {
  const { handleSubmit, isCurrentSlide } = useFormCarouselContext()
  const primaryColor = usePrimaryColor()

  const [map, animation] = useAnimatedSvg(
    replaceColor([250, 55, 44], primaryColor.hex(), mapAnimation),
    {
      autoplay: false,
      loop: false,
      className: 'w-[120px] mb-2 lg:mb-0 md:w-[180px]',
    },
  )

  /**
   * play the animation with a delay every time the step is active
   */
  useEffect(() => {
    if (!animation || !isCurrentSlide) {
      return
    }
    animation.setSpeed(1)
    animation.goToAndStop(0)

    const timeout = setTimeout(() => {
      animation.goToAndPlay(0)
    }, 350)

    return () => { clearInterval(timeout) }
  }, [animation, isCurrentSlide])

  return (
    <FormCarouselSlide>
      <div className="flex w-full flex-col items-center lg:gap-8">
        <div>
          { map }
        </div>

        <div className="w-full grow">
          <AddressesForm onSubmit={handleSubmit} />
        </div>
      </div>
    </FormCarouselSlide>
  )
}

export default AddressesStep
