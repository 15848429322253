import { useMove, useSelectedQuote } from '../../../modules/moves'
import PersonnalizedMoveSizePage from '../PersonnalizeMoveSizePage.tsx/PersonnalizedMoveSizePage'
import QuotesPage from '../QuotesPage'
import CheckoutPage from '../CheckoutPage/CheckoutPage'
import QuoteAcceptedPage from '../QuoteAcceptedPage'
import PendingDepositPage from '../PendingDepositPage'

/**
 * this dispatchs to the proper page based on the move status
 * at this point, we know the move is active
 */
const MoveDispatcherPage: React.FC = () => {
  const { data: move } = useMove()
  const selectedQuote = useSelectedQuote()
  const needDeposit = !!move.paymentProfile?.depositRequest
  const hasDeposit = !!move.deposit

  if (move.specialItemsPrefilled) {
    return <PersonnalizedMoveSizePage />
  }

  if (move.quoteAccepted && needDeposit && !hasDeposit) {
    return <PendingDepositPage />
  }

  if (move.quoteAccepted) {
    return <QuoteAcceptedPage />
  }

  if (selectedQuote) {
    return <CheckoutPage />
  }

  return (
    <QuotesPage />
  )
}

export default MoveDispatcherPage
