import { useEffect, useState } from 'react'
import { IntercomProvider, useIntercom } from 'react-use-intercom'

import { useMove } from '../modules/moves'
import { useLanguage } from '../modules/i18n'
import { formatPhoneNumber } from '../common/phoneNumber'
import { useConfig } from '../modules/config'
import { usePrimaryColor } from '../modules/theme'

type IntercomInitializerProps = {
  children: React.ReactNode
}

const IntercomInitializer: React.FC<IntercomInitializerProps> = ({ children }) => {
  const language = useLanguage()
  const [loaded, setLoaded] = useState(false)
  const [loadedLanguage, setLoadedLanguage] = useState<string | undefined>()
  const move = useMove()
  const intercom = useIntercom()
  const primaryColor = usePrimaryColor()

  /**
   * initialize intercom and update it when move changes
   */
  useEffect(() => {
    if (move.loading) {
      return
    }

    const payload = move.data
      ? {
          languageOverride: language,
          name: `${move.data.user.firstName} ${move.data.user.lastName}`,
          email: move.data.user.email,
          phone: formatPhoneNumber(move.data.user.phoneNumber),
          customAttributes: {
            moveId: move.data.id,
          },
        }
      : {
          languageOverride: language,
        }

    if (!loaded) {
      intercom.boot({
        ...payload,
        actionColor: primaryColor.hex(),
        backgroundColor: primaryColor.hex(),
      })
    } else {
      intercom.update(payload)
      setLoaded(true)
      setLoadedLanguage(language)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [move])

  /**
   * sync intercom language
   */
  useEffect(() => {
    if (loadedLanguage && loadedLanguage !== language) {
      intercom.update({
        languageOverride: language,
      })
      setLoadedLanguage(language)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language, loadedLanguage])

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{ children }</>
}

type IntercomWrapperProps = {
  children: React.ReactNode
}

const IntercomWrapper: React.FC<IntercomWrapperProps> = ({ children }) => {
  const intercomAppId = useConfig('intercom.appId')

  if (!intercomAppId) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{ children }</>
  }

  return (
    <IntercomProvider
      appId={intercomAppId}
    >
      <IntercomInitializer>
        { children }
      </IntercomInitializer>
    </IntercomProvider>
  )
}

export default IntercomWrapper
