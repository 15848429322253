import { keyValueMapToRecord, recordToKeyValueMap } from '../../../common/keyValueMap'
import { type Move, type UpdateMovePayload } from '../../../modules/moves'
import { type SpecialItemsFormValues } from './SpecialItemsForm.type'

export const moveToFormData = (move: Move): SpecialItemsFormValues => {
  const specialItems = move.specialItems ? keyValueMapToRecord(move.specialItems) : []

  return {
    specialItems,
    specialItemsTotal: specialItems.length,
  }
}

const removeZeroValues = <T extends Record<string, number>>(values: T): T => {
  return Object.fromEntries(
    Object.entries(values)
      .map(([key, value]) => ([key, parseInt(String(value))]))
      .filter(([key, value]) => {
        return !!value
      }),
  ) as T
}

export const formatFormOutput = (values: SpecialItemsFormValues): UpdateMovePayload => ({
  specialItems: recordToKeyValueMap(removeZeroValues(values.specialItems)),
})
