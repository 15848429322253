/* eslint-disable @typescript-eslint/no-misused-promises */
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import ShoppingCartIcon from '@mui/icons-material/ShoppingCartOutlined'

import { type Quote } from '../../../../modules/quotes'
import { useSelectQuoteAction } from '../../../../modules/moves'
import { mergeClassName } from '../../../../utils/mergeClassName'
import Button, {
  ButtonSize,
  ButtonVariant,
} from '../../../Button'
import Spinner from '../../../Spinner'
import { errorNotification } from '../../../ToastNotifications'
import { useErrorFormatter } from '../../../errors/useErrorFormatter'
import QuoteHeader from './QuoteHeader'
import QuoteContent from './QuoteContent'

type QuoteComponentProps = {
  quote: Quote
  best?: boolean
}

const QuoteComponent: React.FC<QuoteComponentProps> = ({
  quote,
  best = false,
}) => {
  const { t } = useTranslation()
  const [open, setOpen] = useState(best)
  const { selectQuote, loading: selectingQuote } = useSelectQuoteAction()
  const formatError = useErrorFormatter()

  const onClick = () => {
    if (open) {
      close(); return
    }
    setOpen(true)
  }

  const close = () => {
    setOpen(false)
  }

  const onSelectQuote = async (event: React.MouseEvent) => {
    event.stopPropagation()
    try {
      await selectQuote(quote)
    } catch (error) {
      errorNotification(formatError(error))
    }
  }

  return (
    <>
      { selectingQuote && (
        <Spinner floating backdrop />
      ) }

      <div
        className={mergeClassName(
          'bg-white relative grow cursor-pointer rounded-md border shadow transition-all duration-200 hover:scale-[1.01] hover:shadow-xl',
          open && 'scale-[1.01] shadow-xl border-2 border-gray-300',
          best && 'border-2 border-gray-600',
        )}
        onClick={onClick}
      >

        { best && (
          <div className="absolute -left-1 -top-1 size-16 overflow-hidden">
            <div className="absolute left-[-25px] top-[10px] w-[100px] -rotate-45 bg-gray-600 py-1 text-center text-xs text-white">
              { t('quotes.quote.best') }
            </div>
          </div>
        ) }

        <QuoteHeader
          quote={quote}
          open={open}
          best={best}
        />

        { open && (
          <QuoteContent quote={quote} />
        ) }

        <div className={mergeClassName(
          'mb-2 md:mb-0 md:bg-gradient-to-tr from-white via-zinc-50/10 to-zinc-200/20',
          'py-3 px-4 rounded-b-md flex justify-center md:justify-end md:mt-0',
          !open && 'mt-6',
        )}
        >
          <Button
            variant={open ? ButtonVariant.Filled : ButtonVariant.Outlined}
            size={ButtonSize.Small}
            className={mergeClassName(
              'w-full md:w-auto max-w-[300px]',
              !open && '!bg-white',
            )}
            onClick={onSelectQuote}
          >
            <ShoppingCartIcon className="mr-[5px] mt-[-5px] !text-[18px]" />
            { t('pages.move.quotes.selectQuote') }
          </Button>
        </div>
      </div>
    </>
  )
}

export default QuoteComponent
