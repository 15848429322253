import { type Move, type UpdateMovePayload } from '../../../../modules/moves'
import { type IdentificationFormValues } from './IdentificationForm.type'

export const moveToFormData = (move: Move): IdentificationFormValues => {
  return move.user
}

export const formatFormOutput = (values: IdentificationFormValues): Partial<UpdateMovePayload> => {
  return {
    user: values,
  }
}
