import { mergeClassName } from '../../utils/mergeClassName'

type ReactDiv = React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>

const Row: React.FC<ReactDiv> = ({
  className,
  children,
  ...props
}) => {
  return (
    <div
      className={mergeClassName(
        'flex flex-col lg:flex-row gap-x-2 gap-y-4 lg:gap-y-6 my-2 lg:my-4',
        className,
      )}
      {...props}
    >
      { children }
    </div>
  )
}

export default Row
