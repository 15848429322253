import { useTranslation } from 'react-i18next'

import { SubmitButton } from '../../Form'
import Button, { ButtonColor, ButtonVariant } from '../../Button'

type SubmitBarProps = {
  onCancel?: () => void
}

const SubmitBar: React.FC<SubmitBarProps> = ({
  onCancel,
}) => {
  const { t } = useTranslation()

  return (
    <div className="flex w-full justify-end gap-2 p-4">
      { onCancel && (
        <Button
          color={ButtonColor.Secondary}
          variant={ButtonVariant.Outlined}
          onClick={onCancel}
        >
          { t('actions.cancel') }
        </Button>
      ) }

      <SubmitButton>
        { t('actions.save') }
      </SubmitButton>
    </div>
  )
}

export default SubmitBar
