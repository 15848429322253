import { useTranslation } from 'react-i18next'

const MoveEstimateTooltip: React.FC = () => {
  const { t } = useTranslation()

  return (
    <>
      <p>
        { t('tooltips.moveSizeEstimate.howItsCalculated') }
      </p>

      <p>
        { t('tooltips.moveSizeEstimate.onlyAnEstimate') }
      </p>
    </>
  )
}

export default MoveEstimateTooltip
