import { useTranslation } from 'react-i18next'

import { IntegerInput } from '../../../Form'
import InfoTooltip from '../../../InfoTooltip'

type NbStairsControlProps = {
  name: string
}

const NbStairsControl: React.FC<NbStairsControlProps> = ({
  name,
}) => {
  const { t } = useTranslation()

  return (
    <div className="mt-4 flex flex-wrap items-center gap-2 lg:justify-between">
      <div className="font-sans text-zinc-500 lg:text-left">
        { t('forms.fields.howManyStairs.label') }
        { ' ' }
        <InfoTooltip
          tooltip={(
            <>
              <h2>
                { t('forms.fields.howManyStairs.tooltip.title') }
              </h2>
              <ul>
                <li>
                  { t('forms.fields.howManyStairs.tooltip.threeStepsOrMore') }
                </li>
                <li>
                  { t('forms.fields.howManyStairs.tooltip.connectTwoLevels') }
                </li>
                <li>
                  { t('forms.fields.howManyStairs.tooltip.ignoreInterruptions') }
                </li>
                <li>
                  { t('forms.fields.howManyStairs.tooltip.excludeBasement') }
                </li>
              </ul>
            </>
          )}
        />
      </div>
      <IntegerInput
        name={name}
        min={0}
        max={9}
      />
    </div>
  )
}

export default NbStairsControl
