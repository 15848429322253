import { type EventScheduledEvent, InlineWidget, useCalendlyEventListener } from 'react-calendly'

import { usePrimaryColor } from '../../modules/theme'
import { type MoveUser } from '../../modules/moves'
import Spinner from '../Spinner'

type CalendlyProps = {
  calendlyUrl: string
  user?: MoveUser
  moveId?: string
  onEventScheduled?: (uri: string) => Promise<void> | void
  hideLandingPageDetails?: boolean
}

const Calendly: React.FC<CalendlyProps> = ({
  calendlyUrl,
  user,
  moveId,
  onEventScheduled,
  hideLandingPageDetails = false,
}) => {
  const primaryColor = usePrimaryColor().hex()
  useCalendlyEventListener({
    onEventScheduled: (event: EventScheduledEvent) => {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      onEventScheduled?.(event.data.payload.invitee.uri)
    },
  })

  return (
    <div className="h-[calc(100dvh-200px)] lg:max-h-[900px]">
      <InlineWidget
        url={calendlyUrl}
        styles={{
          height: '100%',
        }}
        pageSettings={{
          backgroundColor: '#fff',
          hideEventTypeDetails: false,
          hideLandingPageDetails,
          primaryColor,
          textColor: '#4d5055',
          hideGdprBanner: true,
        }}
        prefill={{
          email: user?.email,
          firstName: user?.firstName,
          lastName: user?.lastName,
          smsReminderNumber: user?.phoneNumber?.number,
          customAnswers: moveId
            ? {
                a1: moveId,
              }
            : undefined,
        }}
        LoadingSpinner={() => <Spinner floating />}
      />
    </div>
  )
}

export default Calendly
