import * as Yup from 'yup'

import { useAddressValidation, usePlaceValidator } from '../../../Form/fields/PlaceField'
import { type AddressesFormValues } from './AddressesForm.type'

type UseValidationSchemaOptions = {
  specificAddresses?: boolean
}

const useValidationSchema = ({
  specificAddresses = false,
}: UseValidationSchemaOptions = {}) => {
  const placeValidator = usePlaceValidator()
  const addressValidation = useAddressValidation()

  const placeShape: Yup.ObjectShape = {
    address: specificAddresses ? addressValidation : placeValidator,
    nbStairs: Yup.number().min(0).max(9),
  }
  if (specificAddresses) {
    placeShape.elevatorReservationRequired = Yup.string().required()
  }

  return Yup.object<AddressesFormValues>().shape({
    origin: Yup.object(placeShape),
    destination: Yup.object(placeShape),
  })
}

export default useValidationSchema
