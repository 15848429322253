import { useTranslation } from 'react-i18next'

import RegisterForm from '../../../components/forms/RegisterForm'
import { type RegisterFormValues } from '../../../components/forms/RegisterForm/RegisterForm.type'
import { Heading } from '../../../components/typography'
import { useCreateMoveAction, useSaveMoveAction } from '../../../modules/moves'
import { type QuotesRequestFormValues } from '../QuotesRequestPage/QuotesRequestPage.type'
import { formatMoveRequest } from '../QuotesRequestPage/utils/formatRequest'
import { useNavigate, usePathGenerator, useQueryString } from '../../../modules/navigation'
import Route from '../../../app/Route.enum'
import { useErrorFormatter } from '../../../components/errors/useErrorFormatter'
import { errorNotification } from '../../../components/ToastNotifications'

type QuotesPreviewAgentProps = {
  requestFormValues: QuotesRequestFormValues
}

const QuotesPreviewAgent: React.FC<QuotesPreviewAgentProps> = ({
  requestFormValues,
}) => {
  const { t } = useTranslation()
  const { createMove } = useCreateMoveAction()
  const saveMove = useSaveMoveAction()
  const { navigate } = useNavigate()
  const generatePath = usePathGenerator()
  const formatError = useErrorFormatter()
  const queryString = useQueryString()

  const handleCreateMove = async (userFormValues: RegisterFormValues) => {
    const payload = formatMoveRequest({
      requestFormValues,
      userFormValues,
      queryString,
    })
    try {
      const move = await createMove(payload)
      if (move) {
        saveMove(move)
        navigate(generatePath(Route.Move, { moveId: move.id }))
      }
    } catch (error) {
      errorNotification(formatError(error))
    }
  }

  return (
    <div className="pt-4 text-center">

      <Heading variant="h1">
        { t('pages.quotesRequest.preliminaryQuotes.agent.title') }
      </Heading>
      <div className="my-8 font-sans text-lg">
        { t('pages.quotesRequest.preliminaryQuotes.agent.explanation') }
      </div>
      <div className="my-16">
        <Heading variant="h2" className="text-center">
          { t('pages.quotesRequest.preliminaryQuotes.agent.register.title') }
        </Heading>

        <RegisterForm
          onSubmit={handleCreateMove}
          whiteQuotes={false}
        />
      </div>
    </div>
  )
}

export default QuotesPreviewAgent
