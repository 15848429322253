import { type SelectProps as CFSelectProps } from '@concrete-form/mui/Select'
import InputLabel from '@mui/material/InputLabel'

import { getMuiTextfieldProps } from '../Input'
import { type SelectProps } from './Select'

export const getSelectProps = ({
  label,
  placeholder,
  options,
  allowEmpty,
  disabled,
  className,
  ...textfieldProps
}: Omit<SelectProps, 'name'>) => {
  const selectProps: Partial<CFSelectProps> = {
    label,
    native: false,
    options: options as any,
    allowEmpty,
    disabled,
    displayEmpty: true,
    textFieldProps: getMuiTextfieldProps(textfieldProps),
  }

  if (placeholder) {
    selectProps.label = placeholder
    selectProps.renderValue = (value: any) => {
      const option: any = (options as any)?.find((option: any) => {
        return option?.value === value
      })
      return (
        // eslint-disable-next-line react/jsx-no-useless-fragment
        <>
          { option?.label ?? (
            <InputLabel style={{ color: '#949494' }}>
              { placeholder }
            </InputLabel>
          ) }
        </>
      )
    }
  }

  return selectProps
}
