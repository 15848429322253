import { ArrivalWindow } from '../../../modules/quotes'

const defaultChoiceOrder = [
  ArrivalWindow.From8to9,
  ArrivalWindow.From9to10,
  ArrivalWindow.From7to8,
  ArrivalWindow.From13to15,
  ArrivalWindow.From16to19,
]

export const useDefaultTime = (availableTime: ArrivalWindow[], preferredTime: ArrivalWindow[]) => {
  return preferredTime.find(t => availableTime.includes(t)) ??
    defaultChoiceOrder.find(t => availableTime.includes(t)) ??
    ArrivalWindow.From8to9
}
